import React, {useEffect, useState} from 'react';
import FormGroup from 'components/FormGroup';
import {Controller, useForm} from 'react-hook-form';
import {Address} from 'types/order';
import WrongSwitch from 'react-switch';
import styles from './UserAddresses.module.scss';
import Button from '../../../components/Button';
import useUserForm from '../useUserForm';
import {
  GooglePlaceAutoComplete,
  TypeGooglePlaceDetail,
} from 'components/GooglePlaceAutoComplete';
import TextField from 'components/TextField/TextField';
import {get} from 'lodash';
import MapPreview from 'components/MapPreview';
import SelectField from 'components/SelectField';
import {labels} from './UserAddresses';
import toast from 'react-hot-toast';
import DeleteModal from 'components/DeleteModal';
const Switch = WrongSwitch as any;
type FormData = Omit<Address, 'label' | 'type'>;

type AddressFormProps = {
  defaultValues?: Address;
  onSave: () => void;
  deleting: boolean;
  onChange: (values: Partial<FormData>) => void;
};

const AddressForm: React.VFC<AddressFormProps> = ({
  defaultValues = {},
  onSave,
  deleting,
  onChange,
}) => {
  const {
    control,
    formState: {isDirty},
    watch,
    setValue,
    getValues,
  } = useForm<Address>({
    defaultValues,
  });
  const {deleteAddress} = useUserForm();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const subscription = watch((data) => {
      onChange(data);
    });
    return () => subscription.unsubscribe();
  }, [isDirty, onChange, watch]);

  const [location, setLocation] = useState({
    latitude: get(defaultValues, 'latitude', 0),
    longitude: get(defaultValues, 'longitude', 0),
  });

  const onSelectPlace = (placeDetail: TypeGooglePlaceDetail) => {
    setValue('zipcode', placeDetail.zipcode);
    setValue('address_line_1', placeDetail.addressLine1);
    setValue('city', placeDetail.city);
    setValue('state', placeDetail.state);
    setValue('latitude', placeDetail.lat);
    setValue('longitude', placeDetail.lon);
    setLocation({latitude: placeDetail.lat, longitude: placeDetail.lon});
  };
  return (
    <>
      <FormGroup>
        <Controller
          name="city"
          control={control}
          rules={{required: 'Please, Select the address'}}
          render={({field, fieldState}) => (
            <GooglePlaceAutoComplete
              defaultValue={`${getValues().address_line_1}, ${
                getValues().city
              }, ${getValues().state}, ${getValues().zipcode}`}
              label="Your address"
              placeholder="Address"
              onSelectPlace={onSelectPlace}
              error={fieldState.error?.message}
              options={{
                types: ['address'],
              }}
            />
          )}
        />
        <Controller
          name="unit_address"
          control={control}
          // rules={{required: 'Please, fill the unit number'}}
          render={({field, fieldState}) => (
            <TextField
              onChange={field.onChange}
              label="Unit number"
              wrapperClass={styles.googleInput}
              placeholder="Enter unit number"
              defaultValue={get(defaultValues, 'unit_address', '')}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="label"
          control={control}
          rules={{required: 'Please select label of address'}}
          render={({field, fieldState}) => (
            <SelectField
              {...field}
              label="Address Label"
              placeholder="Select a label"
              options={labels}
              error={fieldState.error?.message}
              value={labels.find((item) => item.value === field.value)}
              onChange={(newValue) => {
                if (newValue) {
                  setValue('label', newValue.value as 'home');
                }
              }}
            />
          )}
        />
      </FormGroup>

      {!!location.latitude && (
        <MapPreview
          zoom={15}
          style={{
            marginTop: 20,
            width: 650,
            height: 350,
          }}
          onDrag={(location) => {
            setLocation({latitude: location.lat, longitude: location.lon});
            setValue('latitude', location.lat);
            setValue('longitude', location.lon);
          }}
          center={[location.latitude, location.longitude]}
        />
      )}
      {/* <FormGroup>
        <TextField
          label="Address line 1"
          {...register('address_line_1', {required: 'Required'})}
          error={errors.address_line_1?.message}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          label="Address line 2"
          {...register('address_line_2')}
          error={errors.address_line_2?.message}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          label="City"
          {...register('city', {required: 'Required'})}
          error={errors.city?.message}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="state"
          control={control}
          render={({field, fieldState}) => (
            <SelectField
              {...field}
              label="State"
              placeholder="Select a state"
              options={states}
              error={fieldState.error?.message}
              value={states.find((item) => item.value === field.value)}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          label="Zip code"
          {...register('zipcode')}
          error={errors.zipcode?.message}
        />
      </FormGroup> */}
      <FormGroup className={styles.switchForm} columns={2}>
        <div>
          <p className={styles.label}>Primary address</p>
        </div>
        <div className={styles.switchContainer}>
          <Controller
            control={control}
            name="is_primary"
            render={({field}) => (
              <Switch checked={field.value} onChange={field.onChange} />
            )}
          />
        </div>
      </FormGroup>

      <p className={styles.warningText}>
        * Please hit save before moving to next address
      </p>

      <div className={styles.actionWrapper}>
        <Button
          style={{
            backgroundColor: '#fff',
          }}
          color="danger"
          onClick={() => setOpenModal(true)}
        >
          Delete
        </Button>
        <Button
          style={{
            marginLeft: 10,
            backgroundColor: '#fff',
          }}
          type="submit"
          onClick={onSave}
          loading={deleting}
        >
          Save addess
        </Button>
      </div>
      <DeleteModal
        onDelete={async () => {
          await deleteAddress(get(defaultValues, 'id', 0));
          toast.success('Address deleted successfully');
          setOpenModal(false);
        }}
        text="Are you sure you want to delete this address?"
        setOpenModal={setOpenModal}
        openModal={openModal}
      />
    </>
  );
};

export default AddressForm;
