import api from 'api';
import Button from 'components/Button';
// import Select from 'components/Select';
import useAuth from 'hooks/useAuth';
import {get} from 'lodash';
import {useState} from 'react';
import toast from 'react-hot-toast';
import {useHistory} from 'react-router-dom';
import {SelectOption} from 'types';
import usePricing from '../Tariff/usePricing';
import useBilling from '../Billing/useBilling';
import {PlanCard} from '../Tariff';
import DeleteModal from 'components/DeleteModal';

const UpdatePlan = () => {
  const {data: billingData} = useBilling();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const {currentUser, refetch} = useAuth();

  const tariff = JSON.parse(sessionStorage.getItem('tariff') || '{}');

  const isCurrentPlan =
    get(currentUser, 'company.plan.plan_id', '') === tariff.product_id;

  const hasPlan = !!get(currentUser, 'company.plan', false);

  const [cancelledPlan, setCancelledPlan] = useState(
    get(currentUser, 'company.plan.subscription_cancel_at_period_end', false)
  );

  const {goBack, push} = useHistory();
  // State to manage the number of seats
  const [seats, setSeats] = useState<SelectOption>({
    label: tariff.count,
    value: tariff.count,
  });

  const {data} = usePricing({
    quantity: Number(seats.value),
    id: tariff.product_id,
    fetchable: !!get(currentUser, 'company.plan', ''),
  });

  const updatePlan = async () => {
    if (billingData.length < 1) {
      push('/settings/billing', {
        defaultOpen: true,
      });
      toast.error('Please add a payment method first');
      return;
    }
    try {
      if (hasPlan) {
        await api.put('/payment/stripe-subscription-management/', {
          product_id: tariff.product_id,
          quantity: seats.value,
        });
      } else {
        await api.post('/payment/stripe-subscription-management/', {
          product_id: tariff.product_id,
          quantity: seats.value,
        });
      }
      setTimeout(() => {
        refetch(false);
        push('/settings/billing');
      }, 2000);
    } catch (error) {
      toast.error(get(error, 'response.data.message', 'Error'));
    }
  };

  const cancelHandler = async () => {
    try {
      await api.delete('/payment/stripe-subscription-management/');
      setDeleteVisible(false);
      setCancelledPlan((pr) => !pr);
    } catch (error) {
      toast.error(get(error, 'response.data.message', 'Error'));
    }
  };

  return (
    <div style={styles.container}>
      {hasPlan ? (
        <h1>
          Update your {isCurrentPlan ? '' : ' plan from '}"
          {get(currentUser, 'company.plan.plan_name', '')}" plan{' '}
          {!isCurrentPlan && hasPlan
            ? 'to "' + get(data, 'new_plan.plan_name', '') + '"'
            : ''}
        </h1>
      ) : (
        <h1>Buy {tariff.name} plan</h1>
      )}

      <div style={{display: 'flex', justifyContent: 'center'}}>
        <PlanCard
          id={get(tariff, 'product_id', '')}
          choosen={get(tariff, 'product_id', '')}
          initialCount={get(tariff, 'count', '')}
          title={get(tariff, 'name', '')}
          price={get(tariff, 'price', '')}
          addition={get(tariff, 'addition', '')}
          people={get(tariff, 'description', '')}
          setCounter={(count) =>
            setSeats((pr) => ({label: count, value: count}))
          }
        />
      </div>

      {/* <p>
        Your plan has{' '}
        <strong>
          {get(currentUser, 'company.plan.subscription_quantity', 0)} seats
        </strong>{' '}
        right now, with{' '}
        <strong>
          {get(currentUser, 'company.plan.active_subscription_quantity', 0)}{' '}
          active people
        </strong>{' '}
        on your team filling seats. If you need more seats, you can add them
        here.
      </p> */}

      {/* <div style={styles.card}>
        <label htmlFor="seats">
          <p>How many total seats do you need?</p>
        </label>
        <Select
          onChange={(value) => {
            setSeats(value as SelectOption);
          }}
          value={seats}
          options={[...Array(30)].map((_, index) => ({
            label: index + 1,
            value: index + 1,
          }))}
        />
        <p>
          You currently have{' '}
          {get(currentUser, 'company.plan.active_subscription_quantity', 0)}{' '}
          active people.
        </p>
      </div> */}

      <div style={styles.summary}>
        <h2>Plan summary</h2>
        {/* <div style={styles.tableRow}>
          <p>
            <b>
              {get(data, 'new_plan.plan_name', '')} plan with {seats.value}{' '}
              seats
            </b>
          </p>
          <p>{get(data, 'new_plan.total_price', 0)}</p>
        </div> */}
        <div style={styles.tableRow}>
          <p>
            Prorated credit from{' '}
            {hasPlan ? get(data, 'current_plan.plan_name', '') : tariff.name}
          </p>
          <p>
            {hasPlan
              ? get(data, 'current_prorated_credit', 0)
              : '$' + (Number(tariff.price) * Number(seats.value)).toFixed(2)}
          </p>
        </div>
        <div style={styles.tableRow}>
          <p>
            Prorated charge for{' '}
            {hasPlan ? get(data, 'new_plan.plan_name', '') : tariff.name}
          </p>
          <p>
            {hasPlan
              ? get(data, 'new_prorated_charge', 0)
              : '$' + (Number(tariff.price) * Number(seats.value)).toFixed(2)}
          </p>
        </div>
        <div style={styles.tableRow}>
          <p>Total prorated charge</p>
          <p>{get(data, 'total_prorated_charge', '$0.00') || '$0.00'}</p>
        </div>
        <div style={styles.tableRow}>
          <p>Account balance credit</p>
          <p>{get(data, 'applied_account_balance', '$0.00') || '$0.00'}</p>
        </div>

        <div style={styles.tableRow}>
          <p>
            <b>Amount due today</b>
          </p>
          <p>
            <b>
              {hasPlan
                ? get(data, 'amount_due_today', 0)
                : '$' + (Number(tariff.price) * Number(seats.value)).toFixed(2)}
            </b>
          </p>
        </div>
        {/* <div style={styles.tableRow}>
          <p>
            <strong>
              Total per month for {get(data, 'new_plan.plan_name', '')}
            </strong>
          </p>
          <p>
            <b>
              {hasPlan
                ? get(data, 'new_plan.total_price', 0)
                : '$' + (Number(tariff.price) * Number(seats.value)).toFixed(2)}
            </b>
          </p>
        </div> */}
      </div>

      <div style={styles.buttons}>
        <Button onClick={goBack} color="gray">
          Cancel
        </Button>
        {isCurrentPlan && (
          <Button
            style={cancelledPlan ? styles.cancelButton : {}}
            onClick={() => setDeleteVisible(true)}
            color="danger"
          >
            {!cancelledPlan ? 'Cancel subscription' : 'Renew subscription'}
          </Button>
        )}
        <Button onClick={updatePlan}>
          {hasPlan ? 'Update billing amount' : 'Buy plan'}
        </Button>
      </div>
      <DeleteModal
        openModal={deleteVisible}
        setOpenModal={setDeleteVisible}
        onDelete={cancelHandler}
        text={`Are you sure you want to ${
          cancelledPlan ? 'renew' : 'cancel'
        } your
          subscription?`}
        buttonType={cancelledPlan ? 'green' : 'danger'}
        confirmText={cancelledPlan ? 'Renew' : 'Cancel'}
      />
    </div>
  );
};

// Inline styling (can be replaced with a CSS file or styled-components)
const styles = {
  container: {
    maxWidth: '80%',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    // backgroundColor: '#F9F9FD',
  },
  card: {
    padding: '20px',
    backgroundColor: '#fef7e0',
    border: '1px solid #f0e6c0',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  select: {
    padding: '10px',
    margin: '10px 0',
    width: '200px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  summary: {
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left',
  },
  tableRow: {
    display: 'flex',
    borderBottom: '1px solid #e0e0e0',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    marginTop: '40px',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    padding: '10px 20px',
    backgroundColor: '#f8f9fa',
    color: '#333',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default UpdatePlan;
