import {useMemo, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';

import {ListResponse, Transaction} from 'types';
import useSort from 'hooks/useSort';
import api from 'api';
import {format, parseISO} from 'date-fns';
import {get} from 'lodash';
import useDebounce from 'hooks/useDebounce';

async function getRequests({queryKey}: QueryFunctionContext<any[]>) {
  const [, page, status, creditType, date, key, startDate, endDate] = queryKey;

  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        offset: +page * 20,
        limit: 20,
        transaction_origin: status ? status : undefined,
        service_name: creditType ? creditType.value : undefined,
        transaction_date: date,
        search: key ? key : undefined,
        transaction_date_after: startDate
          ? format(startDate, 'yyyy-MM-dd')
          : undefined,
        transaction_date_before: endDate
          ? format(endDate, 'yyyy-MM-dd')
          : undefined,
      },
    }
  );

  return data;
}

const returnType = (type: string) => {
  if (type === 'patient_app') {
    return 'Customer App';
  }
  if (type === 'pos') {
    return 'POS';
  }
  if (type === 'dashboard') {
    return 'Dashboard';
  }
  if (type === 'driver_app_cod') {
    return 'Driver App COD';
  }
};

function transformData(data: Transaction[]) {
  return data.map((item) => ({
    ...item,
    id: item.transaction_id,
    transaction_origin: returnType(item.transaction_origin),
    transaction_date: format(
      parseISO(item.transaction_date),
      'MM/dd/yy EEE hh:mm a'
    ),
    amount: '$' + item.amount,
    list_orders: `${get(item, 'list_orders', []).slice(0, 3).join(', ')} ${
      item?.list_orders?.length > 3 ? '...' : ''
    }`,
  }));
}

type RequestFilter = {
  userId?: string;
  source?: string;
  page?: number;
};

function useRequests(filter: RequestFilter) {
  const {sortBy, sortOrder, onSort} = useSort('id', 'desc');
  const [source] = useState(filter.source || '');
  const [status, setStatus] = useState(
    sessionStorage.getItem('state') === 'all'
      ? ''
      : sessionStorage.getItem('transactions_state') || ''
  );
  const [key, setKeyWord] = useState(
    sessionStorage.getItem(`searchKey_transaction`) || ''
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const initialDate = sessionStorage.getItem('transactionDate')
    ? new Date(JSON.parse(sessionStorage.getItem('transactionDate') || ''))
    : undefined;

  const [date, setDate] = useState(initialDate);

  const [creditType, setCreditType] = useState<
    undefined | {value: string; label: string}
  >(
    sessionStorage.getItem('creditType')
      ? JSON.parse(sessionStorage.getItem('creditType') || '')
      : {label: 'Sale', value: 'CreditSale'}
  );

  const searchDebounced = useDebounce(key, 400);

  const {data, isLoading} = useQuery(
    [
      'transactions',
      String(filter.page || 0),
      status,
      creditType,
      date,
      searchDebounced,
      startDate,
      endDate,
    ],
    getRequests
  );

  const onChangeData = (val: any) => {
    const [start, end] = val;
    setStartDate(start);
    setEndDate(end);

    // setDate(val);
    // sessionStorage.setItem('transactionDate', val);
  };

  const tabs = useMemo(function () {
    return [
      {title: 'All', value: ''},
      // {title: 'POS', value: 'pos'},
      // {title: 'Customer App', value: 'patient_app'},
      {title: 'Dashboard', value: 'dashboard'},
      {title: 'Field App COD', value: 'driver_app_cod'},
      {title: 'Payment Link', value: 'payment_link'},
    ];
  }, []);

  return {
    data: data && !isLoading ? transformData(data.results) : [],
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    dailyCount: data?.count || 0,
    totalAmount:
      (get(data, 'total_amount') === 'None'
        ? 0
        : get(data, 'total_amount', '0')) || 0,
    loading: isLoading,
    sortBy,
    sortOrder,
    tabs,
    status,
    keyWord: key,
    setKeyWord,
    onSort,
    setStatus,
    source,
    setDate,
    date,
    onChangeData,
    setCreditType,
    creditType,
    startDate,
    endDate,
  };
}

export default useRequests;
