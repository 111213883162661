import classNames from 'classnames';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Ticket from './components/Ticket';
import SignedDocument from './components/SignedDocument';
import useDeliveryPlanner from './useDeliveryPlanner';
import stat_path from './icons/stat_path.svg';
import stat_stops from './icons/stat_stops.svg';
import stat_time from './icons/stat_time.svg';
import reset_icon from './icons/reset.svg';

import styles from './DeliveryPlanner.module.scss';
import './styles.scss';
import AssignedHeader from './components/AssignedHeader';
import React, {Fragment} from 'react';
import nyPostalCodeBoroughOptions from 'shared/ny-postal-code-borough-options';
import {get, groupBy, isArray, map, sortBy} from 'lodash';
// import DateTimePicker from 'components/DateTimePicker';
import {add, parseISO, parseJSON} from 'date-fns';
import {utcToZonedTime, format} from 'date-fns-tz';
import {
  getOrderDeliveryBorough,
  getOrderDeliveryTimePreference,
} from 'utils/order-helpers';

import SendRouteModal from 'components/SendRouteModal';
import FormGroup from 'components/FormGroup';
import PrintIcon from 'icons/PrintIcon';
import ReactToPrint from 'react-to-print';
import Modal from 'components/Modal';
import {Link} from 'react-router-dom';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import clockIcon from 'components/NoteForm/icons/clock.svg';
import CheckboxField from 'components/CheckboxField/CheckboxField';
import TextField from 'components/TextField';
import {components} from 'react-select';
import NewMap from './components/NewMap';
import Select from 'components/Select';
import Onboarding from 'Onboarding';
import PermissionModal from 'components/PermissionModal';
import plansIds from 'constants/plans';
import locationIcon from '../Requests/location.svg';
import calendar from './icons/calendar.svg';
import DateTimePicker from 'components/DateTimePicker';
import Typography from 'components/Typography';
import FormActionGroup from 'components/FormActionGroup';
import FilterIcon from 'pages/Requests/FilterIcon';
import Clear from './icons/clear';
import Morning from './icons/morning';
import Afternoon from './icons/afternoon';
import Evening from './icons/evening';

const locationOptionsGrouped = groupBy(nyPostalCodeBoroughOptions, 'label');
const locationOptions = sortBy(
  map(locationOptionsGrouped, (obj, key) => ({
    label: key,
    value: map(obj, 'value'),
  })),
  'label'
);

const onboardingSteps = [
  {
    target: '.draggable-1',
    title: 'Tasks',
    disableBeacon: true,
    content: 'You can drag task from here to right',
  },
  {
    target: '.assign-team',
    title: 'Assign team',
    disableBeacon: true,
    content: 'You can assign task for your teams from here',
  },
  {
    target: '.calculate-route',
    title: 'Calculate route',
    disableBeacon: true,
    content: 'Calculate the route with Magic Ai',
  },
  {
    target: '.send-route',
    title: 'Send route',
    disableBeacon: true,
    content: 'Send the route to your team from here',
  },
  {
    target: '.mapping-route',
    title: 'Mapping',
    disableBeacon: true,
    content: 'Map your route with using this',
  },
  {
    target: '.history-route',
    title: 'Delivery history',
    disableBeacon: true,
    content: 'You can control all your deliveries here',
  },
];

function DeliveryPlanner() {
  const {
    filtered,
    route,
    currentTeam,
    teams,
    isLoading,
    unassignedOrders,
    selectedUnassignedOrders,
    selectedAssignedOrders,
    stats,
    isSubmittable,
    onChangeUnassignedCheckbox,
    onChangeAssignedCheckbox,
    onChangeRouteParams,
    onChangeStartTime,
    onSendRoute,
    sortBy,
    setSortBy,
    filter,
    // refetch,
    handleChangeLocation,
    assignSelected,
    moveToOrders,
    unassignSelected,
    onChangeData,
    onChangePriority,
    isFetching,
    roundedTime,
    searchKey,
    setSearch,
    count,
    fetchingAll,
    calculating: calculatingRoute,
    resetCalculate,
    sortOptions,
    search,
    permissionRef,
    isRouteHistory,
    currentUser,
    isCalculate,
    handleDragEnd,
    recalculate,
    activeOrder,
    setActiveOrder,
    orderList,
    handleOrdersClick,
    handleOrdersClose,
    noteModal,
    allCheckedAssign,
    allChecked,
    calculateHandler,
    showGuide,
    setShowGuide,
    onMappingPressed,
    onChangePreferenceTime,
    onChangeCheckboxAssign,
    onChangeCheckbox,
    handleNoteClick,
    noteSubmit,
    register,
    handleSubmit,
    isSendRouteModalOpen,
    setIsSendRouteModalOpen,
    moveOrderModal,
    setMoveToOrder,
    isSubmitting,
    lastElementRef,
    orderListRef,
    resetFilter,
    applyFilter,
    showFilterModal,
    setShowFilterModal,
  } = useDeliveryPlanner();

  return (
    <>
      {showGuide && (
        <Onboarding
          onFinish={() => setShowGuide(false)}
          steps={onboardingSteps}
        />
      )}
      <div className={styles.root}>
        <SendRouteModal
          route={route}
          isOpen={isSendRouteModalOpen}
          onClose={() => setIsSendRouteModalOpen(false)}
          onSubmit={onSendRoute}
        />
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className={styles.panel}>
            <div className={classNames(styles.panel__header)}>
              {filtered && <p>Filter:</p>}
              <div className={styles.row}>
                <span className={styles.panel__inbox_title}>
                  Inbox: {Math.max(count, 0)} Jobs
                </span>
                {filtered && (
                  <div className={styles.clear_icon} onClick={resetFilter}>
                    <Clear />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.search_wrapper}>
              <CheckboxField
                name={`checkbox`}
                className={styles.checkboxAll}
                checked={allChecked}
                onChange={onChangeCheckbox}
              />
              <TextField
                value={searchKey}
                onChange={(target) => setSearch(target.target.value)}
                wrapperClass={styles.searchWrapper}
                placeholder="Search..."
              />
              <div
                className={styles.filterBtn}
                style={{
                  borderColor: filtered ? '#4000FF' : '#E0E0E0',
                }}
                onClick={() => setShowFilterModal(true)}
              >
                <FilterIcon isFilled={filtered} />
              </div>
            </div>
            <div className={classNames(styles.panel__content)}>
              {!isRouteHistory && (
                <>
                  <div className="draggable-1" />

                  {isLoading ? (
                    <div className={styles.spinner_loading}>
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <Droppable droppableId="list1">
                        {(provided: any) => (
                          <div
                            style={{minHeight: '70vh'}}
                            ref={provided.innerRef}
                          >
                            {!!filtered && unassignedOrders.length < 1 && (
                              <div className={styles.no_result}>
                                <p>No deliveries with that filter</p>
                                <Button
                                  onClick={resetFilter}
                                  className={styles.no_result_button}
                                >
                                  Reset filter
                                </Button>
                              </div>
                            )}

                            {unassignedOrders.map((ticket, index) => {
                              return (
                                <div
                                  key={ticket.id}
                                  ref={
                                    unassignedOrders.length === index + 1
                                      ? lastElementRef
                                      : null
                                  }
                                >
                                  <Draggable
                                    draggableId={String(ticket.id)}
                                    index={index}
                                  >
                                    {(provided: any) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Ticket
                                          isAssigned={false}
                                          key={ticket.id}
                                          {...ticket}
                                          index={index + 1}
                                          new_leaf_order={ticket.new_leaf_order}
                                          timePreference={getOrderDeliveryTimePreference(
                                            ticket
                                          )}
                                          borough={getOrderDeliveryBorough(
                                            ticket
                                          )}
                                          selected={selectedUnassignedOrders.includes(
                                            ticket.id
                                          )}
                                          onChangeCheckbox={
                                            onChangeUnassignedCheckbox
                                          }
                                          onOrdersClick={handleOrdersClick(
                                            ticket
                                          )}
                                          handleNoteClick={() => {
                                            handleNoteClick(ticket.id, '');
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                </div>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      {isFetching && unassignedOrders.length > 0 && (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Spinner />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className={styles.panel__bottom}>
              <Button
                size="lg"
                className={styles.panel__bottom_button}
                color="outlined"
                disabled={!selectedUnassignedOrders.length}
                onClick={() => setMoveToOrder(true)}
              >
                Remove from Inbox
              </Button>
              <Button
                size="lg"
                color="outlined"
                disabled={!selectedUnassignedOrders.length}
                onClick={assignSelected}
              >
                Move to Router
              </Button>
            </div>
          </div>
          <div className={styles.panel}>
            <AssignedHeader
              roundedTime={roundedTime}
              currentVehicle={currentTeam}
              vehicles={teams?.results}
              startLocation={
                route.start_location
                  ? {
                      name: route.start_location,
                      lat: route.start_latitude,
                      lng: route.start_longitude,
                    }
                  : {
                      name: '1100 2nd Ave, New York, NY 10022',
                      lat: 40.7596958,
                      lng: -73.9650045,
                    }
              }
              recalculate={recalculate}
              routes={route.deliveries.length}
              start_time={route.start_time}
              endLocation={
                route.end_location
                  ? {
                      name: route.end_location,
                      lat: route.end_latitude,
                      lng: route.end_longitude,
                    }
                  : {
                      name: '1100 2nd Ave, New York, NY 10022',
                      lat: 40.7596958,
                      lng: -73.9650045,
                    }
              }
              onChange={onChangeRouteParams}
              onChangeStartTime={onChangeStartTime}
            />
            <div className={classNames(styles.panel__filters, styles.assign)}>
              {/* <div className={styles.panel__start}>
            <span>Starts at</span>
            <DateTimePicker
              value={route.start_time ? parseJSON(route.start_time) : undefined}
              onChange={onChangeStartTime}
            />
          </div> */}
              {!isRouteHistory && (
                <CheckboxField
                  name={`checkbox`}
                  className={classNames(styles.checkbox, styles.assignCheck)}
                  label={allCheckedAssign ? `Uncheck all` : 'Check all'}
                  checked={allCheckedAssign}
                  onChange={onChangeCheckboxAssign}
                />
              )}
              <Button
                className={styles.panel__unassign}
                disabled={!selectedAssignedOrders.length}
                size="sm"
                onClick={unassignSelected}
              >
                Unassign
              </Button>
            </div>
            <div
              ref={orderListRef}
              style={{position: 'absolute', zIndex: -100, display: 'none'}}
              className={classNames(
                styles.panel__content,
                styles['--assigned']
              )}
            >
              <div>
                {route.deliveries.map((ticket, index) => {
                  return (
                    <Ticket
                      isAssigned={true}
                      key={ticket.id}
                      isPrint={true}
                      showTime={route.status === 'processed' || isRouteHistory}
                      index={index + 1}
                      selected={selectedAssignedOrders.includes(ticket.id)}
                      onChangeCheckbox={onChangeAssignedCheckbox}
                      onOrdersClick={handleOrdersClick(ticket)}
                      {...ticket}
                      handleNoteClick={() => {
                        setActiveOrder(ticket);
                        handleNoteClick(
                          ticket.id,
                          String(get(ticket, 'staff_note', '') || '')
                        );
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div
              // ref={orderListRef}
              className={classNames(
                styles.panel__content,
                styles['--assigned']
              )}
            >
              <Droppable droppableId="list">
                {(provided: any) => (
                  <div style={{minHeight: '70vh'}} ref={provided.innerRef}>
                    {route.deliveries.map((ticket, index) => {
                      // console.log('Ticket:', ticket);
                      return (
                        <div key={ticket.id}>
                          <Draggable
                            draggableId={String(ticket.id)}
                            index={index}
                          >
                            {(provided: any) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Ticket
                                  isAssigned={true}
                                  key={ticket.id}
                                  showTime={
                                    route.status === 'processed' ||
                                    isRouteHistory
                                  }
                                  index={index + 1}
                                  selected={selectedAssignedOrders.includes(
                                    ticket.id
                                  )}
                                  isError={(
                                    get(
                                      route,
                                      'wrong_addresses',
                                      []
                                    ) as number[]
                                  ).includes(ticket.id)}
                                  onChangeCheckbox={onChangeAssignedCheckbox}
                                  onOrdersClick={handleOrdersClick(ticket)}
                                  {...ticket}
                                  handleNoteClick={() => {
                                    setActiveOrder(ticket);
                                    handleNoteClick(
                                      ticket.id,
                                      String(
                                        get(ticket, 'staff_note', '') || ''
                                      )
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        </div>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>

            <div className={styles.panel__assigned_buttom}>
              <div className={styles.panel__assigned_summary}>
                {/* <span className={styles.panel__deliveries_counter}>
              {route.deliveries.length}{' '}
              {pluralize('Delivery', route.deliveries.length)}
            </span> */}
                <ReactToPrint
                  trigger={() => (
                    <button className={styles.panel__deliveries_print_action}>
                      <PrintIcon size={16} />
                    </button>
                  )}
                  content={() => orderListRef.current}
                  onBeforeGetContent={() => {
                    orderListRef.current?.setAttribute(
                      'style',
                      'display:block; z-index:-1000;position:absolute'
                    );
                  }}
                  onAfterPrint={() => {
                    orderListRef.current?.setAttribute(
                      'style',
                      'display:none; z-index:-1000;position:absolute'
                    );
                  }}
                  onPrintError={() => {
                    orderListRef.current?.setAttribute(
                      'style',
                      'display:none; z-index:-1000;position:absolute'
                    );
                  }}
                />
                <div className={styles.panel__deliveries_end_time}>
                  <img alt="timer" src={stat_time} />
                  {/*<span>Ends at</span> {stats.end_time}*/}
                  <span>Ends at</span>{' '}
                  {route?.start_time && route.total_time
                    ? format(
                        utcToZonedTime(
                          add(parseISO(route.start_time), {
                            minutes: route.total_time,
                          }),
                          'America/New_York'
                        ),
                        'h:mm aaa',
                        {
                          timeZone: 'America/New_York',
                        }
                      )
                    : '-:-- --'}
                </div>
              </div>
              {route.status === 'processed' ? (
                <div className={styles.panel__assigned_stats}>
                  <Fragment>
                    <div className={styles.panel__assigned_stat}>
                      <img alt="stat icon" src={stat_time} />
                      <span>{stats.total_time}</span>
                      <span>Total time</span>
                    </div>
                    <div className={styles.panel__assigned_stat}>
                      <img alt="stat icon" src={stat_path} />
                      <span>{stats.distance}</span>
                      <span>Distance</span>
                    </div>
                    <div className={styles.panel__assigned_stat}>
                      <img alt="stat icon" src={stat_stops} />
                      <span>{stats.stops}</span>
                      <span>Stops</span>
                    </div>
                  </Fragment>
                </div>
              ) : null}

              <>
                <div
                  className={classNames(
                    styles.panel__assigned_actions,
                    get(currentUser, 'company.plan.plan_id') === plansIds[1] &&
                      styles.reverse_row
                  )}
                >
                  {get(currentUser, 'company.plan.plan_id') === plansIds[2] &&
                  route.deliveries.length > 25 ? null : (
                    <div
                      style={{
                        opacity:
                          !route.deliveries.length ||
                          !isSubmittable ||
                          isRouteHistory
                            ? 0.6
                            : 1,
                      }}
                      className={styles.linear_border}
                    >
                      <Button
                        loading={isCalculate && calculatingRoute}
                        color="outlined"
                        onClick={calculateHandler}
                        className={styles.panel__bottom_ai_route}
                        disabled={
                          !route.deliveries.length ||
                          !isSubmittable ||
                          isRouteHistory
                        }
                      >
                        Route with AI
                      </Button>
                    </div>
                  )}
                  {(get(currentUser, 'company.plan.plan_id') !== plansIds[2] ||
                    (get(currentUser, 'company.plan.plan_id') === plansIds[2] &&
                      route.deliveries.length > 25)) && (
                    <Button
                      color="outlined"
                      disabled={!route.deliveries.length || !isSubmittable}
                      loading={!isCalculate && calculatingRoute}
                      onClick={onMappingPressed}
                    >
                      Map Jobs
                    </Button>
                  )}
                  {get(currentUser, 'company.plan.plan_id') === plansIds[2] && (
                    <Button
                      color="outlined"
                      className={styles.panel__bottom_send_route}
                      disabled={
                        !isSubmittable ||
                        route.id === '0' ||
                        !route.deliveries.length ||
                        !search
                      }
                      onClick={() => setIsSendRouteModalOpen(true)}
                    >
                      Send Route
                    </Button>
                  )}
                </div>
                {get(currentUser, 'company.plan.plan_id') !== plansIds[2] && (
                  <Button
                    color="outlined"
                    disabled={
                      !isSubmittable ||
                      route.id === '0' ||
                      !route.deliveries.length ||
                      !search
                    }
                    className={styles.panel__bottom_send_route}
                    onClick={() => setIsSendRouteModalOpen(true)}
                  >
                    Send Route
                  </Button>
                )}
              </>
            </div>
          </div>
        </DragDropContext>

        <div className={styles.panel}>
          <div className={classNames(styles.panel__header)}>
            {search ? (
              <div onClick={resetCalculate} className={styles.datetime}>
                <img
                  style={{margin: 0, width: 20, height: 20}}
                  alt="reset"
                  src={reset_icon}
                />
                <span className={styles.panel__change_vehicle}>Reset</span>
              </div>
            ) : (
              <span className={styles.panel__inbox_title}>Preview</span>
            )}
            <Link
              to="/history/routes/page/1"
              className={classNames(styles.datetime, 'history-route')}
            >
              <img className={styles.clock_icon} alt="clock" src={clockIcon} />
              <span className={styles.panel__change_vehicle}>History</span>
            </Link>
          </div>
          <NewMap
            routeType={get(route, 'team.travel_method')}
            isMapping={!isCalculate}
            orders={route.deliveries}
            start_location={{
              name: route.start_location,
              lat: route.start_latitude,
              lng: route.start_longitude,
            }}
            end_location={{
              name: route.end_location,
              lat: route.end_latitude,
              lng: route.end_longitude,
            }}
            display={!!search || isRouteHistory}
          />
        </div>

        <Modal
          isOpen={!!orderList || noteModal}
          onClose={handleOrdersClose}
          onRequestClose={handleOrdersClose}
          className={styles.list_wrapper}
        >
          <Typography variant="h2">
            {orderList ? 'Offerings' : 'Dispatch note for team members:'}
          </Typography>
          {orderList && (
            <>
              <div className={styles.list}>
                <div className={classNames(styles.list__item)}>
                  <p>Order #</p>
                  <p>Item name</p>
                  <p style={{width: 110, textAlign: 'right'}}>
                    Preference date
                  </p>
                </div>
                {orderList?.map(
                  ({
                    new_leaf_order,
                    sequential_order_number,
                    preference_date,
                    preference_time,
                    items,
                  }) => (
                    <Link
                      key={new_leaf_order}
                      to={`/orders/${sequential_order_number}`}
                    >
                      <div className={classNames(styles.list__item)}>
                        <p>#M{sequential_order_number}</p>
                        <p>
                          {items
                            ?.map((item) => get(item, 'drug.name', ''))
                            .join(', ')}
                        </p>
                        <p
                          style={{
                            width: 110,
                            textAlign: 'right',
                          }}
                        >
                          {preference_date || '--/--/----'}
                          {'\n'}
                          {preference_time ? `${preference_time}` : ''}
                        </p>
                        {/* <p className={styles.list__count}>
                        {items.length}
                      </p> */}
                      </div>
                    </Link>
                  )
                )}
              </div>
              <div className={styles.list_total}>
                Total items:
                {orderList?.reduce(
                  //@ts-ignore
                  (prev, item) => prev + item.items.length,
                  0
                )}
              </div>
            </>
          )}
          {noteModal && (
            <form
              className={styles.noteFormWrapper}
              onSubmit={handleSubmit(noteSubmit)}
            >
              <FormGroup>
                <textarea
                  className={styles.noteInput}
                  rows={5}
                  {...register('driverNote')}
                />
              </FormGroup>
              <div className={styles.actions}>
                <Button
                  type="submit"
                  color="green"
                  size="md"
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </form>
          )}
          {isRouteHistory ? (
            <>
              <div>Driver note for Dispatch:</div>
              {get(activeOrder, 'note', 'Empty!') !== null ? (
                <textarea
                  disabled={true}
                  value={get(activeOrder, 'note', 'Empty!')}
                  className={styles.orderNote}
                />
              ) : (
                <textarea
                  disabled={true}
                  value={'Empty!'}
                  className={styles.orderNote}
                />
              )}

              {!!get(activeOrder, 'delivery_image', null) && (
                <div className={styles.delivery_image}>
                  <p>Delivery image:</p>
                  <img
                    alt="delivery_image"
                    src={get(activeOrder, 'delivery_image.medium', '')}
                  />
                </div>
              )}
              {/* <div
              className={classNames(
                styles.list__item_header,
                styles.list__item
              )}
            >
              <p>Signed by:</p>
              <p>{get(activeOrder, 'accepters_name', '')}</p>
            </div>
            <div
              className={classNames(
                styles.list__item_header,
                styles.list__item
              )}
            >
              <p>Signed at:</p>
              <p>{get(activeOrder, 'signed_at', '')}</p>
            </div> */}
              <SignedDocument driver={route.driver} order={activeOrder} />
              {/* {get(activeOrder, 'signature.file', '') !== '' ? (
              <img
                src={get(activeOrder, 'signature.file', '')}
                className={styles.signatureImage}
                alt="Signature by customer"
              />
            ) : (
              <textarea
                disabled={true}
                value={'No images, yet!'}
                className={styles.orderNote}
              />
            )} */}
            </>
          ) : null}
        </Modal>
        <Modal
          isOpen={moveOrderModal}
          onClose={() => setMoveToOrder(false)}
          onRequestClose={() => setMoveToOrder(false)}
          className={styles.list_wrapper}
        >
          <h3 style={{textAlign: 'center', marginTop: 30, marginBottom: 50}}>
            Are you sure you want to move your orders into Orders section ?
          </h3>
          <div className={styles.actions}>
            <Button
              type="submit"
              color="green"
              size="md"
              onClick={moveToOrders}
            >
              Move
            </Button>
          </div>
        </Modal>
        <Modal
          showCloseButton={false}
          isOpen={fetchingAll}
          className={styles.list_wrapper}
        >
          <div style={{alignItems: 'center', textAlign: 'center'}}>
            <Spinner className={styles.fetchLoader} />
            <h3>Fetching all orders. Please wait...</h3>
          </div>
        </Modal>
        <Modal
          showCloseButton={false}
          isOpen={calculatingRoute}
          className={styles.list_wrapper}
        >
          <div style={{alignItems: 'center', textAlign: 'center'}}>
            <Spinner className={styles.fetchLoader} />
            <h3>Route calculating. Please wait...</h3>
          </div>
        </Modal>
        <Modal
          onRequestClose={() => setShowFilterModal(false)}
          isOpen={showFilterModal}
          onClose={() => setShowFilterModal(false)}
          className={styles.filter_wrapper}
        >
          <div className={styles.filter_wrapper}>
            <Typography variant="h2">Filter and sort</Typography>
            <Select
              className="Location_Select"
              isSearchable={false}
              onChange={handleChangeLocation}
              options={[
                {
                  label: 'Locations',
                  options: locationOptions as any,
                },
              ]}
              value={filter.location}
              placeholder="Location"
              isClearable
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !filter.location.length ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                Control: ({children, ...props}) => (
                  <components.Control {...props}>
                    <img
                      className={'locationIcon'}
                      src={locationIcon}
                      alt="location_icon"
                    />
                    {children}
                  </components.Control>
                ),
                SingleValue: ({children, ...props}) => {
                  return (
                    <components.SingleValue {...props}>
                      {isArray(props.selectProps.value)
                        ? props.selectProps.value
                            .map((item: {label: string}) => item.label)
                            .join(', ')
                        : ''}
                    </components.SingleValue>
                  );
                },
                IndicatorSeparator: () => null,
              }}
              menuPosition="fixed"
            />
            <FormGroup columns={2}>
              <DateTimePicker
                showTimeSelect={false}
                icon={calendar}
                dateFormat="MM/dd/yy E"
                placeholder="Preference date"
                customInput="customInput_Filter"
                value={filter.date ? parseJSON(filter.date) : undefined}
                minTime={filter.date ? parseJSON(filter.date) : undefined}
                onChange={onChangeData}
              />
              <Select
                isSearchable={false}
                onChange={onChangePreferenceTime}
                options={[
                  {label: 'Morning', value: 'morning'},
                  {label: 'Afternoon', value: 'afternoon'},
                  {label: 'Evening', value: 'evening'},
                ]}
                value={filter.time || null}
                placeholder={
                  <span className="select-placeholder-text">
                    Preference time
                  </span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !filter.time ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  Control: ({children, ...props}) => (
                    <components.Control {...props} className={styles.timeIcon}>
                      <img src={stat_time} alt="timer" />
                      {children}
                    </components.Control>
                  ),
                  IndicatorSeparator: () => null,
                  Option: (props) => {
                    const icons = {
                      morning: <Morning />,
                      afternoon: <Afternoon />,
                      evening: <Evening />,
                    };
                    return (
                      <components.Option
                        {...props}
                        className={styles.select_option}
                      >
                        {props.data.label}
                        {icons[props.data.value as keyof typeof icons]}
                      </components.Option>
                    );
                  },
                }}
              />
            </FormGroup>
            <Select
              isSearchable={false}
              onChange={onChangePriority}
              options={[
                {label: 'High', value: 'high'},
                {label: 'Medium', value: 'medium'},
                {label: 'Low', value: 'low'},
              ]}
              value={filter.priority || null}
              placeholder={
                <span className="select-placeholder-text">Priority</span>
              }
              menuPosition="fixed"
              isClearable
              components={{
                DropdownIndicator: (props) =>
                  !filter.priority ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                Option: (props) => {
                  const colors = {
                    high: '#FF0000',
                    medium: '#FFA500',
                    low: '#1A6AE5',
                  };
                  return (
                    <components.Option
                      {...props}
                      className={styles.select_option}
                    >
                      {props.data.label}
                      <div
                        className={styles.priorityColor}
                        style={{
                          backgroundColor:
                            colors[props.data.value as keyof typeof colors],
                        }}
                      ></div>
                    </components.Option>
                  );
                },
              }}
            />
            <Select
              value={sortBy}
              onChange={(val: any) => {
                setSortBy(val);
              }}
              isSearchable={false}
              options={sortOptions}
            />
            <FormActionGroup className={styles.filter_actions}>
              <Button onClick={resetFilter} color="gray" size="md">
                Reset
              </Button>
              <Button onClick={applyFilter} color="blue" size="md">
                Apply
              </Button>
            </FormActionGroup>
          </div>
        </Modal>
        <PermissionModal getRef={(ref) => (permissionRef.current = ref)} />
      </div>
    </>
  );
}

export default DeliveryPlanner;
