import CardContent from 'components/CardContent';
import FormGroup from 'components/FormGroup';
import ListWrapper from 'components/ListWrapper';
import TextField from 'components/TextField';
import {useContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import ReactSwitch from 'react-switch';
import {validateEmail} from 'utils/validators';
import styles from './style.module.scss';
import infoIcon from '../../../EmployeeForm/icons/info.svg';
import EyeIcon from '../../../EmployeeForm/icons/eye.svg';
import EyeDisabledIcon from '../../../EmployeeForm/icons/eye-disable.svg';
import FormActionGroup from 'components/FormActionGroup';
import Button from 'components/Button';
import {onboardingContext} from 'pages/Onboarding';
import api from 'api';
import {User} from 'types';
import toast from 'react-hot-toast';
import {get} from 'lodash';
import Typography from 'components/Typography';

interface FormValue {
  first_name: string;
  last_name: string;
  field_memebers: any[];
  travel_method: string;
  email: string;
  phone: string;
  password: string;
  is_active: boolean;
  password_confirmation: string;
}

function Index() {
  const {data, setData} = useContext(onboardingContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const {
    handleSubmit,
    register,
    formState: {errors},
    watch,
    setValue,
  } = useForm<FormValue>({
    defaultValues: {
      is_active: get(data, 'employee.is_active', true),
      first_name: get(data, 'employee.first_name', ''),
      last_name: get(data, 'employee.last_name', ''),
      email: get(data, 'employee.email', ''),
      phone: get(data, 'employee.phone', ''),
      password: get(data, 'employee.password', ''),
      password_confirmation: get(data, 'employee.password', ''),
    },
  });

  const onSubmit = async (values: FormValue) => {
    if (get(data, 'employee.id', 0)) {
      setData({...data, step: 1});
      return;
    }
    try {
      const {data: user} = await api.post<User>('/users/', {
        ...values,
        phone: values.phone.replace(/\D/g, ''),
        role: 'driver',
      });
      setData({
        ...data,
        employee: {
          ...user,
          phone: values.phone,
          is_active: values.is_active,
          password: values.password,
        },
        step: 1,
      });
    } catch (error) {
      toast.error(get(error, 'response.data.message', 'Something went wrong'));
    }
  };

  return (
    <>
      <Typography variant="h4">
        <b>Create your first employee (Staff)</b> - These are internal users
        such as management, admins, and dispatchers that will only have access
        to the dashboard and not to the Jobs App.
      </Typography>
      <ListWrapper>
        <CardContent px="md" pt="none" pb="md">
          <FormGroup columns={2}>
            <TextField
              label="First name"
              placeholder="First name"
              {...register('first_name', {required: 'Required'})}
              error={errors.first_name?.message}
            />
            <TextField
              label="Last name"
              placeholder="Last name"
              {...register('last_name', {required: 'Required'})}
              error={errors.last_name?.message}
            />
          </FormGroup>

          <FormGroup columns={2}>
            <TextField
              label="Email address"
              placeholder="Email address"
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
            <TextField
              label="Phone number"
              placeholder="(123) 456-7890"
              {...register('phone', {
                required: 'Required',
                validate: (val: string) => {
                  if (val.length !== 14) {
                    return 'Phone number must be 14 numbers';
                  }
                },
              })}
              type="tel"
              minLength={14}
              onChange={(e) => {
                let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
                let formatted = '';

                if (input.length > 0) formatted += '(' + input.substring(0, 3);
                if (input.length >= 4)
                  formatted += ') ' + input.substring(3, 6);
                if (input.length >= 7)
                  formatted += '-' + input.substring(6, 10);

                e.target.value = formatted;
              }}
              error={errors.phone?.message}
            />
          </FormGroup>

          <FormGroup columns={2}>
            <div style={{position: 'relative'}}>
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                {...register('password', {
                  required: 'Required',
                })}
                error={errors.password?.message}
              />
              <img
                onClick={() => setShowPassword((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
            <div style={{position: 'relative'}}>
              <TextField
                label="Password confirmation"
                type={showPassword1 ? 'text' : 'password'}
                placeholder="Password confirmation"
                {...register('password_confirmation', {
                  required: 'Required',
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return 'Your passwords do no match';
                    }
                  },
                })}
                error={errors.password_confirmation?.message}
              />
              <img
                onClick={() => setShowPassword1((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword1 ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
          </FormGroup>

          <div
            style={{
              display: 'flex',
              gap: 3,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div className={styles.tooltipContainer}>
              <img className={styles.infoIcon} src={infoIcon} alt="info" />
              <div className={styles.tooltip}>
                Employees that are active can login, receive jobs and will be
                billed as paid users. Employees that are NOT active can NOT
                login or receive jobs and will not be billed as paid users.{' '}
              </div>
            </div>
            <p style={{marginRight: 7}}>Active:</p>
            <label>
              <ReactSwitch
                onColor="#4000FF"
                onChange={(val) => setValue('is_active', val)}
                handleDiameter={22}
                height={26}
                width={45}
                checked={watch('is_active')}
              />
            </label>
          </div>
        </CardContent>
      </ListWrapper>
      <FormActionGroup
        style={{justifyContent: 'center', marginTop: 50, padding: 20}}
      >
        <Button onClick={handleSubmit(onSubmit)}>Next</Button>
      </FormActionGroup>
    </>
  );
}

export default Index;
