import Modal from 'components/Modal';
import TextField from 'components/TextField';
import Typography from 'components/Typography/Typography';
import {useRef, useState} from 'react';
import styles from './Signup.module.scss';
import Button from 'components/Button/Button';

const returnTimer = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

function ConfirmationModal({
  email,
  onClose,
  confirmHandler,
  resendCode,
  confirmationVisible,
}: {
  email: string;
  onClose: () => void;
  confirmHandler: (code: string) => void;
  resendCode: () => void;
  confirmationVisible: boolean;
}) {
  const [time, setTime] = useState(180);
  const [code, setCode] = useState('');
  const timer = useRef<NodeJS.Timeout | null>(null);

  return (
    <Modal
      onAfterOpen={() => {
        setTime(180);
        timer.current = setInterval(() => {
          setTime((prev) => prev - 1);
        }, 1000);
      }}
      onAfterClose={() => {
        setCode('');
        if (timer.current) {
          clearInterval(timer.current);
        }
      }}
      className={styles.modal}
      onClose={onClose}
      onRequestClose={onClose}
      isOpen={confirmationVisible}
    >
      <Typography variant="h2">Confirm email</Typography>
      <Typography variant="p" className={styles.modalText}>
        We have sent a confirmation code to <b>{email}</b> address. Please check
        your email and enter the code below.
      </Typography>
      <TextField
        onChange={(e) => setCode(e.target.value)}
        label="Confirmation code"
        value={code}
        placeholder="Code"
      />
      <div style={{marginTop: 5}} className={styles.actionWrapper}>
        {time > 0 ? (
          <Typography variant="p">
            You can send new code after {returnTimer(time)}
          </Typography>
        ) : (
          <Typography
            onClick={() => {
              setTime(180);
              resendCode();
            }}
            className={styles.resend}
            variant="p"
          >
            Resend code
          </Typography>
        )}
      </div>

      <div className={styles.actionWrapper}>
        <Button
          onClick={() => {
            confirmHandler(code);
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
