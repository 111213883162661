import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import ListWrapper from 'components/ListWrapper';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import Button from 'components/Button';
import {useForm} from 'react-hook-form';
import {validateEmail} from 'utils/validators';
import useAuth from 'hooks/useAuth';
import {get} from 'lodash';
import api from 'api';
import toast from 'react-hot-toast';
import {useState} from 'react';
import ConfirmationModal from 'pages/Signup/ConfirmationModal';
import EyeIcon from '../EmployeeForm/icons/eye.svg';
import EyeDisabledIcon from '../EmployeeForm/icons/eye-disable.svg';
import styles from '../EmployeeForm/EmployeeForm.module.scss';

type FormValues = {
  name: string;
  email: string;
  phone: string;
  password: string;
  password_confirmation: string;
};
function CompanyDetails() {
  const {currentUser} = useAuth();
  const {
    register,
    formState: {errors},
    handleSubmit,
    getValues,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      name: get(currentUser, 'full_name', ''),
      email: get(currentUser, 'email', ''),
      phone: get(currentUser, 'phone', '')
        ? '(' +
          get(currentUser, 'phone', '').substring(0, 3) +
          ') ' +
          get(currentUser, 'phone', '').substring(3, 6) +
          '-' +
          get(currentUser, 'phone', '').substring(6, 10)
        : '',
    },
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const saveHandler = async (data: FormValues) => {
    setLoading(true);
    try {
      await api.put(`/users/${get(currentUser, 'id', '')}/`, {
        ...data,
        is_active: true,
        phone: data.phone.replace(/\D/g, ''),
      });
      toast.success('Details updated successfully');
    } catch (error) {
      toast.error(get(error, 'response.data.error_message', '') || 'Error');
    } finally {
      setLoading(false);
    }
  };

  const sendConfirmation = async () => {
    try {
      await api.post('/companies/send-code/', {
        email: getValues('email'),
      });
      toast.success('Confirmation code sent to your email');
      setConfirmationVisible(true);
    } catch (error) {
      toast.error(
        get(error, 'response.data.error', '') || 'Something went wrong'
      );
    }
  };

  const confirmHandler = async (value: string) => {
    try {
      await api.post('/companies/verify-code/', {
        code: value,
        email: getValues('email'),
      });
      handleSubmit(saveHandler)();
      setConfirmationVisible(false);
    } catch (error) {
      toast.error(
        get(error, 'response.data.error', '') || 'Something went wrong'
      );
      setLoading(false);
    }
  };

  const checkEmailChange = () => {
    if (getValues('email') !== get(currentUser, 'email', '')) {
      setConfirmationVisible(true);
      sendConfirmation();
    } else {
      handleSubmit(saveHandler)();
    }
  };

  return (
    <div>
      <PageHeader>
        <Typography variant="h2">Profile details</Typography>
      </PageHeader>
      <ListWrapper
        style={{
          maxWidth: 700,
          paddingLeft: 20,
          display: 'flex',
          paddingTop: 20,
          paddingRight: 20,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h3">Details</Typography>
          </div>
          <FormGroup>
            <TextField
              label="Name"
              placeholder="Name"
              {...register('name', {
                required: 'Required',
              })}
              error={errors.name?.message}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Email"
              placeholder="Email"
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Phone number"
              placeholder="(123) 456-7890"
              {...register('phone', {
                required: 'Required',
                minLength: {
                  value: 14,
                  message: 'Enter a valid phone number',
                },
              })}
              type="tel"
              onChange={(e) => {
                let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
                let formatted = '';

                if (input.length > 0) formatted += '(' + input.substring(0, 3);
                if (input.length >= 4)
                  formatted += ') ' + input.substring(3, 6);
                if (input.length >= 7)
                  formatted += '-' + input.substring(6, 10);

                e.target.value = formatted;
                // e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
              error={errors.phone?.message}
            />
          </FormGroup>
          <FormGroup columns={2}>
            <div style={{position: 'relative'}}>
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                {...register('password')}
              />
              <img
                onClick={() => setShowPassword((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
            <div style={{position: 'relative'}}>
              <TextField
                label="Password confirmation"
                type={showPassword1 ? 'text' : 'password'}
                placeholder="Password confirmation"
                {...register('password_confirmation', {
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return 'Your passwords do no match';
                    }
                  },
                })}
                error={errors.password_confirmation?.message}
              />
              <img
                onClick={() => setShowPassword1((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword1 ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
          </FormGroup>
          <Typography style={{marginTop: 10, opacity: 0.5}} variant="p">
            If you want to change your password enter your new password to above
            field
          </Typography>
        </div>
        <Button
          loading={loading}
          onClick={checkEmailChange}
          style={{marginLeft: 'auto', marginBottom: 10, marginTop: 30}}
        >
          Save
        </Button>
      </ListWrapper>
      <ConfirmationModal
        confirmationVisible={confirmationVisible}
        onClose={() => setConfirmationVisible(false)}
        confirmHandler={confirmHandler}
        email={getValues('email')}
        resendCode={sendConfirmation}
      />
    </div>
  );
}

export default CompanyDetails;
