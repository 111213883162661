function Evening() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3354 9.45985C14.0746 10.5906 13.5159 11.6308 12.7172 12.4727C11.9186 13.3145 10.9091 13.9272 9.7937 14.2472C8.67829 14.5671 7.49756 14.5827 6.37411 14.2922C5.25066 14.0017 4.22544 13.4158 3.40491 12.5952C2.58438 11.7747 1.99846 10.7495 1.70796 9.62607C1.41747 8.50262 1.433 7.32189 1.75295 6.20647C2.07289 5.09105 2.68559 4.08161 3.52742 3.28295C4.36925 2.4843 5.40952 1.92555 6.54022 1.66472C6.62277 1.64576 6.70878 1.64809 6.79018 1.67148C6.87159 1.69487 6.94571 1.73856 7.00561 1.79844C7.06551 1.85833 7.10921 1.93245 7.13262 2.01385C7.15602 2.09524 7.15837 2.18126 7.13943 2.26381C6.93095 3.17271 6.95676 4.11967 7.21443 5.01586C7.47209 5.91206 7.95318 6.72812 8.61255 7.3875C9.27193 8.04688 10.088 8.52797 10.9842 8.78564C11.8804 9.04331 12.8273 9.06912 13.7362 8.86064C13.8188 8.8417 13.9048 8.84404 13.9862 8.86744C14.0676 8.89085 14.1417 8.93455 14.2016 8.99444C14.2615 9.05434 14.3052 9.12847 14.3286 9.20987C14.352 9.29128 14.3544 9.3773 14.3354 9.45985Z"
        fill="#666666"
      />
    </svg>
  );
}

export default Evening;
