import {Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import Card from 'components/Card';
import Button from 'components/Button';
import TextField from 'components/TextField';
import CardContent from 'components/CardContent';
import Logo from 'components/Logo';
import Typography from 'components/Typography';

import {validateEmail} from 'utils/validators';
import useAuth from 'hooks/useAuth';
import EyeIcon from '../EmployeeForm/icons/eye.svg';
import EyeDisabledIcon from '../EmployeeForm/icons/eye-disable.svg';
import styles from './Signup.module.scss';
// import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import toast, {Toaster} from 'react-hot-toast';
// import {addressToString} from 'helpers/address';
import api from 'api';
import {get} from 'lodash';
import ConfirmationModal from './ConfirmationModal';

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  passwordConfirmation: string;
  company_name: string;
  company_address: string;
  company_email: string;
  company_phone: string;
  website: string;
}

function Signup() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    // setValue,
    getValues,
    formState: {errors},
  } = useForm<FormValues>();

  const {register: signup} = useAuth();

  async function submit(data: FormValues) {
    setLoading(true);

    try {
      await signup({
        ...data,
        phone: data.company_phone,
        // email: data.company_email,
      });
      toast.success('Email confirmed');
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const confirmHandler = async (value: string) => {
    try {
      await api.post('/companies/verify-code/', {
        code: value,
        email: getValues('email'),
      });
      handleSubmit(submit)();
      setLoading(false);
      setConfirmationVisible(false);
    } catch (error) {
      toast.error(
        get(error, 'response.data.error', '') || 'Something went wrong'
      );
      setLoading(false);
    }
  };

  const sendConfirmation = async () => {
    try {
      await api.post('/companies/send-code/', {
        email: getValues('email'),
      });
      toast.success('Confirmation code sent to your email');
      setConfirmationVisible(true);
    } catch (error) {
      toast.error(
        get(error, 'response.data.error', '') || 'Something went wrong'
      );
    }
  };

  const resendCode = () => {
    sendConfirmation();
  };

  return (
    <Card className={styles.root}>
      <CardContent>
        <Logo />
        <Typography variant="h2" textAlign="center">
          Sign Up
        </Typography>
        <Fragment>
          <form onSubmit={handleSubmit(submit)}>
            <TextField
              label="Company name"
              placeholder="Name"
              error={errors.company_name?.message}
              {...register('company_name', {
                required: 'Required',
              })}
            />
            <TextField
              label="Email"
              placeholder="Email"
              type="email"
              error={errors.email?.message}
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
            />
            <div style={{position: 'relative'}}>
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                error={errors.password?.message}
                {...register('password', {
                  required: 'Required',
                  minLength: {value: 6, message: 'Too short'},
                })}
              />
              <img
                onClick={() => setShowPassword((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
            <div style={{position: 'relative'}}>
              <TextField
                label="Confirm password"
                type={showPassword1 ? 'text' : 'password'}
                placeholder="Confirm password"
                error={errors.passwordConfirmation?.message}
                {...register('passwordConfirmation', {
                  required: 'Required',
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return 'Your passwords do no match';
                    }
                  },
                })}
              />
              <img
                onClick={() => setShowPassword1((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword1 ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
            <div className={styles.button_container}>
              <Button
                onClick={handleSubmit(sendConfirmation)}
                loading={loading}
              >
                Sign up
              </Button>
            </div>
          </form>
          <div className={styles.signup}>
            <Typography variant="p">
              Already have an account?{' '}
              <Link className={styles.link} to="/signin">
                Sign in
              </Link>
            </Typography>
          </div>
        </Fragment>
        {/* <Fragment>
          <form onSubmit={handleSubmit(submit)}>
            <div className={styles.row}>
              <div>
                <Typography variant="h4">Company details</Typography>
                <TextField
                  label="Company name"
                  placeholder="Name"
                  error={errors.company_name?.message}
                  {...register('company_name', {
                    required: 'Required',
                  })}
                />
                <GooglePlaceAutoComplete
                  label="Address"
                  placeholder="Address"
                  onSelectPlace={(data) => {
                    setValue(
                      'company_address',
                      addressToString({
                        address_line_1: data.addressLine1,
                        city: data.city,
                        state: data.state,
                        zipcode: data.zipcode,
                        address_line_2: '',
                        is_primary: false,
                        is_valid: true,
                        label: '',
                        latitude: data.lat,
                        longitude: data.lon,
                        unit_address: '',
                        id: 0,
                        type: 'billing',
                      })
                    );
                  }}
                  error={errors?.company_address?.message}
                  options={{
                    types: ['address'],
                  }}
                />
                <TextField
                  label="Company email"
                  type="email"
                  placeholder="Email"
                  error={errors.company_email?.message}
                  {...register('company_email', {
                    required: 'Required',
                    validate: validateEmail,
                  })}
                />
                <TextField
                  label="Company phone"
                  placeholder="(123) 456-7890"
                  error={errors.company_phone?.message}
                  {...register('company_phone', {
                    required: 'Required',
                    minLength: {
                      value: 14,
                      message: 'Enter a valid phone number',
                    },
                  })}
                  onChange={(e) => {
                    let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
                    let formatted = '';

                    if (input.length > 0)
                      formatted += '(' + input.substring(0, 3);
                    if (input.length >= 4)
                      formatted += ') ' + input.substring(3, 6);
                    if (input.length >= 7)
                      formatted += '-' + input.substring(6, 10);

                    e.target.value = formatted;
                  }}
                />
                <TextField
                  label="Website"
                  placeholder="https://"
                  error={errors.website?.message}
                  {...register('website', {
                    required: 'Required',
                  })}
                />
              </div>
              <div>
                <Typography variant="h4">User details</Typography>
                <TextField
                  label="First name"
                  placeholder="First name"
                  error={errors.first_name?.message}
                  {...register('first_name', {
                    required: 'Required',
                  })}
                />
                <TextField
                  label="Last name"
                  placeholder="Last name"
                  error={errors.last_name?.message}
                  {...register('last_name', {
                    required: 'Required',
                  })}
                />
                <TextField
                  label="Email"
                  placeholder="Email"
                  type="email"
                  error={errors.email?.message}
                  {...register('email', {
                    required: 'Required',
                    validate: validateEmail,
                  })}
                />
                <TextField
                  label="Phone"
                  placeholder="Phone"
                  error={errors.passwordConfirmation?.message}
                  {...register('phone', {
                    required: 'Required',
                    minLength: {
                      value: 14,
                      message: 'Enter a valid phone number',
                    },
                  })}
                  onChange={(e) => {
                    let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
                    let formatted = '';

                    if (input.length > 0)
                      formatted += '(' + input.substring(0, 3);
                    if (input.length >= 4)
                      formatted += ') ' + input.substring(3, 6);
                    if (input.length >= 7)
                      formatted += '-' + input.substring(6, 10);

                    e.target.value = formatted;
                  }}
                />
                <div style={{position: 'relative'}}>
                  <TextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    error={errors.password?.message}
                    {...register('password', {
                      required: 'Required',
                      minLength: {value: 6, message: 'Too short'},
                    })}
                  />
                  <img
                    onClick={() => setShowPassword((pr) => !pr)}
                    className={styles.eyeIcon}
                    src={showPassword ? EyeIcon : EyeDisabledIcon}
                    alt="eye"
                  />
                </div>
                <div style={{position: 'relative'}}>
                  <TextField
                    label="Confirm password"
                    type={showPassword1 ? 'text' : 'password'}
                    placeholder="Confirm password"
                    error={errors.passwordConfirmation?.message}
                    {...register('passwordConfirmation', {
                      required: 'Required',
                      validate: (val: string) => {
                        if (watch('password') !== val) {
                          return 'Your passwords do no match';
                        }
                      },
                    })}
                  />
                  <img
                    onClick={() => setShowPassword1((pr) => !pr)}
                    className={styles.eyeIcon}
                    src={showPassword1 ? EyeIcon : EyeDisabledIcon}
                    alt="eye"
                  />
                </div>
              </div>
            </div>
            <br />
            <hr color="#E5E5E5" style={{marginBottom: 0}} />
            <div className={styles.button_container}>
              <Button
                onClick={handleSubmit(sendConfirmation)}
                loading={loading}
                color="green"
              >
                Sign up
              </Button>
            </div>
          </form>
          <div className={styles.signup}>
            <Typography variant="p">
              Already have an account?{' '}
              <Link className={styles.link} to="/signin">
                Sign in
              </Link>
            </Typography>
          </div>
        </Fragment> */}
      </CardContent>
      <Toaster />
      <ConfirmationModal
        email={getValues('email')}
        confirmHandler={confirmHandler}
        resendCode={resendCode}
        confirmationVisible={confirmationVisible}
        onClose={() => setConfirmationVisible(false)}
      />
    </Card>
  );
}

export default Signup;
