import Button from 'components/Button';
import DataTable from './DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListWrapper from 'components/ListWrapper';
import SearchInput from 'components/SearchInput';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';

import useTeams from './useTeams';

import styles from './Teams.module.scss';
import {DataTableSchemaItem} from 'types';
import {DragDropContext} from 'react-beautiful-dnd';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'dropdown',
    header: '',
    sortable: true,
    colWidth: '40px',
  },
  {
    dataKey: 'name',
    header: 'Team Name',
    sortable: true,
  },
  // {
  //   dataKey: 'members',
  //   header: 'Team Members',
  // },
  // {
  //   dataKey: 'model',
  //   header: 'Vehicle Name',
  //   sortable: true,
  // },
  // {
  //   dataKey: 'travel_method',
  //   header: 'Travel method',
  //   align: 'center',
  //   sortable: false,
  // },
  {
    dataKey: 'view',
    header: '',
    align: 'right',
  },
];

const SUB_SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'first_name',
    header: 'Name',
  },
  {
    dataKey: 'last_name',
    header: 'Last name',
  },
  {
    dataKey: 'phone',
    header: 'Phone number',
  },
  {
    dataKey: 'active',
    header: 'Active',
  },
  {dataKey: 'view', header: '', colWidth: '150px'},
];

function Vehicles() {
  const {
    data,
    isLoading,
    sortBy,
    sortOrder,
    onSort,
    keyWord,
    setKeyWord,
    showSubMembers,
    setSelectedRows,
    selectedRows,
    onAddMemberClick,
    handleDragEnd,
  } = useTeams();

  return (
    <>
      <PageHeader>
        <Typography variant="h2">Teams</Typography>
        <Button to="/people/teams/new" component="a">
          New Team
        </Button>
      </PageHeader>

      <ListWrapper>
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput
            value={keyWord}
            onChange={(val) => {
              setKeyWord(val.target.value);
              sessionStorage.setItem(`search_vehicle`, val.target.value);
            }}
            size="sm"
          />
        </ListActionsWrapper>
        <DragDropContext onDragEnd={handleDragEnd}>
          <DataTable
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={onSort}
            showSubMembers={showSubMembers}
            selectedRows={selectedRows}
            data={data as any}
            onAddMemberClick={onAddMemberClick}
            schema={SCHEMA}
            subSchema={SUB_SCHEMA}
            loading={isLoading}
            selectable={false}
            onClick={(row) =>
              setSelectedRows((pr) => (pr !== row.id ? row.id : 0))
            }
          />
        </DragDropContext>
      </ListWrapper>
    </>
  );
}

export default Vehicles;
