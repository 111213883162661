import ListWrapper from 'components/ListWrapper';
import React, {useContext, useMemo} from 'react';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import {Controller, useForm} from 'react-hook-form';
import SelectField from 'components/SelectField';
import {useQuery} from 'react-query';
import {getDrivers} from 'pages/VehicleForm/VehicleForm';
import {components} from 'react-select';
import Button from 'components/Button';
import CardContent from 'components/CardContent';
import FormActionGroup from 'components/FormActionGroup';
import {SelectOption} from 'types';
import {onboardingContext} from 'pages/Onboarding';
import api from 'api';
import {get} from 'lodash';
import toast from 'react-hot-toast';
import Typography from 'components/Typography';

type FormValue = {
  driver: SelectOption[];
  name: string;
  model: string;
  travel_method: SelectOption;
};

function Index() {
  const {data, setData} = useContext(onboardingContext);
  const {data: members} = useQuery('drivers', getDrivers);

  const {
    control,
    handleSubmit,
    register,
    formState: {errors},
  } = useForm<FormValue>({
    defaultValues: {
      name: get(data, 'team.name', ''),
      model: get(data, 'team.model', ''),
      driver: get(data, 'team.drivers', '')
        ? get(data, 'team.drivers', []).map((d: any) => ({
            value: d.id,
            label: d.first_name + ' ' + d.last_name,
            isActive: d.is_active === 'True',
          }))
        : undefined,
      travel_method: get(data, 'team.travel_method', '')
        ? {
            value: get(data, 'team.travel_method', ''),
            label: get(data, 'team.travel_method') === 'car' ? 'Car' : 'Bike',
          }
        : undefined,
    },
  });

  const membersOptions = useMemo(
    function () {
      if (!members) return [];

      return members.results.map((d) => ({
        value: d.id,
        label: d.full_name,
        isActive: d.is_active === 'True',
      }));
    },
    [members]
  );

  const onSubmit = async (values: FormValue) => {
    if (get(data, 'team.id', 0)) {
      setData({...data, step: 2});
      return;
    }
    try {
      const {data: team} = await api.post('/teams/', {
        ...values,
        travel_method: values.travel_method.value,
        drivers: values.driver.map((d) => d.value),
      });
      setData({...data, team, step: 2});
    } catch (error) {
      toast.error(get(error, 'response.data.message', 'Something went wrong'));
    }
  };

  return (
    <>
      <Typography variant="h4">
        <b>Create your first team members (Team)</b> - These are users of the
        Jobs App that will receive and complete jobs.
      </Typography>
      <ListWrapper>
        <CardContent px="md" pt="none" pb="md">
          <FormGroup columns={2}>
            <TextField
              label="Team name"
              placeholder="Name"
              {...register('name', {required: 'Required'})}
              error={errors.name?.message}
            />

            <TextField
              label="Vehicle name"
              placeholder="Vehicle name"
              {...register('model')}
              error={errors.model?.message}
            />
          </FormGroup>
          <FormGroup columns={1}>
            <Controller
              name="driver"
              control={control}
              render={({field, fieldState}) => (
                <div>
                  <SelectField
                    label="Team member"
                    placeholder="Select team member"
                    options={membersOptions}
                    components={{
                      Option: (props) => {
                        const {children, data} = props;

                        const isActive = membersOptions.find(
                          (item) => item.value === data.value
                        )?.isActive;

                        return (
                          <components.Option {...props}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 5,
                              }}
                            >
                              <div
                                style={{
                                  width: 8,
                                  height: 8,
                                  borderRadius: 5,
                                  marginRight: 5,
                                  backgroundColor: isActive ? 'green' : 'red',
                                }}
                              />
                              {children}
                            </div>
                          </components.Option>
                        );
                      },
                      MultiValueContainer: (props) => {
                        const {children, data} = props;

                        const isActive = membersOptions.find(
                          (item) => item.value === data.value
                        )?.isActive;

                        return (
                          <div
                            style={{
                              display: 'flex',
                              marginRight: 5,
                              backgroundColor: '#e5e5e5',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                width: 8,
                                height: 8,
                                borderRadius: 5,
                                marginLeft: 5,
                                backgroundColor: isActive ? 'green' : 'red',
                              }}
                            />
                            {children}
                          </div>
                        );
                      },
                    }}
                    //@ts-ignore
                    isMulti
                    error={fieldState.error?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </FormGroup>
          <FormGroup columns={1}>
            <Controller
              name="travel_method"
              control={control}
              render={({field, fieldState}) => (
                <div>
                  <SelectField
                    label="Travel method"
                    placeholder="Select travel method"
                    options={[
                      {value: 'car', label: 'Car'},
                      {value: 'bike', label: 'Bike'},
                    ]}
                    error={fieldState.error?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </FormGroup>
        </CardContent>
      </ListWrapper>
      <FormActionGroup
        style={{justifyContent: 'center', marginTop: 50, padding: 20}}
      >
        <Button
          onClick={() => setData({...data, step: data.step - 1})}
          color="blue"
        >
          Prev
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Next</Button>
      </FormActionGroup>
    </>
  );
}

export default Index;
