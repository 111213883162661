import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Button from 'components/Button';
import {get} from 'lodash';
import {useState} from 'react';
import toast from 'react-hot-toast';
import useAuth from 'hooks/useAuth';
import FormGroup from 'components/FormGroup';
import SelectField from 'components/SelectField';
import {SelectOption} from 'types';
import TextField from 'components/TextField';
import ReactSwitch from 'react-switch';

type Props = {
  createNewCard: (data: any) => void;
  addAddress: () => void;
  addresses: any[];
  onClose: () => void;
  customer?: string;
};

function NewCard({
  createNewCard,
  onClose,
  addresses,
  addAddress,
  customer,
}: Props) {
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(0);
  const [isPrimary, setIsPrimary] = useState(false);
  const [holderName, setHolderName] = useState('');
  const [selectedAddress, setSelectedAddress] = useState<SelectOption>();

  const onSubmit = async () => {
    if (!holderName) {
      setError(1);
      return;
    }
    if (!selectedAddress) {
      setError(2);
      return;
    }
    setError(0);
    setLoading(true);
    try {
      //@ts-ignore
      const response = await stripe?.createPaymentMethod({
        type: 'card',
        card: elements?.getElement(CardElement),
      });
      if (response?.error) {
        setLoading(false);
        toast.error(response.error.message || 'Something went wrong');
        return;
      }

      const body = {
        customer,
        billing_address: selectedAddress.value,
        card_type: get(response, 'paymentMethod.card.display_brand', ''),
        card_number:
          '************' + get(response, 'paymentMethod.card.last4', ''),
        token: response?.paymentMethod.id,
        cardholder_name: holderName,
        is_primary: isPrimary,
      };
      await createNewCard(body);
      onClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(get(error, 'message', '') || 'Something went wrong');
    }
  };

  return (
    <div className="container">
      <h2 className="text-center">Add Payment Method</h2>
      <div className="card">
        <div style={{height: 20}} />
        <CardElement />
        <FormGroup style={{marginTop: 30}}>
          <TextField
            label="Card holder name"
            placeholder="Enter card holder name"
            hasError={false}
            onChange={(e: any) => {
              setHolderName(e.target.value);
              if (error === 1) {
                setError(0);
              }
            }}
            error={error === 1 ? 'Please, fill holder name' : ''}
          />
        </FormGroup>
        <FormGroup style={{position: 'relative', marginTop: 10}}>
          <SelectField
            label="Billing Address"
            placeholder="Select address"
            options={addresses
              .filter((item) => item.type === 'billing')
              .map((item) => ({
                value: item.id,
                label: `${item.address_line_1}, ${item.city}, ${item.state}, ${item.zipcode}`,
              }))}
            error={error ? 'Please select address' : ''}
            value={selectedAddress}
            onChange={(newValue) => {
              if (newValue) {
                if (error === 2) {
                  setError(0);
                }
                setSelectedAddress(newValue);
              }
            }}
          />

          <Button
            style={{
              position: 'absolute',
              padding: 0,
              height: 18,
              width: 18,
              borderRadius: 10,
              paddingBottom: 1,
              left: 110,
              top: 21,
              zIndex: 1,
            }}
            onClick={addAddress}
            component="a"
          >
            +
          </Button>
        </FormGroup>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}
        >
          <div>
            <p>Primary card:</p>
          </div>
          <ReactSwitch checked={isPrimary} onChange={setIsPrimary} />
        </div>
        <div style={{height: 20}} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: 6,
            marginTop: 20,
          }}
        >
          <Button
            loading={loading}
            style={{padding: '0px 60px'}}
            onClick={onSubmit}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
}

const CardMain = (props: Props) => {
  const {currentUser} = useAuth();
  const stripePromise = loadStripe(
    get(currentUser, 'company.payment_integration.public_key', '')
  );

  return (
    <Elements stripe={stripePromise}>
      <NewCard {...props} />
    </Elements>
  );
};

export default CardMain;
