import {useMutation} from 'react-query';
import {CustomerService} from 'services/customer.service';

const useSendCustomerNotification = () => {
  const {mutateAsync, isLoading} = useMutation({
    mutationFn: async ({
      requestId,
      request_drugs,
      types,
    }: {
      requestId: number;
      request_drugs: Array<any>;
      types: any;
    }) => {
      const customerService = new CustomerService();
      return customerService.sendNotification(requestId, request_drugs, types);
    },
  });

  return {
    sendNotification: mutateAsync,
    isSending: isLoading,
  };
};

export default useSendCustomerNotification;
