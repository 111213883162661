import {useEffect} from 'react';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import Modal from 'components/Modal';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import React from 'react';
import {useForm, useFormState} from 'react-hook-form';
import {Prescription, SelectOption} from 'types';
import styles from './PrescriptionDrugModal.module.scss';
import Select from 'components/Select';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import {SingleValue} from 'react-select';

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  drug?: Prescription;
  onSubmit: (values: any) => Promise<any>;
  sync_source?: string | null;
};

const payment_method = [
  {value: 'app_purchase', label: 'App Purchase'},
  {value: 'phone_call', label: 'Phone Call'},
  {value: 'in_store_cash', label: 'In-Store Cash'},
  {value: 'in_store_back_card', label: 'In-Store Bank Card'},
  {value: 'cash_on_delivery', label: 'Cash on Delivery'},
  {value: 'payment_link', label: 'Payment Link'},
];

const PrescriptionDrugModal: React.VFC<Props> = ({
  isOpen = false,
  onClose,
  onSubmit,
  drug,
}) => {
  const {handleSubmit, register, control, reset, setValue} = useForm();
  const {isSubmitting} = useFormState({control});

  useEffect(() => {
    reset();
  }, [drug, reset]);

  const onChangeMethod = (newValue: SingleValue<SelectOption>) => {
    setValue('payment_method', newValue?.value);
  };

  if (!drug) {
    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      onClose={onClose}
      showCloseButton={true}
      className={styles.modal}
    >
      <div className={styles.header}>
        <div className={styles.header__title}>
          <Typography variant="h2">{drug.name}</Typography>
          <Typography variant="p">{drug.strength}</Typography>
        </div>
        {/* <div className={styles.header__status}>
          <ScriptStatusSelect
            name={`drug-${drug.id}`}
            status={drug.status}
            onChange={onChangeStatus}
            type="prescription"
          />
        </div> */}
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.block}>
          <Typography variant="h3">Invoice Fields</Typography>
          <FormGroup>
            <TextField defaultValue={drug.id} label="Offering ID" readOnly />
          </FormGroup>
          <FormGroup>
            <TextField
              defaultValue={drug.cash_price || 0}
              label="Price $"
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <TextField defaultValue={drug.quantity} label="Quantity" readOnly />
          </FormGroup>
          <FormGroup>
            <TextField
              defaultValue={
                Number(drug.cash_price) * Number(drug.quantity) || 0
              }
              label="Total $"
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <TextField
              {...register('amount_due', {min: 0})}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9.]/g, '');
                setValue('amount_due', value);
              }}
              // type="number"
              step={0.01}
              defaultValue={drug.amount_due || 0}
              label="Due $"
            />
          </FormGroup>
        </div>
        <div className={styles.seperator} />
        <div className={styles.block}>
          <Typography variant="h3">Payment Fields</Typography>
          {/* <FormGroup>
            <TextField
              defaultValue={drug.bill_type}
              label="Bill Type"
              readOnly
            />
          </FormGroup> */}
          <FormGroup>
            <TextField
              defaultValue={drug.is_paid ? 'Yes' : 'No'}
              label="Paid"
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <TextField
              defaultValue={drug.paid_at ? drug.paid_at : 'Is not available'}
              label="Payment Date"
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <FieldWrapper label="Payment Method">
              <Select
                placeholder="Select payment method"
                onChange={onChangeMethod}
                className={styles.select}
                defaultValue={
                  drug.payment_method
                    ? {
                        label: payment_method.find(
                          (item) => item.value === drug.payment_method
                        )?.label,
                        value: drug.payment_method,
                      }
                    : undefined
                }
                options={payment_method}
              />
            </FieldWrapper>
          </FormGroup>
          <FormGroup>
            <TextField
              defaultValue={drug.paid_by || ''}
              label="Paid by"
              {...register('paid_by')}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              defaultValue={drug.bank_card || ''}
              label="Bank Card"
              {...register('bank_card')}
            />
          </FormGroup>

          <div className={styles.actions}>
            <Button type="submit" size="md" loading={isSubmitting}>
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default PrescriptionDrugModal;
