import {SingleValue, ActionMeta} from 'react-select';

import Typography from 'components/Typography';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import {ScriptStatusOption} from 'components/ScriptStatusSelect';

import {Prescription, RequestDetails, SelectOption} from 'types';

import styles from '../RequestDetails.module.scss';
import Button from 'components/Button';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PrescriptionDrugModal from './PrescriptionDrugModal';
import {RequestService} from 'services/request.service';
import {useQueryClient} from 'react-query';
import {get} from 'lodash';
// import {mapMMStatusText} from 'constants/mappings';
import Modal from 'components/Modal';
import classNames from 'classnames';
import QRCode from 'react-qr-code';
import ReactToPrint from 'react-to-print';
import useSendCustomerNotification from 'hooks/useSendCustomerNotification';

interface Props {
  data: RequestDetails;
  requestId: number;
  origin?: string;
  status?: string;
  sync_source?: string | null;
  drugs: Prescription[];
  onSendNotification: (id: number | undefined, types: any) => Promise<any>;
  onCharge: (id: number | undefined) => Promise<any>;
  onRequestSignature: () => Promise<any>;
  onChangeStatus: (
    newValue: SingleValue<ScriptStatusOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => void;
}

function Prescriptions({
  drugs,
  data,
  onCharge,
  onSendNotification,
  requestId,
  status,
  sync_source,
}: Props) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDrug, setSelectedDrug] = useState<Prescription>();
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const [notifyToPay, setNotifyToPay] = useState({
    is_sms: true,
    is_email: true,
    is_app_push: true,
  });
  const [paymentUrl, setPaymentUrl] = useState('');
  const {sendNotification} = useSendCustomerNotification();

  const prescriptionId = useRef<number | undefined>();
  const printRef = useRef<any>(null);

  const handleEditDrug = useCallback((drug: Prescription) => {
    setSelectedDrug(drug);
    if (drug) {
      setIsEditModalOpen(true);
    }
  }, []);

  const handleUpdateDrug = useCallback(
    async (data: {amount_due?: string}) => {
      if (!selectedDrug) {
        return;
      }

      const requestService = new RequestService();
      await requestService.updateRequestDrug(selectedDrug.id, data);
      queryClient.invalidateQueries(['request_details', requestId.toString()]);
      setIsEditModalOpen(false);
    },
    [queryClient, requestId, selectedDrug]
  );

  useEffect(() => {
    if (selectedDrug) {
      setSelectedDrug(drugs.find((item) => item.id === selectedDrug.id));
    }
  }, [drugs, selectedDrug]);

  const handleSendNotification = useCallback(() => {
    onSendNotification(prescriptionId.current, notifyToPay);
    setIsNotifyModalOpen(false);
  }, [onSendNotification, prescriptionId, notifyToPay]);

  const handleOpenPaymentModal = useCallback(async () => {
    setIsNotifyModalOpen(true);
    const res = await sendNotification({
      requestId: data?.id,
      types: {
        is_sms: false,
        is_email: false,
        is_app_push: false,
      },
      request_drugs:
        data.status === 'completed'
          ? data.prescriptions.filter((item) =>
              prescriptionId.current
                ? prescriptionId.current === item.id
                : item.status !== 'completed'
            )
          : prescriptionId.current
          ? data.prescriptions.filter(
              (item) => item.id === prescriptionId.current
            )
          : data.prescriptions,
    });

    const paymentUrl = res.data.payment_link;
    setPaymentUrl(paymentUrl);
  }, [data, prescriptionId, sendNotification]);

  const notifyToPayActions = useMemo(() => {
    const types = {
      is_sms: 'SMS',
      is_email: 'Email',
      is_app_push: 'App Push',
    };
    return Object.keys(notifyToPay).map((key) => {
      return (
        <div className={styles.notify_to_pay_action} key={key}>
          <input
            type="checkbox"
            className={styles.notify_to_pay_action__checkbox}
            checked={notifyToPay[key as keyof typeof notifyToPay]}
            onChange={() =>
              setNotifyToPay({
                ...notifyToPay,
                [key]: !notifyToPay[key as keyof typeof notifyToPay],
              })
            }
          />
          <Typography variant="h3">
            {types[key as keyof typeof types]}
          </Typography>
        </div>
      );
    });
  }, [notifyToPay]);

  return (
    <>
      <Card>
        <CardContent px="sm" pt="md" pb="sm">
          <div style={{marginBottom: 25}} className={styles.row}>
            <div style={{flex: 1}}>
              <p style={{margin: 0}}>Total amount:</p>
              <p style={{margin: 0, marginTop: 5}}>
                <b>
                  $
                  {drugs
                    .reduce((pr, curr) => pr + Number(curr.amount_due), 0)
                    .toFixed(2)}
                </b>
              </p>
            </div>
            <div style={{flex: 1}}>
              <p style={{margin: 0}}>Total paid:</p>
              <p style={{margin: 0, marginTop: 5}}>
                <b>
                  $
                  {drugs
                    .reduce((pr, curr) => pr + (Number(curr.paid) || 0), 0)
                    .toFixed(2)}
                </b>
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <Button
              disabled={
                drugs.filter((item) => Number(item.amount_due) > 0).length ===
                  0 ||
                (status === 'completed'
                  ? drugs.filter((item) => item.status !== 'completed')
                      .length === 0
                  : false)
              }
              onClick={() => {
                setSelectedDrug(undefined);
                prescriptionId.current = undefined;
                handleOpenPaymentModal();
              }}
              className={styles.notify_action}
            >
              Notify to Pay All
            </Button>
            <Button
              onClick={() => onCharge(undefined)}
              disabled={
                drugs.filter((item) => Number(item.amount_due) !== 0).length ===
                  0 || status === 'completed'
              }
              className={styles.notify_action}
            >
              Charge All
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card>
        <PrescriptionDrugModal
          isOpen={isEditModalOpen}
          drug={selectedDrug}
          sync_source={sync_source}
          onClose={() => setIsEditModalOpen(false)}
          // onChangeStatus={onChangeStatus}
          onSubmit={handleUpdateDrug}
        />
        <CardContent px="sm" pt="sm" pb="sm">
          {/* <Typography component="p" variant="label">
            Service
          </Typography> */}
          {drugs.map((drug) => (
            <div key={drug.id} className={styles.drug}>
              {/* <Typography component="p" variant="label">
                {drug.strength}
              </Typography> */}
              <div className={styles.drugActions}>
                <Typography
                  component="p"
                  variant="h3"
                  className={styles.drug__name}
                >
                  {drug.name}
                </Typography>
                {/* <p></p> */}
                {/* <p>Status: {mapMMStatusText[drug.status as 'completed']}</p> */}

                <Button
                  color="gray"
                  component="button"
                  size="sm"
                  onClick={() => handleEditDrug(drug)}
                >
                  Edit
                </Button>
              </div>
              <div className={styles.payments}>
                <div className={styles.payment}>
                  <Typography variant="label" className={styles.payment__label}>
                    Amount due
                  </Typography>
                  <Typography
                    variant="strong"
                    className={styles.payment__value}
                  >
                    ${drug.amount_due || 0}
                  </Typography>
                </div>
                <div className={styles.payment}>
                  <Typography variant="label" className={styles.payment__label}>
                    Paid
                  </Typography>
                  <Typography
                    variant="strong"
                    className={styles.payment__value}
                  >
                    ${drug.paid || 0}
                  </Typography>
                </div>
              </div>
              <div style={{marginTop: 15}} className={styles.row}>
                <Button
                  disabled={
                    Number(drug?.amount_due) === 0 ||
                    drug.status === 'completed'
                  }
                  onClick={() => {
                    prescriptionId.current = drug.id;
                    handleOpenPaymentModal();
                    setSelectedDrug(drug);
                  }}
                  className={styles.notify_action}
                >
                  Notify to Pay
                </Button>
                <Button
                  onClick={() => onCharge(drug.id)}
                  disabled={
                    get(drug, 'is_paid', false) ||
                    drug.status === 'completed' ||
                    Number(get(drug, 'amount_due')) === 0
                  }
                  className={styles.notify_action}
                >
                  Charge Card
                </Button>
              </div>
            </div>
          ))}
        </CardContent>
      </Card>
      <Modal
        onClose={() => setIsNotifyModalOpen(false)}
        className={styles.notify_to_pay_modal}
        isOpen={isNotifyModalOpen}
      >
        <Typography variant="h1">Notify to Pay</Typography>
        <div className={styles.notify_to_pay_actions}>{notifyToPayActions}</div>
        <div>
          <Typography
            style={{marginBottom: 20}}
            className={styles.title}
            variant="h2"
          >
            {false ? 'Thank you for the purchase' : 'Payment Details'}
          </Typography>
          {false && (
            <div style={{textAlign: 'center'}}>
              {/* <img
                      className={styles.successImg}
                      src={successImg}
                      alt="Success"
                    /> */}
              <Typography
                style={{marginBottom: 20}}
                className={styles.title}
                variant="h2"
              >
                Successfully paid
              </Typography>
            </div>
          )}
          <div ref={printRef}>
            <div
              style={{
                // display: 'flex',
                marginBottom: 20,
                flexDirection: !false ? 'row' : 'column',
              }}
            >
              <div style={{marginBottom: 20}}>
                <Typography
                  className={classNames(
                    styles.label,
                    !false && styles.noSuccess
                  )}
                  variant="label"
                >
                  Patient
                </Typography>
                <Typography
                  className={classNames(
                    styles.payment__label,
                    !false && styles.noSuccessText
                  )}
                  variant="p"
                >
                  {get(data, 'customer.user.first_name', '') +
                    ' ' +
                    get(data, 'customer.user.last_name', '')}
                </Typography>
              </div>
              <div>
                <Typography
                  className={classNames(
                    styles.label,
                    !false && styles.noSuccess
                  )}
                  variant="label"
                >
                  Product Name
                </Typography>
                <Typography
                  className={classNames(
                    styles.payment__label,
                    !false && styles.noSuccessText
                  )}
                  variant="p"
                >
                  {selectedDrug
                    ? get(selectedDrug, 'name')
                    : drugs.map((item) => item.name).join(', ')}
                </Typography>
              </div>
            </div>
            <div
              style={{
                // display: 'flex',
                flexDirection: !false ? 'row' : 'column',
              }}
            >
              <div style={!false ? {flex: 1, marginBottom: 20} : {}}>
                <Typography
                  className={classNames(
                    styles.label,
                    !false && styles.noSuccess
                  )}
                  variant="label"
                >
                  Order #
                </Typography>
                <Typography
                  className={classNames(
                    styles.payment__label,
                    !false && styles.noSuccessText
                  )}
                  variant="p"
                >
                  {get(data, 'sequential_order_number')}
                </Typography>
              </div>

              <div style={!false ? {flex: 1, marginBottom: 20} : {}}>
                <Typography
                  className={classNames(
                    styles.label,
                    !false && styles.noSuccess
                  )}
                  variant="label"
                >
                  Payment amount
                </Typography>
                <Typography
                  className={classNames(
                    styles.payment__label,
                    !false && styles.noSuccessText
                  )}
                  variant="p"
                >
                  $
                  {selectedDrug
                    ? get(selectedDrug, 'amount_due')
                    : drugs.reduce(
                        (pr, curr) => pr + Number(curr.amount_due),
                        0
                      )}
                </Typography>
              </div>

              <div style={!false ? {flex: 1} : {}}>
                <Typography
                  className={classNames(
                    styles.label,
                    !false && styles.noSuccess
                  )}
                  variant="label"
                >
                  Status
                </Typography>

                <Typography
                  className={classNames(
                    styles.payment__label,
                    false && !false && styles.noSuccessText
                  )}
                  style={{
                    backgroundColor: false ? '#21db6b' : '#ff3b24',
                    fontWeight: '500',
                    padding: '3px',
                    borderRadius: 5,
                    color: '#fff',
                    width: 80,
                    textAlign: 'center',
                  }}
                  variant="p"
                >
                  {false ? 'Paid' : 'Unpaid'}
                </Typography>
              </div>
            </div>
            <Typography style={{marginTop: 20}} variant="h3">
              Scan to pay:
            </Typography>
            {paymentUrl && (
              <div className={styles.qr_code}>
                <QRCode size={100} value={paymentUrl} />
              </div>
            )}
          </div>

          <ReactToPrint
            pageStyle={'@page { size: 2.5in 7in; margin: 10mm; }'}
            trigger={() => (
              <Typography className={styles.download_invoice} variant="p">
                Download Invoice
              </Typography>
            )}
            content={() => printRef.current}
          />
        </div>

        <Button
          style={{
            marginLeft: 'auto',
            marginTop: 20,
          }}
          onClick={handleSendNotification}
        >
          Send
        </Button>
      </Modal>
    </>
  );
}

export default Prescriptions;
