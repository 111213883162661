import {useEffect, useRef, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';
import api from 'api';
import {DataItem, Driver, ListResponse, Vehicle} from 'types';
import ViewDetails from 'components/ViewDetails';
import useSort from 'hooks/useSort';
import useDebounce from 'hooks/useDebounce';
import {EmployeStatus} from '../Employees/useEmployees';
import arrow_bottom from 'pages/DeliveryPlanner/components/Ticket/arrow_bottom.svg';
import {useHistory} from 'react-router-dom';
import {DropResult} from 'react-beautiful-dnd';

async function getVehicles({queryKey}: QueryFunctionContext) {
  const [, order, keyWord] = queryKey;
  const {data} = await api.get<ListResponse<Vehicle>>('/teams/', {
    params: {order, search: keyWord ? keyWord : undefined},
  });

  return data;
}

function mapVehicles(vehiles: Vehicle[]) {
  return vehiles.map((v) => ({
    ...v,
    members: v.drivers
      ? v.drivers
          .map((item) => item.first_name + ' ' + item.last_name)
          .slice(0, 2)
          .join(', ') + (v.drivers.length > 2 ? '...' : '')
      : '',
    view: v.id ? <ViewDetails to={`/people/teams/${v.id}`} /> : null,
  }));
}

function useVehicles() {
  const {push} = useHistory();
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_vehicle`) || ''
  );
  const [selectedRows, setSelectedRows] = useState<number | string>('default');
  const {sortBy, sortOrder, order, onSort} = useSort('name');

  const searchDebounced = useDebounce(keyWord, 400);

  const {data: vehicles, isLoading} = useQuery(
    ['vehicles', order, searchDebounced],
    getVehicles
  );

  const initializedCount = useRef(0);

  const [data, setData] = useState<Vehicle[]>([]);

  const returnDrivers = (drivers: Driver[]) => {
    return drivers.map((d) => ({
      ...d,
      view: <ViewDetails to={`/people/employees/${d.user_id}`} />,
      active: <EmployeStatus id={d.user_id} active={d.is_active === 'True'} />,
      phone: d.phone
        ? '(' +
          d.phone.substring(0, 3) +
          ') ' +
          d.phone.substring(3, 6) +
          '-' +
          d.phone.substring(6, 10)
        : '-',
    }));
  };

  type Team = {
    id: string;
    drivers: number[];
    name: string;
    model: string;
    travel_method: string;
  };

  const updateTeams = async (team1: Team, team2: Team) => {
    try {
      if (team1.id !== 'default') {
        api.put(`/teams/${team1.id}/`, team1);
      }
      if (team2.id !== 'default') {
        api.put(`/teams/${team2.id}/`, team2);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (vehicles) {
      if (initializedCount.current < 2) {
        setData(
          mapVehicles(vehicles.results).map((v) => ({
            ...v,
            id: v.id || 'default',
            drivers: returnDrivers(v.drivers || []),
            dropdown: <ArrowBottom />,
          }))
        );
      }
    }
    initializedCount.current++;
  }, [vehicles]);

  const onAddMemberClick = (item: DataItem) => {
    push(`/people/employees/new`, {
      state: {
        team_id: item.id === 'default' ? null : item.id,
      },
    });
  };

  const reorder = (list: Driver[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result: DropResult) => {
    const {source, destination, draggableId} = result;

    if (
      !destination ||
      !source ||
      source.droppableId === destination.droppableId
    ) {
      if (destination && source.droppableId === destination.droppableId) {
        const clonedData = [...data];
        const teamId = source.droppableId.split('-')[1];

        const index = clonedData.findIndex(
          (d) => d.id === (teamId === 'default' ? 'default' : Number(teamId))
        );

        clonedData[index].drivers = reorder(
          clonedData[index].drivers || [],
          source.index,
          destination.index
        );

        setData(clonedData);
        return;
      }
      return;
    }

    const clonedData = [...data];

    const toId = destination.droppableId.split('-')[1];
    const fromId = source.droppableId.split('-')[1];

    const toIndex = clonedData.findIndex(
      (d) => d.id === (toId === 'default' ? 'default' : Number(toId))
    );

    const fromIndex = clonedData.findIndex(
      (d) => d.id === (fromId === 'default' ? 'default' : Number(fromId))
    );

    const memberId = draggableId.split('-')[1];

    const member = clonedData[fromIndex].drivers?.find(
      (d) => d.id === Number(memberId)
    );

    clonedData[fromIndex].drivers = clonedData[fromIndex].drivers?.filter(
      (d) => d.id !== Number(memberId)
    );

    clonedData[toIndex].drivers = [
      ...(clonedData[toIndex].drivers || []),
      member as any,
    ];

    updateTeams(
      {
        id: fromId,
        drivers: clonedData[fromIndex].drivers?.map((d) => d.id) || [],
        name: clonedData[fromIndex].name,
        model: clonedData[fromIndex].model,
        travel_method: clonedData[fromIndex].travel_method || '',
      },
      {
        id: toId,
        drivers: clonedData[toIndex].drivers?.map((d) => d.id) || [],
        name: clonedData[toIndex].name,
        model: clonedData[toIndex].model,
        travel_method: clonedData[toIndex].travel_method || '',
      }
    );

    setData(clonedData);
    setSelectedRows(toId === 'default' ? 'default' : Number(toId));
  };

  return {
    data,
    vehicles: data,
    isLoading,
    sortBy,
    sortOrder,
    onSort,
    keyWord,
    setData,
    setKeyWord,
    selectedRows,
    setSelectedRows,
    updateTeams,
    showSubMembers: selectedRows,
    onAddMemberClick,
    handleDragEnd,
  };
}

const ArrowBottom = () => {
  return (
    <img
      alt="arrow bottom"
      src={arrow_bottom}
      style={{
        width: 30,
        height: 30,
        marginTop: 5,
      }}
    />
  );
};

export default useVehicles;
