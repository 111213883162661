import close from './icons/close.svg';

import styles from './ImageView.module.scss';

interface Props {
  show?: boolean;
  onClose: () => any;
  images: string[];
}

function ImageView({show, onClose, images = []}: Props) {
  // const [status, setStatus] = useState<'requesting' | 'requested'>();

  // async function handleClick() {
  //   setStatus('requesting');
  //   await wait(1500);
  //   setStatus('requested');
  // }

  if (!show) return null;

  return (
    <div className={styles.root}>
      <div className={styles.images}>
        <img
          className={styles.close}
          alt="close"
          src={close}
          onClick={onClose}
        />
        {images.map((image, index) => (
          <img key={index} className={styles.image} alt={image} src={image} />
        ))}
      </div>
    </div>
  );
}

export default ImageView;
