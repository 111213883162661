import {useCallback, useEffect, useMemo, useState} from 'react';
import {format} from 'date-fns';
import {QueryFunctionContext, useQuery} from 'react-query';

import Typography from 'components/Typography';
import StatusBadge from 'components/StatusBadge';
import ViewDetails from 'components/ViewDetails';

import {
  Address,
  Customer,
  DataItem,
  DataTableSchemaItem,
  ListResponse,
  Product,
  Request,
} from 'types';
import useSort from 'hooks/useSort';
import api from 'api';
import {
  mapStatusColor,
  mapStatusText,
  mapMMStatusText,
  mapMMSubStatusColor,
} from 'constants/mappings';
import {get, reject, sortBy as sortLodash} from 'lodash';
import {FilterOption} from 'pages/DeliveryPlanner/components/Filter/Filter';
import {useHistory, useRouteMatch} from 'react-router-dom';
import toast from 'react-hot-toast';

async function getRequests({queryKey}: QueryFunctionContext<any[]>) {
  const [
    ,
    page,
    order,
    status,
    userId,
    keyWord,
    source,
    date,
    location,
    preference_time,
    onlyOTC,
    paid,
    priority,
  ] = queryKey;

  const {data} = await api.get<ListResponse<Request>>('/requests', {
    params: {
      order: order,
      status: status ? status : undefined,
      user_id: userId,
      offset: +page * 20,
      search: keyWord,
      limit: 40,
      source: source,
      preference_date: date ? format(date, 'yyyy-MM-dd') : undefined,
      zip_code:
        location.length > 0
          ? location.reduce((pr: [], curr: any) => [...pr, curr.label], [])
          : undefined,
      preference_time: preference_time ? preference_time.value : undefined,
      priority: priority ? priority.value : undefined,
      offering_type: onlyOTC ? onlyOTC.value : undefined,
      is_paid: paid ? paid.value === 'true' : undefined,
    },
  });

  return data;
}

function transformData(data: Request[], source: string) {
  return data.map((item) => ({
    ...item,
    sync_time: get(item, 'sync_date', '') + '\n' + item.sync_time,
    amount_due: `$${item.amount_due?.toFixed(2)}`,
    number: (
      <Typography variant="strong">#{item.sequential_order_number}</Typography>
    ),
    drug_name:
      source === 'micromerchant'
        ? item.drug_name ?? ''
        : item.drug_name
        ? `${item.drug_name}...`
        : '',
    preference_date: item.preference_date
      ? `${item.preference_date} / ${item.preference_time}`
      : '',

    status:
      source === 'micromerchant' ? (
        // @ts-ignore
        <StatusBadge color={mapMMSubStatusColor[item.status]}>
          {/*// @ts-ignore*/}
          {mapMMStatusText[item.status]}
        </StatusBadge>
      ) : (
        <StatusBadge color={mapStatusColor[item.status]}>
          {/*@ts-ignore*/}
          {mapStatusText[item.status]}
        </StatusBadge>
      ),
    view: <ViewDetails to={`/requests/${item.id}`} />,
  }));
}

const MM_SCHEMA: DataTableSchemaItem[] = [
  // {dataKey: 'rx_no', header: 'Rx #', sortable: true, index: 0},
  {
    dataKey: 'number',
    header: 'Order #',
    sortable: true,
    // align: 'center',
    index: 0,
  },
  {
    dataKey: 'customer',
    header: 'Customer',
    sortable: true,
    index: 1,
  },
  {dataKey: 'status', header: 'Status', sortable: true, index: 2},
  {dataKey: 'drug_name', header: 'Offering', sortable: false, index: 3},
  {dataKey: 'amount_due', header: 'Amount', sortable: false, index: 4},
  // {
  //   dataKey: 'number',
  //   header: 'Order #',
  //   sortable: true,
  //   align: 'center',
  //   index: 5,
  // },
  // {dataKey: 'refill_no', header: 'Refill #', sortable: false, index: 6},
  {
    dataKey: 'sync_time',
    header: 'Date/Time',
    sortable: true,
    align: 'center',
    index: 7,
  },
  {
    dataKey: 'priority',
    header: 'Priority',
    sortable: true,
    align: 'center',
    index: 7,
  },
  {
    dataKey: 'preference_date',
    header: 'Preference date',
    sortable: false,
    index: 8,
  },
  // {dataKey: 'view', header: '', align: 'right'},
];

const SCHEMA: DataTableSchemaItem[] = [
  {dataKey: 'number', header: 'Request #', sortable: false},
  // {dataKey: 'rx_no', header: 'Rx #', sortable: false},
  {dataKey: 'sync_time', header: 'Sync time', sortable: true},
  {dataKey: 'customer', header: 'Customer', sortable: true},
  {dataKey: 'status', header: 'Status', sortable: true},
  {dataKey: 'drug_name', header: 'Drug name', sortable: false},
  // {dataKey: 'refill_no', header: 'Refill #', sortable: false},
];

type RequestFilter = {
  userId?: string;
  source?: string;
  page?: number;
};

function useRequests(props: RequestFilter) {
  const history = useHistory();
  const {params} = useRouteMatch<{page: string | undefined}>();
  const {sortBy, sortOrder, order, onSort} = useSort('id', 'desc');
  const [source] = useState(props.source || '');
  const [status, setStatus] = useState(
    sessionStorage.getItem('state') === 'all'
      ? ''
      : sessionStorage.getItem(
          source === 'app' ? 'requests_state' : 'orders_state'
        ) || ''
  );
  const [key, setKeyWord] = useState(
    sessionStorage.getItem(`searchKey_${source}`) || ''
  );
  const [keyWord, setApiKeyWord] = useState(
    sessionStorage.getItem(`searchKey_${source}`) || ''
  );
  const [location, setLocation] = useState<FilterOption[]>([]);

  const initialDate = sessionStorage.getItem('date')
    ? new Date(JSON.parse(sessionStorage.getItem('date') || ''))
    : undefined;

  const [date, setDate] = useState(initialDate);

  const initialTime = sessionStorage.getItem('preferenceTime')
    ? JSON.parse(sessionStorage.getItem('preferenceTime') || '')
    : undefined;

  const initialPriority = sessionStorage.getItem('priority')
    ? JSON.parse(sessionStorage.getItem('priority') || '')
    : undefined;

  const [preferenceTime, setPreferenceTime] = useState<
    undefined | {value: string; label: string}
  >(initialTime);

  const [priority, setPriority] = useState<
    undefined | {value: string; label: string}
  >(initialPriority);

  const initialOTC = sessionStorage.getItem('onlyOTC')
    ? JSON.parse(sessionStorage.getItem('onlyOTC') || '')
    : undefined;

  const [onlyOTC, setOnlyOTC] = useState<
    undefined | {value: string; label: string}
  >(initialOTC);

  const initialPaid = sessionStorage.getItem('paid')
    ? JSON.parse(sessionStorage.getItem('paid') || '')
    : undefined;

  const [paid, setPaid] = useState<undefined | {value: string; label: string}>(
    initialPaid
  );
  const [clients, setClients] = useState<Customer[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [MMTable, setMMTable] = useState(
    MM_SCHEMA.map((item) => ({
      ...item,
      value: item.dataKey,
      label: item.header,
    }))
  );
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const [submitting, setSubmitting] = useState(false);
  const [productIds, setProductIds] = useState<any>([]);
  const [userId, setUserId] = useState<any>(0);
  const [addressId, setAddressId] = useState<any>(0);
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState<any>({
    location: [],
    priority: sessionStorage.getItem('priority')
      ? JSON.parse(sessionStorage.getItem('priority') || '')
      : undefined,
    paid: sessionStorage.getItem('paid')
      ? JSON.parse(sessionStorage.getItem('paid') || '')
      : undefined,
    statuses: sessionStorage.getItem(`request_statuses`)
      ? JSON.parse(sessionStorage.getItem(`request_statuses`) as any)
      : [],
    types: sessionStorage.getItem(`request_types`)
      ? JSON.parse(sessionStorage.getItem(`request_types`) as any)
      : undefined,
    onlyOTC: sessionStorage.getItem(`onlyOTC`)
      ? JSON.parse(sessionStorage.getItem(`onlyOTC`) as any)
      : undefined,
    date: sessionStorage.getItem('date')
      ? new Date(JSON.parse(sessionStorage.getItem('date') || ''))
      : undefined,
    preferenceTime: sessionStorage.getItem('preferenceTime')
      ? JSON.parse(sessionStorage.getItem('preferenceTime') || '')
      : undefined,
  });
  const [filterVisible, setFilterVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [addressVisible, setAddressVisible] = useState(false);
  const [productVisible, setProductVisible] = useState(false);

  const getLocalTable = () => {
    let localTable = localStorage.getItem('tableData');
    if (localTable) {
      const data: any[] = MM_SCHEMA.filter((item) =>
        JSON.parse(localTable || '').find(
          (el: any) => el.dataKey === item.dataKey
        )
      );
      setMMTable(
        data.map((item) => ({...item, value: item.dataKey, label: item.header}))
      );
    }
  };

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  useEffect(() => {
    getLocalTable();
    getClients(1, '');
    getProducts(1, '');
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setApiKeyWord(key);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [key]);

  useEffect(() => {
    sessionStorage.setItem('location', JSON.stringify(location));
  }, [location]);

  useEffect(() => {
    if (paid) {
      sessionStorage.setItem('paid', JSON.stringify(paid));
    } else {
      sessionStorage.setItem('paid', '');
    }
    if (priority) {
      sessionStorage.setItem('priority', JSON.stringify(priority));
    } else {
      sessionStorage.setItem('priority', '');
    }
    if (onlyOTC) {
      sessionStorage.setItem('onlyOTC', JSON.stringify(onlyOTC));
    } else {
      sessionStorage.setItem('onlyOTC', '');
    }
    if (preferenceTime) {
      sessionStorage.setItem('preferenceTime', JSON.stringify(preferenceTime));
    } else {
      sessionStorage.setItem('preferenceTime', '');
    }
    if (priority) {
      sessionStorage.setItem('priority', JSON.stringify(priority));
    } else {
      sessionStorage.setItem('priority', '');
    }
    if (date) {
      sessionStorage.setItem('date', JSON.stringify(date));
    } else {
      sessionStorage.setItem('date', '');
    }
  }, [paid, onlyOTC, preferenceTime, date, priority]);

  const {data, refetch, isLoading} = useQuery(
    [
      'requests',
      String(currentPage - 1 || 0),
      order,
      status,
      '',
      keyWord,
      source,
      date,
      location,
      preferenceTime,
      onlyOTC,
      paid,
      priority,
    ],
    getRequests
  );

  const getClients = async (page: number, keyWord: string) => {
    try {
      const {data} = await api.get<ListResponse<Customer>>(`/customers/`, {
        params: {
          limit: 150,
          search: keyWord,
        },
      });
      setClients(data.results);
    } catch (error) {}
  };

  const getProducts = async (page: number, keyWord: string) => {
    try {
      const {data} = await api.get<ListResponse<Product>>(`/products/`, {
        params: {
          limit: 150,
          search: keyWord,
        },
      });
      setProducts(data.results);
    } catch (error) {}
  };

  const getAddress = async (id: number) => {
    setAddresses([]);
    try {
      const {data} = await api.get<Customer>(`/customers/${id}/`);
      setAddresses(data.addresses || []);
    } catch (error) {}
  };

  const onChangeTab = (val: any) => {
    if (val) {
      const newTabs = MMTable.some((item) => item.label === val.label)
        ? reject(MMTable, val)
        : [...MMTable, val];

      setMMTable(sortLodash(newTabs, 'index'));

      localStorage.setItem(
        'tableData',
        JSON.stringify(sortLodash(newTabs, 'index'))
      );
    }
  };

  const onChangeData = (val: any) => {
    setDate(val);
  };

  const onChangeLocation = (value: any) => {
    if (value) {
      setLocation((prev) =>
        prev.some((item) => item.label === value.label)
          ? reject(prev, value)
          : [...prev, value]
      );
    } else {
      setLocation([]);
    }
  };

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      if (source === 'app') {
        history.push(`/requests/${row.sequential_order_number}`);
      } else {
        history.push(`/orders/${row.sequential_order_number}`);
      }
    },
    [history, source]
  );

  const onSubmit = () => {
    setSubmitting(true);
    api
      .post(source === 'app' ? 'requests/create/' : 'requests/create-order/', {
        customer_id: userId,
        address_id: addressId,
        drugs: productIds,
      })
      .then(({data}) => {
        refetch();
        setUserId(0);
        setAddressId(0);
        setProductIds([]);
        setVisible(false);
        handleNavigateRequest(data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    setVisible(false);
    setUserId(0);
    setAddressId(0);
    setProductIds([]);
  };

  const resetFilter = () => {
    setFilterVisible(false);
    const data = {
      location: [],
      priority: undefined,
      onlyOTC: undefined,
      paid: undefined,
      date: undefined,
      preferenceTime: undefined,
    };
    setFilter(data);
    filterHandler(data);
  };

  const filterHandler = (filter: any) => {
    setLocation(filter.location);
    setPriority(filter.priority);
    setPaid(filter.paid);
    setDate(filter.date);
    setOnlyOTC(filter.onlyOTC);
    setPreferenceTime(filter.preferenceTime);
  };

  const applyFilter = () => {
    setFilterVisible(false);
    filterHandler(filter);
    history.push('/orders/page/1');
  };

  const tabs = useMemo(function () {
    return [
      {title: 'All', value: ''},
      {title: 'New', value: 'new'},
      {title: 'In-Progress', value: 'in_progress'},
      {title: 'Closed', value: 'completed'},
      {title: 'Invalid', value: 'invalid'},
      {title: 'Incomplete', value: 'incomplete'},
      {title: 'Call Doctor', value: 'call_doctor'},
    ];
  }, []);

  const MMtabs = useMemo(function () {
    return [
      {title: 'All', value: ''},
      {title: 'New', value: 'new'},
      {title: 'In-Progress', value: 'in_progress'},
      // {title: 'Filled', value: 'filled'},
      // {title: 'OTC CheckOut', value: 'otc_status'},
      // {title: 'Paid', value: 'paid'},
      // {title: 'Waiting for Pickup', value: 'waiting_for_pickup'},
      {title: 'Ready for Dispatch', value: 'ready_for_dispatch'},
      // {title: 'Delivery Partner', value: 'delivery_partner'},
      // {title: 'Picked-Up', value: 'picked_up'},
      {title: 'Completed', value: 'completed'},
      {title: 'Canceled', value: 'canceled'},
      {title: 'Archived', value: 'archived'},
    ];
  }, []);

  const filtered = useMemo(() => {
    return (
      (filter.location.length > 0 ||
        !!filter.date ||
        !!filter.preferenceTime ||
        filter.onlyOTC !== undefined ||
        filter.priority !== undefined ||
        filter.paid !== undefined) &&
      !filterVisible
    );
  }, [filter, filterVisible]);

  return {
    data: data && !isLoading ? transformData(data.results, source) : [],
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    dailyCount: data?.dailyCount,
    loading: isLoading,
    sortBy,
    sortOrder,
    tabs,
    MMtabs,
    status,
    keyWord: key,
    setKeyWord,
    onSort,
    setStatus,
    source,
    setDate,
    date,
    onChangeData,
    onChangeLocation,
    location,
    preferenceTime,
    onlyOTC,
    paid,
    priority,
    MMTable,
    clients,
    products,
    setProducts,
    setClients,
    addresses,
    getAddress,
    setAddresses,
    currentPage,
    setCurrentPage,
    params,
    onChangeTab,
    handleNavigateRequest,
    history,
    submitting,
    onSubmit,
    visible,
    setVisible,
    productIds,
    setProductIds,
    userId,
    setUserId,
    addressId,
    setAddressId,
    handleClose,
    filter,
    setFilter,
    filterVisible,
    setFilterVisible,
    filtered,
    applyFilter,
    resetFilter,
    isModalOpen,
    setModalOpen,
    addressVisible,
    setAddressVisible,
    productVisible,
    setProductVisible,
    MM_SCHEMA,
    SCHEMA,
  };
}

export default useRequests;
