function Afternoon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 12.25C10.3472 12.25 12.25 10.3472 12.25 8C12.25 5.65279 10.3472 3.75 8 3.75C5.65279 3.75 3.75 5.65279 3.75 8C3.75 10.3472 5.65279 12.25 8 12.25Z"
        fill="#666666"
      />
      <path
        d="M8 2.75C8.06566 2.75 8.13068 2.73707 8.19134 2.71194C8.25201 2.68681 8.30713 2.64998 8.35355 2.60355C8.39998 2.55713 8.43681 2.50201 8.46194 2.44134C8.48707 2.38068 8.5 2.31566 8.5 2.25V1.75C8.5 1.61739 8.44732 1.49021 8.35355 1.39645C8.25979 1.30268 8.13261 1.25 8 1.25C7.86739 1.25 7.74021 1.30268 7.64645 1.39645C7.55268 1.49021 7.5 1.61739 7.5 1.75V2.25C7.5 2.31566 7.51293 2.38068 7.53806 2.44134C7.56319 2.50201 7.60002 2.55713 7.64645 2.60355C7.69287 2.64998 7.74799 2.68681 7.80866 2.71194C7.86932 2.73707 7.93434 2.75 8 2.75Z"
        fill="black"
      />
      <path
        d="M3.58067 4.28769C3.62707 4.33423 3.6822 4.37118 3.74289 4.3964C3.80358 4.42162 3.86865 4.43463 3.93438 4.43469C4.00011 4.43474 4.0652 4.42183 4.12593 4.3967C4.18666 4.37158 4.24185 4.33472 4.28832 4.28825C4.3348 4.24177 4.37166 4.18659 4.39679 4.12586C4.42192 4.06513 4.43483 4.00004 4.43478 3.93431C4.43473 3.86859 4.42172 3.80351 4.3965 3.74282C4.37128 3.68213 4.33434 3.627 4.28779 3.58059L3.93424 3.22703C3.88785 3.18047 3.83272 3.14351 3.77202 3.11827C3.71133 3.09304 3.64625 3.08002 3.58051 3.07996C3.51478 3.0799 3.44968 3.0928 3.38894 3.11793C3.32819 3.14306 3.273 3.17992 3.22652 3.2264C3.18004 3.27288 3.14318 3.32807 3.11805 3.38881C3.09292 3.44956 3.08002 3.51466 3.08008 3.58039C3.08014 3.64613 3.09316 3.71121 3.11839 3.7719C3.14363 3.8326 3.18059 3.88772 3.22715 3.93412L3.58067 4.28769Z"
        fill="black"
      />
      <path
        d="M2.25 7.5H1.75C1.61739 7.5 1.49021 7.55268 1.39645 7.64645C1.30268 7.74021 1.25 7.86739 1.25 8C1.25 8.13261 1.30268 8.25979 1.39645 8.35355C1.49021 8.44732 1.61739 8.5 1.75 8.5H2.25C2.38261 8.5 2.50979 8.44732 2.60355 8.35355C2.69732 8.25979 2.75 8.13261 2.75 8C2.75 7.86739 2.69732 7.74021 2.60355 7.64645C2.50979 7.55268 2.38261 7.5 2.25 7.5Z"
        fill="black"
      />
      <path
        d="M3.58078 11.7123L3.22723 12.0659C3.18076 12.1123 3.14388 12.1674 3.11872 12.2281C3.09355 12.2888 3.08059 12.3538 3.08057 12.4195C3.08055 12.4852 3.09347 12.5502 3.1186 12.6109C3.14372 12.6716 3.18056 12.7267 3.22701 12.7732C3.27346 12.8196 3.3286 12.8565 3.38929 12.8816C3.44998 12.9067 3.51503 12.9196 3.58071 12.9196C3.6464 12.9196 3.71144 12.9066 3.77211 12.8815C3.83279 12.8563 3.88791 12.8194 3.93432 12.773L4.28787 12.4194C4.38154 12.3256 4.43413 12.1985 4.43409 12.0659C4.43405 11.9334 4.38138 11.8063 4.28765 11.7125C4.19392 11.6188 4.06681 11.5661 3.93426 11.5661C3.80171 11.5661 3.67456 11.6187 3.58078 11.7123Z"
        fill="black"
      />
      <path
        d="M8 13.25C7.93434 13.25 7.86932 13.2629 7.80866 13.2881C7.74799 13.3132 7.69287 13.35 7.64644 13.3964C7.60001 13.4429 7.56318 13.498 7.53806 13.5587C7.51293 13.6193 7.5 13.6843 7.5 13.75V14.25C7.5 14.3826 7.55268 14.5098 7.64645 14.6036C7.74021 14.6973 7.86739 14.75 8 14.75C8.13261 14.75 8.25979 14.6973 8.35355 14.6036C8.44732 14.5098 8.5 14.3826 8.5 14.25V13.75C8.5 13.6843 8.48707 13.6193 8.46194 13.5587C8.43682 13.498 8.39999 13.4429 8.35356 13.3964C8.30713 13.35 8.25201 13.3132 8.19134 13.2881C8.13068 13.2629 8.06566 13.25 8 13.25Z"
        fill="black"
      />
      <path
        d="M12.4196 11.7123C12.3257 11.6189 12.1987 11.5666 12.0663 11.5667C11.9339 11.5669 11.807 11.6195 11.7133 11.7132C11.6197 11.8068 11.5671 11.9337 11.5669 12.0661C11.5667 12.1985 11.6191 12.3256 11.7125 12.4194L12.066 12.7729C12.1598 12.8666 12.287 12.9192 12.4195 12.9192C12.5521 12.9191 12.6792 12.8665 12.7729 12.7727C12.8666 12.679 12.9193 12.5519 12.9194 12.4193C12.9194 12.2868 12.8668 12.1596 12.7731 12.0659L12.4196 11.7123Z"
        fill="black"
      />
      <path
        d="M14.25 7.5H13.75C13.6174 7.5 13.4902 7.55268 13.3964 7.64645C13.3027 7.74021 13.25 7.86739 13.25 8C13.25 8.13261 13.3027 8.25979 13.3964 8.35355C13.4902 8.44732 13.6174 8.5 13.75 8.5H14.25C14.3826 8.5 14.5098 8.44732 14.6036 8.35355C14.6973 8.25979 14.75 8.13261 14.75 8C14.75 7.86739 14.6973 7.74021 14.6036 7.64645C14.5098 7.55268 14.3826 7.5 14.25 7.5Z"
        fill="black"
      />
      <path
        d="M12.0659 4.43412C12.1316 4.4342 12.1966 4.4213 12.2573 4.39617C12.318 4.37104 12.3731 4.33416 12.4195 4.28766L12.773 3.9341C12.8194 3.88767 12.8563 3.83256 12.8814 3.77189C12.9065 3.71123 12.9195 3.64622 12.9195 3.58056C12.9195 3.5149 12.9065 3.44988 12.8814 3.38922C12.8563 3.32856 12.8194 3.27344 12.773 3.22701C12.7266 3.18058 12.6715 3.14375 12.6108 3.11863C12.5501 3.0935 12.4851 3.08057 12.4195 3.08057C12.3538 3.08057 12.2888 3.0935 12.2281 3.11863C12.1675 3.14375 12.1123 3.18058 12.0659 3.22701L11.7124 3.58057C11.6424 3.6505 11.5948 3.73959 11.5755 3.83658C11.5562 3.93357 11.5661 4.0341 11.604 4.12546C11.6418 4.21683 11.7059 4.29492 11.7881 4.34986C11.8704 4.4048 11.967 4.43412 12.0659 4.43412V4.43412Z"
        fill="black"
      />
    </svg>
  );
}

export default Afternoon;
