import React, {useCallback, useEffect, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styles from './PaymentForm.module.scss';
import TextField from 'components/TextField';
import {
  cc_format,
  dd_format,
  getCardType,
} from 'pages/UserForm/CardInformation/CardInformation';
import Button from 'components/Button';
import Typography from 'components/Typography';
import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import FormActionGroup from 'components/FormActionGroup';
import api from 'api';
import {get} from 'lodash';
import axios from 'axios';
import toast, {Toaster} from 'react-hot-toast';
import successImg from './payment_success_icon.png';
import Spinner from 'components/Spinner';
import logo from './logo.png';
import ReactToPrint from 'react-to-print';
import PrintIcon from 'icons/PrintIcon';
import classNames from 'classnames';

type FormData = {
  card_number: string;
  expiry_date: string;
  secure_code: string;
  cardholder_name: string;
  billing_address: {
    address_line_1: string;
    city: string;
    state: string;
    zipcode: string;
    latitude: number;
    longitude: number;
  };
  amount_due: string;
};

type Props = {
  id: string;
};

const PaymentForm = ({id}: Props) => {
  const [fetching, setFetching] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [datas, setData] = React.useState({});

  const printRef = useRef<any>(null);

  const isPhone = window.innerWidth < 768;

  const {
    control,
    setValue,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>();

  const fetchData = useCallback(() => {
    api
      .get(`/payment/links/${id}/`)
      .then(({data}) => {
        const amount = get(data, 'request.amount_due', '');
        setValue('amount_due', amount);
        setSuccess(get(data, 'request.is_paid', false));
        setData(data);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
      });
  }, [id, setValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCreation = async (data: FormData) => {
    setLoading(true);
    try {
      const card = {
        card: {
          number: data.card_number.replace(/\s+/g, ''),
          cvc: data.secure_code,
          exp_month: data.expiry_date.split('/')[0],
          exp_year: '20' + data.expiry_date.split('/')[1],
        },
        object: 'token',
        token_type: 'supt',
      };
      const {data: response} = await axios.post(
        'https://api.heartlandportico.com/SecureSubmit.v1/api/token?api_key=pkapi_prod_znAgcDJ1S86y0CPLHq',
        card
      );
      const body = {
        id,
        card_number: response.card.number,
        card_type: getCardType(data.card_number),
        cardholder_name: data.cardholder_name,
        token: response.token_value,
        amount_due: get(datas, 'request.amount_due'),
        billing_address: data.billing_address,
      };
      await api.post('/payment/links/', body);
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      const message = get(
        error,
        'response.data.detail',
        'Error creating payment'
      );
      toast.error(message || 'Error creating payment');
      setLoading(false);
    }
  };

  // if (!success) {
  //   return (
  //     <Card className={styles.container}>
  //       <CardContent className={styles.success} pt="sm">
  //         <img className={styles.successImg} src={successImg} alt="Success" />
  //         <Typography className={styles.title} variant="h2">
  //           Payment created successfully
  //         </Typography>
  //       </CardContent>
  //     </Card>
  //   );
  // }

  if (fetching) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  const Payment = () => {
    return (
      <div style={{flex: 1, marginTop: isPhone ? 30 : 15}}>
        <Typography className={styles.title} variant="h2">
          Payment Method
        </Typography>
        <Controller
          name="card_number"
          control={control}
          rules={{
            required: 'Please, fill card number',
            minLength: {
              value: 18,
              message: 'Please, fill card number',
            },
          }}
          render={({field, fieldState}) => (
            <TextField
              label="Card Number"
              onChange={(event) => {
                setValue('card_number', cc_format(event.target.value));
              }}
              wrapperClass={styles.googleInput}
              placeholder="**** **** **** 1234"
              hasError={false}
              value={field.value}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="expiry_date"
          control={control}
          rules={{
            required: 'Please, fill expiration date',
            minLength: {
              value: 5,
              message: 'Please, fill expiration date',
            },
          }}
          render={({field, fieldState}) => (
            <TextField
              label="Expiration date"
              wrapperClass={styles.googleInput}
              placeholder="mm/yy"
              hasError={false}
              onChange={(event) => {
                setValue('expiry_date', dd_format(event.target.value));
              }}
              value={field.value}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="secure_code"
          control={control}
          rules={{
            required: 'Please, fill security code',
            minLength: {
              value: 3,
              message: 'Please, fill security code',
            },
          }}
          render={({field, fieldState}) => (
            <TextField
              maxLength={4}
              label="Security code"
              value={field.value}
              wrapperClass={styles.googleInput}
              placeholder="CVC"
              hasError={false}
              onChange={(event) => {
                setValue('secure_code', event.target.value.replace(/\D/g, ''));
              }}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          name="cardholder_name"
          control={control}
          rules={{required: 'Please, fill holder name'}}
          render={({field, fieldState}) => (
            <TextField
              value={field.value}
              label="Card holder name"
              wrapperClass={styles.googleInput}
              placeholder="Enter card holder name"
              hasError={false}
              onChange={field.onChange}
              error={fieldState.error?.message}
            />
          )}
        />
        {/* <Controller
            name="amount_due"
            control={control}
            rules={{required: 'Please, fill amount'}}
            render={({field, fieldState}) => (
              <TextField
                disabled
                value={field.value}
                label="Amount($)"
                placeholder="Enter amount"
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          /> */}
        <GooglePlaceAutoComplete
          label="Billing address"
          placeholder="Address"
          onSelectPlace={(data) => {
            setValue('billing_address', {
              address_line_1: data.addressLine1,
              city: data.city,
              state: data.state,
              zipcode: data.zipcode,
              latitude: data.lat,
              longitude: data.lon,
            });
          }}
          error={errors?.billing_address?.address_line_1?.message}
          options={{
            types: ['address'],
          }}
        />
        <FormActionGroup className={styles.actionWrapper}>
          <Button
            loading={loading}
            type="submit"
            style={{minWidth: 200}}
            onClick={handleSubmit(handleCreation)}
          >
            Submit
          </Button>
        </FormActionGroup>
        <Toaster />
      </div>
    );
  };

  return (
    <>
      {!isPhone && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 40,
            marginBottom: 40,
            marginTop: 'auto',
          }}
        >
          <img
            className={styles.logo}
            style={{height: 70}}
            src={logo}
            alt="Logo"
          />
        </div>
      )}
      <div
        style={{
          maxWidth: success ? 400 : 900,
        }}
        className={styles.container}
      >
        <div style={{flex: 1}}>
          <Card>
            <CardContent
              pt="sm"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 500,
                position: 'relative',
              }}
            >
              <div ref={printRef}>
                {isPhone && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 40,
                      marginBottom: 20,
                    }}
                  >
                    <img className={styles.logo} src={logo} alt="Logo" />
                  </div>
                )}
                <Typography
                  style={{marginBottom: 20}}
                  className={styles.title}
                  variant="h2"
                >
                  {success ? 'Thank you for the purchase' : 'Payment Details'}
                </Typography>
                {success && (
                  <div style={{textAlign: 'center'}}>
                    <img
                      className={styles.successImg}
                      src={successImg}
                      alt="Success"
                    />
                    <Typography
                      style={{marginBottom: 20}}
                      className={styles.title}
                      variant="h2"
                    >
                      Successfully paid
                    </Typography>
                  </div>
                )}
                <Typography
                  className={classNames(
                    styles.label,
                    isPhone && !success && styles.noSuccess
                  )}
                  variant="label"
                >
                  Patient
                </Typography>
                <Typography
                  className={classNames(
                    styles.text,
                    isPhone && !success && styles.noSuccessText
                  )}
                  variant="p"
                >
                  {get(datas, 'request.customer')}
                </Typography>
                <Typography
                  className={classNames(
                    styles.label,
                    isPhone && !success && styles.noSuccess
                  )}
                  variant="label"
                >
                  Product Name
                </Typography>
                <Typography
                  className={classNames(
                    styles.text,
                    isPhone && !success && styles.noSuccessText
                  )}
                  variant="p"
                >
                  {get(datas, 'request.drug_name')}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isPhone && !success ? 'row' : 'column',
                  }}
                >
                  <div style={isPhone && !success ? {flex: 1} : {}}>
                    <Typography
                      className={classNames(
                        styles.label,
                        isPhone && !success && styles.noSuccess
                      )}
                      variant="label"
                    >
                      Order #
                    </Typography>
                    <Typography
                      className={classNames(
                        styles.text,
                        isPhone && !success && styles.noSuccessText
                      )}
                      variant="p"
                    >
                      {get(datas, 'request.sequential_order_number')}
                    </Typography>
                  </div>

                  <div style={isPhone && !success ? {flex: 1} : {}}>
                    <Typography
                      className={classNames(
                        styles.label,
                        isPhone && !success && styles.noSuccess
                      )}
                      variant="label"
                    >
                      Payment amount
                    </Typography>
                    <Typography
                      className={classNames(
                        styles.text,
                        isPhone && !success && styles.noSuccessText
                      )}
                      variant="p"
                    >
                      ${get(datas, 'request.amount_due')}
                    </Typography>
                  </div>

                  <div
                    style={isPhone && !success ? {flex: 1, marginLeft: 20} : {}}
                  >
                    <Typography
                      className={classNames(
                        styles.label,
                        isPhone && !success && styles.noSuccess
                      )}
                      variant="label"
                    >
                      Status
                    </Typography>

                    <Typography
                      className={classNames(
                        styles.text,
                        isPhone && !success && styles.noSuccessText
                      )}
                      style={{
                        backgroundColor: success ? '#21db6b' : '#ff3b24',
                        fontWeight: '500',
                        padding: '3px',
                        borderRadius: 5,
                        color: '#fff',
                        width: 80,
                        textAlign: 'center',
                      }}
                      variant="p"
                    >
                      {success ? 'Paid' : 'Unpaid'}
                    </Typography>
                  </div>
                </div>
              </div>

              {/* {(!isPhone || success) && (
                <>
                  <Typography
                    style={{textAlign: 'center', marginTop: 'auto'}}
                    variant="p"
                  >
                    You can track your order with our app
                  </Typography>
                  <div className={styles.stores}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={get(datas, 'android_app_link')}
                    >
                      <img
                        className={styles.storeImg}
                        alt="Play Store"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1024px-Google_Play_Store_badge_EN.svg.png"
                      />
                    </a>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={get(datas, 'ios_app_link')}
                    >
                      <img
                        className={styles.storeImg}
                        alt="Play Store"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Download_on_the_App_Store_RGB_blk.svg/1280px-Download_on_the_App_Store_RGB_blk.svg.png"
                      />
                    </a>
                  </div>
                </>
              )} */}
              {success && (
                <div style={{position: 'absolute', bottom: 25, right: 10}}>
                  <ReactToPrint
                    pageStyle={'@page { size: auto; margin: 20mm; }'}
                    trigger={() => (
                      <button className={styles.panel__deliveries_print_action}>
                        <PrintIcon size={16} />
                      </button>
                    )}
                    content={() => printRef.current}
                  />
                </div>
              )}
              {isPhone && !success && <Payment />}
            </CardContent>
          </Card>
        </div>
        {!success && !isPhone && <Payment />}
      </div>
    </>
  );
};

export default PaymentForm;
