import SearchInput from 'components/SearchInput';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import Typography from 'components/Typography';
import ListActionsWrapper from 'components/ListActionsWrapper';
import DataTable from 'components/DataTable';
import {Address} from 'types';
import styles from './Requests.module.scss';
import './styles.scss';
import stat_time from '../DeliveryPlanner/icons/stat_time.svg';
import calendar from '../DeliveryPlanner/icons/calendar.svg';
import useRequests from './useRequests';
import Pagination from 'components/Pagination';
import DateTimePicker from 'components/DateTimePicker';
import Select from 'components/Select';
import {components} from 'react-select';
import {groupBy, isArray, map, reject, sortBy as sortLodash} from 'lodash';
import nyPostalCodeBoroughOptions from 'shared/ny-postal-code-borough-options';
import locationIcon from './location.svg';
import Button from 'components/Button';
import Modal from 'components/Modal';
import {CreateAddress} from 'pages/UserForm/UserAddresses/UserAddresses';
import {addressToString} from 'helpers/address';
import {NewProduct} from 'pages/Products/Products';
import {NewCustomer} from 'pages/Members/Customers/Customers';
import classNames from 'classnames';
import FilterIcon from './FilterIcon';
import Morning from 'pages/DeliveryPlanner/icons/morning';
import Evening from 'pages/DeliveryPlanner/icons/evening';
import Afternoon from 'pages/DeliveryPlanner/icons/afternoon';

const locationOptionsGrouped = groupBy(nyPostalCodeBoroughOptions, 'label');
const sorted: {label: string; value: any}[] = map(
  locationOptionsGrouped,
  (obj, key) => ({
    label: key,
    value: map(obj, 'value'),
  })
);
const locationOptions = sortLodash(sorted, 'label');

type Props = {
  source: string;
};

function Requests({source}: Props) {
  const {
    data,
    totalPages,
    dailyCount,
    loading,
    sortBy,
    sortOrder,
    tabs,
    status,
    onSort,
    setStatus,
    keyWord,
    setKeyWord,
    MMtabs,
    location,
    preferenceTime,
    MMTable,
    clients,
    products,
    setProducts,
    setClients,
    addresses,
    getAddress,
    setAddresses,
    currentPage,
    setCurrentPage,
    params,
    onChangeTab,
    handleNavigateRequest,
    history,
    submitting,
    onSubmit,
    visible,
    setVisible,
    productIds,
    setProductIds,
    userId,
    setUserId,
    addressId,
    setAddressId,
    handleClose,
    filter,
    setFilter,
    filterVisible,
    setFilterVisible,
    filtered,
    applyFilter,
    resetFilter,
    isModalOpen,
    setModalOpen,
    addressVisible,
    setAddressVisible,
    productVisible,
    setProductVisible,
    MM_SCHEMA,
    SCHEMA,
  } = useRequests({source});

  return (
    <>
      <div className={styles.top_header}>
        <SearchInput
          className={styles.input}
          value={keyWord}
          onChange={(val) => {
            setKeyWord(val.target.value);
            setCurrentPage(1);
            if (params.page !== '1') {
              if (source === 'app') {
                history.push('/requests/page/1');
              } else {
                history.push('/orders/page/1');
              }
            }
            sessionStorage.setItem(`searchKey_${source}`, val.target.value);
          }}
          placeholder="Search"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 6,
          }}
        >
          <Button onClick={() => setVisible(true)}>
            {source === 'app' ? 'Add request' : 'Add Order'}
          </Button>
          <Typography variant="h4">
            {source === 'app' ? 'Today’s App Requests' : 'Today’s Orders :'}{' '}
            {dailyCount}
          </Typography>
        </div>
      </div>
      <ListWrapper>
        <ListTabs
          onChange={setStatus}
          isSupportPage={false}
          activeTabValue={status}
          tabs={source === 'micromerchant' ? MMtabs : tabs}
        />
        <ListActionsWrapper>
          <div
            className={styles.filterBtn}
            style={{
              borderColor: filtered ? '#4000FF' : '#E0E0E0',
            }}
            onClick={() => setFilterVisible(true)}
          >
            <FilterIcon isFilled={filtered} />
            <div
              style={{
                color: '#28303F',
              }}
            >
              {filtered ? 'Filtered' : 'Filter'}
            </div>
          </div>
          <div className={styles.preferenceDate}>
            <Select
              className={'Table_Select'}
              isSearchable={false}
              onChange={onChangeTab}
              options={[
                {
                  label: 'Table column',
                  options: MM_SCHEMA.map((item) => ({
                    ...item,
                    value: item.dataKey,
                    label: item.header,
                  })),
                },
              ]}
              value={MMTable}
              placeholder={
                <span className="select-placeholder-text">Display</span>
              }
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !preferenceTime ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                SingleValue: ({children, ...props}) => {
                  return (
                    <components.SingleValue {...props}>
                      Display
                    </components.SingleValue>
                  );
                },
                IndicatorSeparator: () => null,
              }}
              closeMenuOnSelect={false}
            />
          </div>
        </ListActionsWrapper>
        <DataTable
          schema={source === 'app' ? SCHEMA : MMTable}
          data={data}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onClick={handleNavigateRequest}
          onSort={onSort}
          loading={loading}
        />
        {!loading && (
          <Pagination
            basePath={source === 'app' ? '/requests' : '/orders'}
            page={currentPage}
            total={totalPages}
            setCurrentPage={setCurrentPage}
          />
        )}
      </ListWrapper>
      <Modal
        isOpen={filterVisible}
        className={styles.filterModal}
        onClose={() => setFilterVisible(false)}
        onRequestClose={() => setFilterVisible(false)}
      >
        <Typography variant="h2">Filter</Typography>
        <div className={styles.filterContainer}>
          <div className={styles.row}>
            <div style={{width: '100%'}}>
              <Select
                className={'DeliveryPlanner__Filter'}
                placeholder="Location"
                menuPosition="fixed"
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !location ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  Control: ({children, ...props}) => {
                    return (
                      <components.Control {...props}>
                        <img
                          className={styles.locationIcon}
                          src={locationIcon}
                          alt="location_icon"
                        />
                        {children}
                      </components.Control>
                    );
                  },
                  SingleValue: ({children, ...props}) => {
                    return (
                      <components.SingleValue {...props}>
                        {isArray(props.selectProps.value)
                          ? props.selectProps.value
                              .map((item: {label: string}) => item.label)
                              .join(', ')
                          : ''}
                      </components.SingleValue>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                onChange={(data) => {
                  let location: any = [];
                  if (data) {
                    location = filter.location.some(
                      (item: any) => item.label === data.label
                    )
                      ? reject(filter.location, data)
                      : [...filter.location, data];
                  }
                  setFilter((prev: any) => ({
                    ...prev,
                    location,
                  }));
                }}
                isClearable
                options={[
                  {
                    label: 'Locations',
                    options: locationOptions,
                  },
                ]}
                value={filter.location}
              />
            </div>

            <Select
              className={styles.selectCon}
              isSearchable={false}
              onChange={(val: any) => {
                setFilter((prev: any) => ({
                  ...prev,
                  onlyOTC: val,
                }));
              }}
              options={[
                {label: 'Service', value: 'service'},
                {label: 'Product', value: 'product'},
              ]}
              value={filter.onlyOTC}
              placeholder={
                <span className="select-placeholder-text">Service/Product</span>
              }
              isClearable
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !filter.onlyOTC ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className={styles.row}>
            <Select
              className={classNames(styles.selectCon)}
              isSearchable={false}
              menuPosition="fixed"
              onChange={(val: any) => {
                setFilter((prev: any) => ({
                  ...prev,
                  priority: val,
                }));
              }}
              options={[
                {label: 'High', value: 'high'},
                {label: 'Medium', value: 'medium'},
                {label: 'Low', value: 'low'},
              ]}
              value={filter.priority}
              placeholder={
                <span className="select-placeholder-text">Priority</span>
              }
              isClearable
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !filter.priority ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                IndicatorSeparator: () => null,
                Option: (props) => {
                  const colors = {
                    high: '#FF0000',
                    medium: '#FFA500',
                    low: '#1A6AE5',
                  };
                  return (
                    <components.Option
                      {...props}
                      className={styles.select_option}
                    >
                      {props.data.label}
                      <div
                        className={styles.priorityColor}
                        style={{
                          backgroundColor:
                            colors[props.data.value as keyof typeof colors],
                        }}
                      ></div>
                    </components.Option>
                  );
                },
              }}
            />

            <Select
              className={styles.selectCon}
              isSearchable={false}
              menuPosition="fixed"
              onChange={(val: any) => {
                setFilter((prev: any) => ({
                  ...prev,
                  paid: val,
                }));
              }}
              options={[
                {label: 'Paid', value: 'true'},
                {label: 'Unpaid', value: 'false'},
              ]}
              value={filter.paid}
              placeholder={
                <span className="select-placeholder-text">Paid/Unpaid</span>
              }
              isClearable
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !filter.paid ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className={styles.row}>
            <DateTimePicker
              icon={calendar}
              showTimeSelect={false}
              dateFormat="MM/dd/yy E"
              value={filter.date}
              placeholder="Pr. date"
              className={styles.customInput}
              onChange={(data) => {
                setFilter((prev: any) => ({
                  ...prev,
                  date: data,
                }));
              }}
            />

            <Select
              className={styles.selectCon}
              isSearchable={false}
              menuPosition="fixed"
              onChange={(val: any) => {
                setFilter((prev: any) => ({
                  ...prev,
                  preferenceTime: val,
                }));
              }}
              options={[
                {label: 'Morning', value: 'morning'},
                {label: 'Afternoon', value: 'afternoon'},
                {label: 'Evening', value: 'evening'},
              ]}
              value={filter.preferenceTime}
              placeholder={
                <span className="select-placeholder-text">Pr. time</span>
              }
              isClearable
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !filter.preferenceTime ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                Control: ({children, ...props}) => (
                  <components.Control {...props}>
                    <img
                      className={styles.timeIcon}
                      src={stat_time}
                      alt="timer"
                    />
                    {children}
                  </components.Control>
                ),

                IndicatorSeparator: () => null,
                Option: (props) => {
                  const icons = {
                    morning: <Morning />,
                    afternoon: <Afternoon />,
                    evening: <Evening />,
                  };
                  return (
                    <components.Option
                      {...props}
                      className={styles.select_option}
                    >
                      {props.data.label}
                      {icons[props.data.value as keyof typeof icons]}
                    </components.Option>
                  );
                },
              }}
            />
          </div>
        </div>
        <div className={styles.filterBtnContainer}>
          <Button onClick={resetFilter} color="gray">
            Reset
          </Button>
          <Button onClick={applyFilter}>Apply</Button>
        </div>
      </Modal>
      <Modal
        isOpen={visible}
        onClose={handleClose}
        className={styles.list_wrapper}
        onRequestClose={handleClose}
      >
        <Typography variant="h2">
          Add new {source === 'app' ? 'request' : 'order'}
        </Typography>
        <div
          style={{display: 'flex', alignItems: 'center', gap: 10}}
          className={styles.label}
        >
          <div>Customer</div>{' '}
          <PlusComponent onClick={() => setModalOpen(true)} />
        </div>
        <Select
          // label="Customer:"
          placeholder="Click to select"
          onChange={(item) => {
            setUserId(item?.value);
            getAddress(Number(item?.value));
          }}
          value={
            clients.find((item) => item.id === userId)
              ? {
                  value:
                    clients.find((item) => item.id === userId)?.user?.id || '',
                  label:
                    clients.find((item) => item.id === userId)?.user
                      ?.first_name +
                    ' ' +
                    clients.find((item) => item.id === userId)?.user?.last_name,
                }
              : undefined
          }
          menuPosition="fixed"
          options={clients.map((item) => ({
            value: item.id,
            label: item.user.first_name + ' ' + item.user.last_name,
          }))}
        />
        {userId !== 0 && (
          <>
            <div
              style={{display: 'flex', alignItems: 'center', gap: 10}}
              className={styles.label}
            >
              <div>Address</div>
              <PlusComponent onClick={() => setAddressVisible(true)} />
            </div>

            <Select
              placeholder="Click to select"
              menuPosition="fixed"
              onChange={(item) => {
                setAddressId(item?.value);
              }}
              value={
                addresses.find((item) => item.id === addressId)
                  ? {
                      value:
                        addresses.find((item) => item.id === addressId)?.id ||
                        '',
                      label: addressToString(
                        addresses.find(
                          (item) => item.id === addressId
                        ) as Address
                      ),
                    }
                  : undefined
              }
              options={addresses.map((item) => ({
                value: item.id,
                label:
                  item.address_line_1 +
                  ',' +
                  item.city +
                  ',' +
                  item.state +
                  ',' +
                  item.zipcode,
              }))}
            />
          </>
        )}
        <div
          style={{display: 'flex', alignItems: 'center', gap: 10}}
          className={styles.label}
        >
          <div>Product</div>
          <PlusComponent onClick={() => setProductVisible(true)} />
        </div>
        <Select
          placeholder="Click to select"
          menuPosition="fixed"
          onChange={(item) => {
            if (isArray(item)) {
              setProductIds(item.map((item) => item.value));
            } else {
              setProductIds([item?.value]);
            }
          }}
          value={
            productIds.length > 0
              ? products
                  .filter((item) => productIds.includes(item.id))
                  .map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })
              : undefined
          }
          isSearchable={true}
          //@ts-ignore
          isMulti={true}
          options={products.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />

        <Button
          loading={submitting}
          disabled={!addressId || !userId || !productIds.length}
          className={styles.addBtn}
          onClick={onSubmit}
        >
          {source === 'app' ? 'Add request' : 'Add Order'}
        </Button>
        <NewCustomer
          onSuccess={(data) => {
            setClients((pr) => [data, ...pr]);
            setUserId(data.id);
          }}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />

        <CreateAddress
          customer_id={userId}
          handleClose={() => setAddressVisible(false)}
          onSuccess={(data) => {
            setAddresses((pr) => [data, ...pr]);
            setAddressId(data.id);
          }}
          isOpen={addressVisible}
        />
        <NewProduct
          visible={productVisible}
          onClose={() => setProductVisible(false)}
          onSuccess={(data) => {
            setProducts((pr) => [data, ...pr]);
            setProductIds([data.id]);
          }}
        />
      </Modal>
    </>
  );
}

export const PlusComponent = ({onClick}: {onClick: () => void}) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: 22,
        height: 22,
        display: 'flex',
        backgroundColor: '#4000FF',
        color: '#fff',
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      +
    </div>
  );
};

export default Requests;
