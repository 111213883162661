import React, {useState} from 'react';
import Employee from './components/Employee';
import Team from './components/Team';
import Offering from './components/Offering';
import Customer from './components/Customer';
import Order from './components/Order';
import style from './style.module.scss';
import classNames from 'classnames';
import EmployeeIcon from 'components/Sidebar/MenuIcon/IconEmployes';
import TeamIcon from 'components/Sidebar/MenuIcon/IconTeams';
import OfferingIcon from 'components/Sidebar/MenuIcon/IconPackage';
import CustomerIcon from 'components/Sidebar/MenuIcon/IconUsers';
import OrderIcon from 'components/Sidebar/MenuIcon/IconCart';
import Typography from 'components/Typography';

export const onboardingContext = React.createContext({
  data: {
    employee: {},
    team: {},
    offering: {},
    customer: {},
    order: {},
    step: 0,
  },
  setData: (data: any) => {},
});

function Index() {
  const [data, setData] = useState({
    employee: {},
    team: {},
    offering: {},
    customer: {},
    order: {},
    step: 0,
  });

  const steps = [
    {
      title: 'Employee',
      component: <EmployeeIcon color={'#4000FF'} />,
    },
    {
      title: 'Team',
      component: <TeamIcon color={'#4000FF'} />,
    },
    {
      title: 'Offering',
      component: <OfferingIcon color={'#4000FF'} />,
    },
    {
      title: 'Customer',
      component: <CustomerIcon color={'#4000FF'} />,
    },
    {
      title: 'Order',
      component: <OrderIcon color={'#4000FF'} />,
    },
  ];

  return (
    <onboardingContext.Provider value={{data, setData}}>
      <Typography style={{marginLeft: 30, fontWeight: '500'}} variant="h1">
        Onboarding
      </Typography>
      <div className={style.steps}>
        {steps.map((_, index) => {
          return (
            <div className={style.stepperContainer} key={index}>
              {index > 0 && <div className={style.stepperLine} />}
              <div
                className={classNames(style.stepper, {
                  [style.active]: index <= data.step,
                })}
              >
                {index + 1}
              </div>
            </div>
          );
        })}
      </div>
      <div className={classNames(style.steps, style.stepsNames)}>
        {steps.map((step, index) => (
          <div key={index} className={classNames(style.stepName)}>
            {step.component}
            {step.title}
          </div>
        ))}
      </div>
      <div className={style.wrapper}>
        {data.step === 0 && <Employee />}
        {data.step === 1 && <Team />}
        {data.step === 2 && <Offering />}
        {data.step === 3 && <Customer />}
        {data.step === 4 && <Order />}
      </div>
    </onboardingContext.Provider>
  );
}

export default Index;
