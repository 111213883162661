import {useHistory} from 'react-router';

import SearchInput from 'components/SearchInput';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import Typography from 'components/Typography';
import ListActionsWrapper from 'components/ListActionsWrapper';
import DataTable from 'components/DataTable';
import {DataItem} from 'types';
import styles from './Requests.module.scss';
import calendar from '../DeliveryPlanner/icons/calendar.svg';
import {DataTableSchemaItem} from 'types';
import useRequests from './useRequests';
import {useCallback, useEffect, useState} from 'react';
import Pagination from 'components/Pagination';
import {useRouteMatch} from 'react-router-dom';
import DateTimePicker from 'components/DateTimePicker';
import Select from 'components/Select';
import {components} from 'react-select';
import {get} from 'lodash';
import Button from 'components/Button';
import Modal from 'components/Modal';
import {useForm} from 'react-hook-form';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import {validateEmail} from 'utils/validators';
import {cc_format} from 'pages/UserForm/CardInformation/CardInformation';
import api from 'api';
import toast from 'react-hot-toast';
import {formatDateString} from 'pages/Members/Customers/Customers';

const MM_SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'transaction_id',
    header: 'ID',
    sortable: true,
    index: 0,
  },
  {dataKey: 'amount', header: 'Amount', sortable: true, index: 1},
  {
    dataKey: 'transaction_date',
    header: 'Date',
    sortable: false,
    align: 'center',
    index: 2,
  },
  {
    dataKey: 'transaction_origin',
    header: 'Source',
    align: 'center',
    sortable: true,
    index: 3,
  },
  // {dataKey: 'list_rx', header: 'List of RXs', sortable: false, index: 5},
  {dataKey: 'list_orders', header: 'List of Orders', sortable: false, index: 6},
];

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  birthday: string;
  patient_no: string;
  amount: string;
  card: string;
};

function Requests() {
  const {params} = useRouteMatch<{page: string | undefined}>();
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const {
    data,
    totalPages,
    dailyCount,
    loading,
    sortBy,
    sortOrder,
    tabs,
    status,
    onSort,
    setStatus,
    keyWord,
    setKeyWord,
    onChangeData,
    date,
    setCreditType,
    creditType,
    totalAmount,
    startDate,
    endDate,
  } = useRequests({page: currentPage - 1});
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [birthdate, setBirthdate] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: {errors},
  } = useForm<FormValues>();

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      if (get(row, 'service_name', '') === 'CreditSale') {
        history.push(`/transactions/${row.id}`);
      }
    },
    [history]
  );

  function handleDateChange(event: {target: {value: any}}) {
    const inputDate = event.target.value;
    const formattedDate = formatDateString(inputDate);
    setBirthdate(formattedDate);
    setValue('birthday', formattedDate);
  }

  const onSubmit = async (data: FormValues) => {
    setSubmitting(true);
    try {
      const birthday = data.birthday.split('/');
      await api.post('/payment/transactions/', {
        customer: {
          user: {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone.replace(/\D/g, ''),
          },
          birthday: birthday[2] + '-' + birthday[0] + '-' + birthday[1],
          patient_no: data.patient_no,
        },
        amount: data.amount,
        masked_card_number: data.card,
      });
      setVisible(false);
      setSubmitting(false);
    } catch (error) {
      toast.error('Failed to add transaction');
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className={styles.top_header}>
        <Typography textAlign="right" variant="h2">
          Invoices
        </Typography>
        <div className={styles.count_wrapper}>
          <Button onClick={() => setVisible(true)}>Add invoice</Button>
          <Typography textAlign="right" variant="h4">
            Count: {dailyCount} {'  |  '} Total: ${totalAmount || 0}
          </Typography>
        </div>
      </div>
      <ListWrapper>
        <ListTabs
          onChange={setStatus}
          isSupportPage={false}
          activeTabValue={status}
          tabs={tabs}
        />
        <ListActionsWrapper>
          <div className={styles.row}>
            <SearchInput
              className={styles.input}
              value={keyWord}
              onChange={(val) => {
                setKeyWord(val.target.value);
                setCurrentPage(1);
                history.push('/transactions/page/1');
                sessionStorage.setItem(
                  `searchKey_transaction`,
                  val.target.value
                );
              }}
              placeholder="Search"
            />
          </div>
          <div className={styles.preferenceDate}>
            <DateTimePicker
              icon={calendar}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              showTimeSelect={false}
              dateFormat="MM/dd/yy E"
              value={date}
              placeholder="Transaction Date"
              customInput={styles.customInput}
              onChange={(data) => {
                setCurrentPage(1);
                history.push('/transactions/page/1');
                onChangeData(data);
              }}
            />
            <Select
              className={styles.selectCon}
              isSearchable={false}
              onChange={(val: any) => {
                setCreditType(val);
                setCurrentPage(1);
                history.push('/transactions/page/1');
                sessionStorage.setItem('creditType', JSON.stringify(val));
              }}
              options={[
                {label: 'Sale', value: 'CreditSale'},
                {label: 'Account Verify', value: 'CreditAccountVerify'},
              ]}
              value={creditType}
              placeholder={
                <span className="select-placeholder-text">Credit Type</span>
              }
              isClearable
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !creditType ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                Control: ({children, ...props}) => (
                  <components.Control {...props} className="select-row">
                    {children}
                  </components.Control>
                ),

                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </ListActionsWrapper>
        <DataTable
          schema={MM_SCHEMA}
          data={data}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onClick={handleNavigateRequest}
          onSort={onSort}
          loading={loading}
        />
        {!loading && (
          <Pagination
            basePath={'/transactions'}
            page={currentPage}
            total={totalPages}
            setCurrentPage={setCurrentPage}
          />
        )}
      </ListWrapper>
      <Modal
        isOpen={visible}
        onAfterClose={() => reset()}
        onClose={() => setVisible(false)}
        className={styles.list_wrapper}
        onRequestClose={() => setVisible(false)}
      >
        <Typography variant="h2">Add new invoice</Typography>
        <div className={styles.formWrapper}>
          <Typography style={{marginTop: 20}} variant="h4">
            Customer information
          </Typography>
          <FormGroup columns={2}>
            <TextField
              label="First name"
              placeholder="First name"
              {...register('first_name', {required: 'Required'})}
              error={errors.first_name?.message}
            />
            <TextField
              label="Last name"
              placeholder="Last name"
              {...register('last_name', {required: 'Required'})}
              error={errors.last_name?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Email address"
              placeholder="Email address"
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Phone number"
              placeholder="(123) 456-7890"
              {...register('phone', {
                required: 'Required',
                minLength: {
                  value: 14,
                  message: 'Phone number length must be 14',
                },
              })}
              onChange={(e) => {
                let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
                let formatted = '';

                if (input.length > 0) formatted += '(' + input.substring(0, 3);
                if (input.length >= 4)
                  formatted += ') ' + input.substring(3, 6);
                if (input.length >= 7)
                  formatted += '-' + input.substring(6, 10);

                e.target.value = formatted;
              }}
              maxLength={14}
              error={errors.phone?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Date of birth"
              placeholder="mm/dd/yyyy"
              {...register('birthday', {
                required: 'Required',
              })}
              value={birthdate}
              onChange={handleDateChange}
              maxLength={10}
              error={errors.birthday?.message}
            />
          </FormGroup>
          <FormGroup columns={1}>
            <TextField
              label="Patient number"
              placeholder="Patient number"
              {...register('patient_no', {
                required: 'Required',
              })}
              error={errors.patient_no?.message}
            />
          </FormGroup>
        </div>
        <TextField
          placeholder="Amount"
          label="Amount"
          type="number"
          error={errors.amount?.message}
          {...register('amount', {
            required: 'Required',
          })}
        />
        <TextField
          placeholder="Card number"
          label="Masked card number"
          {...register('card', {
            required: 'Required',
          })}
          error={errors.card?.message}
          onChange={(e) => setValue('card', cc_format(e.target.value))}
        />

        <Button
          loading={submitting}
          className={styles.addBtn}
          onClick={handleSubmit(onSubmit)}
        >
          Add Transaction
        </Button>
      </Modal>
    </>
  );
}

export default Requests;
