import Button from 'components/Button';
import CardContent from 'components/CardContent';
import FormActionGroup from 'components/FormActionGroup';
import FormGroup from 'components/FormGroup';
import {
  GooglePlaceAutoComplete,
  TypeGooglePlaceDetail,
} from 'components/GooglePlaceAutoComplete';
import ListWrapper from 'components/ListWrapper';
import MapPreview from 'components/MapPreview';
import SelectField from 'components/SelectField';
import TextField from 'components/TextField';
import addressTypes from 'constants/addressTypes';
import {formatDateString} from 'pages/Members/Customers/Customers';
import {onboardingContext} from 'pages/Onboarding';
import {labels} from 'pages/UserForm/UserAddresses/UserAddresses';
import React, {useContext, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {validateEmail} from 'utils/validators';
import styles from './style.module.scss';
import Typography from 'components/Typography';
import {get} from 'lodash';
import api from 'api';
import toast from 'react-hot-toast';
import {format, parse} from 'date-fns';

type FormValue = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  birthday: string;
  address: TypeGooglePlaceDetail;
  unit_address: string;
  type: string;
  label: string;
  company: string;
};

function Index() {
  const {data, setData} = useContext(onboardingContext);
  const [yy, mm, dd] = get(data, 'customer.birthday', '').split('-');
  const phone = get(data, 'customer.user.phone', '')
    ? '(' +
      get(data, 'customer.user.phone', '').replace(/\D/g, '').substring(0, 3) +
      ') ' +
      get(data, 'customer.user.phone', '').replace(/\D/g, '').substring(3, 6) +
      '-' +
      get(data, 'customer.user.phone', '').replace(/\D/g, '').substring(6, 10)
    : '';
  const {
    handleSubmit,
    register,
    formState: {errors},
    control,
    setValue,
  } = useForm<FormValue>({
    defaultValues: {
      first_name: get(data, 'customer.user.first_name', ''),
      last_name: get(data, 'customer.user.last_name', ''),
      email: get(data, 'customer.user.email', ''),
      phone,
      address: get(data, 'customer.address', {}),
      birthday: get(data, 'customer.birthday', '') ? `${mm}/${dd}/${yy}` : '',
      unit_address: get(data, 'customer.address.unit_address', ''),
      type: get(data, 'customer.address.type', undefined),
      label: get(data, 'customer.address.label', undefined),
    },
  });

  const [location, setLocation] = useState({
    latitude: get(data, 'customer.address.latitude', 40.7596958),
    longitude: get(data, 'customer.address.longitude', -73.9650045),
  });

  function handleDateChange(event: {target: {value: any}}) {
    const inputDate = event.target.value;
    const formattedDate = formatDateString(inputDate);
    setValue('birthday', formattedDate);
  }

  const onSubmit = async (values: FormValue) => {
    if (get(data, 'customer.id', 0)) {
      setData({...data, step: 4});
      return;
    }
    try {
      const user = {
        email: values.email.trim(),
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        phone: values.phone.replace(/\D/g, ''),
        birthday: format(
          parse(values.birthday, 'MM/dd/yyyy', new Date()),
          'yyyy-MM-dd'
        ),
        customer_company: values.company || ' ',
      };
      const {data: customer} = await api.post('/customers/', user);
      await api.post<any>(`/customers/address/`, {
        customer_id: Number(customer.id),
        address_line_1: get(values, 'address.addressLine1', ''),
        city: get(values, 'address.city', ''),
        state: get(values, 'address.state', ''),
        unit_address: values.unit_address,
        type: values.type,
        label: values.label,
        zipcode: get(values, 'address.zipcode', ''),
        latitude: location.latitude,
        longitude: location.longitude,
      });
      setData({
        ...data,
        customer: {
          ...customer,
          address: {
            name: values.address.fullAddress,
            label: values.label,
            type: values.type,
            latitude: location.latitude,
            longitude: location.longitude,
            unit_address: values.unit_address,
          },
        },
        step: 4,
      });
    } catch (error) {
      toast.error(get(error, 'response.data.message', 'Something went wrong'));
    }
  };

  return (
    <>
      <Typography variant="h4">
        <b>Create your first customer (Customers)</b> - These are people that
        place orders, requesting your products or services.
      </Typography>
      <ListWrapper>
        <CardContent px="md" pt="none" pb="md">
          <FormGroup columns={2}>
            <TextField
              label="First name"
              placeholder="First name"
              {...register('first_name', {required: 'Required'})}
              error={errors.first_name?.message}
            />
            <TextField
              label="Last name"
              placeholder="Last name"
              {...register('last_name', {required: 'Required'})}
              error={errors.last_name?.message}
            />
          </FormGroup>

          <FormGroup columns={2}>
            <TextField
              label="Email address"
              placeholder="Email address"
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
            <TextField
              label="Phone number"
              placeholder="(123) 456-7890"
              type="tel"
              {...register('phone', {
                required: 'Required',
                minLength: {
                  value: 14,
                  message: 'Enter a valid phone number',
                },
              })}
              onChange={(e) => {
                let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
                let formatted = '';

                if (input.length > 0) formatted += '(' + input.substring(0, 3);
                if (input.length >= 4)
                  formatted += ') ' + input.substring(3, 6);
                if (input.length >= 7)
                  formatted += '-' + input.substring(6, 10);

                e.target.value = formatted;
                // e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
              maxLength={14}
              error={errors.phone?.message}
            />
          </FormGroup>

          <FormGroup columns={2}>
            <TextField
              label="Date of birth"
              placeholder="mm/dd/yyyy"
              {...register('birthday', {
                required: 'Required',
              })}
              onChange={handleDateChange}
              maxLength={10}
              error={errors.birthday?.message}
            />
            <TextField
              label="Company"
              placeholder="Company"
              {...register('company')}
              error={errors.company?.message}
            />
          </FormGroup>
          <div style={{display: 'flex', gap: 20}}>
            <div style={{flex: 1}}>
              <FormGroup columns={2}>
                <Controller
                  name="address"
                  control={control}
                  rules={{required: 'Please, Select the address'}}
                  render={({field, fieldState}) => (
                    <GooglePlaceAutoComplete
                      label="Customer address"
                      placeholder="Address"
                      onSelectPlace={(address) => {
                        setLocation({
                          latitude: address.lat,
                          longitude: address.lon,
                        });
                        setValue('address', address);
                      }}
                      defaultValue={get(data, 'customer.address.name', '')}
                      error={fieldState.error?.message}
                      options={{
                        types: ['address'],
                      }}
                    />
                  )}
                />
                <Controller
                  name="unit_address"
                  control={control}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      label="Unit number"
                      placeholder="Enter unit number"
                      hasError={false}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </FormGroup>

              <FormGroup columns={2}>
                <Controller
                  name="type"
                  control={control}
                  rules={{required: 'Please select type of address'}}
                  render={({field, fieldState}) => (
                    <SelectField
                      {...field}
                      label="Address Type"
                      placeholder="Select a type"
                      options={addressTypes}
                      error={fieldState.error?.message}
                      value={addressTypes.find(
                        (item) => item.value === field.value
                      )}
                      onChange={(newValue) =>
                        setValue(
                          'type',
                          (
                            newValue as unknown as {
                              value: 'shipping' | 'billing' | 'both';
                            }
                          ).value
                        )
                      }
                    />
                  )}
                />
                <Controller
                  name="label"
                  control={control}
                  rules={{required: 'Please select label of address'}}
                  render={({field, fieldState}) => (
                    <SelectField
                      {...field}
                      label="Address Label"
                      placeholder="Select a label"
                      options={labels}
                      error={fieldState.error?.message}
                      value={labels.find((item) => item.value === field.value)}
                      onChange={(newValue) => {
                        if (newValue) {
                          setValue('label', newValue.value as 'home');
                        }
                      }}
                    />
                  )}
                />
              </FormGroup>
            </div>
            <div
              style={{
                paddingTop: 20,
                width: '30%',
              }}
            >
              <div className={styles.label}>Preview</div>
              {!!location.latitude && (
                <MapPreview
                  style={{
                    width: '100%',
                    height: 200,
                  }}
                  zoom={15}
                  onDrag={(location) => {
                    setLocation({
                      latitude: location.lat,
                      longitude: location.lon,
                    });
                  }}
                  center={[location.latitude, location.longitude]}
                />
              )}
            </div>
          </div>
        </CardContent>
      </ListWrapper>
      <FormActionGroup
        style={{justifyContent: 'center', padding: 20, marginTop: 50}}
      >
        <Button
          onClick={() => setData({...data, step: data.step - 1})}
          color="blue"
        >
          Prev
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Next</Button>
      </FormActionGroup>
    </>
  );
}

export default Index;
