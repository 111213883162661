import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Address} from 'types/order';
import {createAddress} from 'types/order';
import AddressForm from './AddressForm';
import styles from './UserAddresses.module.scss';
import {capitalize, get} from 'lodash';
import Typography from 'components/Typography';
import ArrowIcon from './ArrowIcon';
import Button from 'components/Button';
import Modal from 'components/Modal';
import SelectField from 'components/SelectField';
import addressTypes from 'constants/addressTypes';
import {Controller, useForm} from 'react-hook-form';
import FormGroup from 'components/FormGroup';
import WrongSwitch from 'react-switch';
import useUserForm from '../useUserForm';
import toast from 'react-hot-toast';
import {
  TypeGooglePlaceDetail,
  GooglePlaceAutoComplete,
} from 'components/GooglePlaceAutoComplete';
import TextField from 'components/TextField/TextField';
import ListWrapper from 'components/ListWrapper';
import {useLocation} from 'react-router-dom';
import MapPreview from 'components/MapPreview';

const Switch = WrongSwitch as any;
type AddressEntity = Address & {id: number};
type FormData = createAddress;

type UserAddressesProps = {
  addresses: AddressEntity[];
  onSave: () => void;
  isShow: boolean;
  deleting: boolean;
  userId: number;
  addressModalVisible: boolean;
  onChange: (valud: AddressEntity[]) => void;
  setAddressModalVisible: (val: boolean) => void;
  refetch: () => void;
};

export const labels = [
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Work',
    value: 'work',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const UserAddresses: React.FC<UserAddressesProps> = ({
  addresses,
  onSave,
  deleting,
  isShow,
  addressModalVisible,
  setAddressModalVisible,
  onChange,
  refetch,
  userId,
}) => {
  const [expandedId, setExpandedId] = useState<number[]>(
    addresses.map((item) => item.id)
  );
  const [openModal, setOpenModal] = useState(
    get(useLocation().state, 'isAddAddress', false)
  );
  const [changedAddresses, setChangedAddresses] = useState<AddressEntity[]>([]);

  const handleChange = useCallback(
    (address: AddressEntity) => {
      const savedEntity = changedAddresses.find(
        (item) => item.id === address.id
      );
      if (savedEntity) {
        setChangedAddresses((prev) =>
          prev.map((item) =>
            item.id === savedEntity.id ? {...savedEntity, ...address} : item
          )
        );
      } else {
        setChangedAddresses((prev) => [...prev, address]);
      }
    },
    [changedAddresses]
  );
  const handleOrdersClose = () => {
    setOpenModal(false);
    setAddressModalVisible(false);
  };

  useEffect(() => {
    onChange(changedAddresses);
  }, [changedAddresses, onChange]);

  const deleteRef = useRef(0);

  return (
    <>
      {isShow ? (
        <div>
          <ListWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: 20,
              justifyContent: 'space-between',
            }}
          >
            <div className={styles.accordion}>
              <div className={styles.accordion__inner}>
                <Typography variant="h3" className={styles.accordion__title}>
                  Addresses
                </Typography>
                {addresses.map((address) => (
                  <div className={styles.accordion__item} key={address.id}>
                    <div
                      className={styles.accordion__item_header}
                      onClick={() =>
                        expandedId.includes(address.id)
                          ? setExpandedId((pr) =>
                              pr.filter((item) => item !== address.id)
                            )
                          : setExpandedId((pr) => [...pr, address.id])
                      }
                    >
                      <ArrowIcon
                        className={
                          expandedId.includes(address.id)
                            ? styles.accordion__item_header_icon_rotated
                            : ''
                        }
                      />{' '}
                      {address.type === 'home'
                        ? 'Home or work'
                        : capitalize(address.type)}
                    </div>
                    {expandedId.includes(address.id) && (
                      <div className={styles.accordion__item_content}>
                        <AddressForm
                          defaultValues={address}
                          deleting={
                            deleting && address.id === deleteRef.current
                          }
                          onSave={() => {
                            deleteRef.current = address.id;
                            onSave();
                          }}
                          onChange={(add: any) => {
                            handleChange({...add, id: address.id});
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className={styles.actionWrapper}>
                <Button
                  style={{
                    backgroundColor: '#fff',
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  Add New Address
                </Button>
              </div>
            </div>
          </ListWrapper>
        </div>
      ) : (
        <></>
      )}
      <CreateAddress
        handleClose={handleOrdersClose}
        onSuccess={() => refetch()}
        customer_id={userId}
        isOpen={openModal || addressModalVisible}
      />
    </>
  );
};

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  customer_id: number | undefined;
  onSuccess: (data: Address) => void;
};

export const CreateAddress = ({
  isOpen,
  handleClose,
  onSuccess,
  customer_id = undefined,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const {control, handleSubmit, reset, setValue} = useForm<FormData>();

  const [location, setLocation] = useState({
    latitude: 40.7596958,
    longitude: -73.9650045,
  });

  const onSelectPlace = (placeDetail: TypeGooglePlaceDetail) => {
    setValue('zipcode', placeDetail.zipcode);
    setValue('address_line_1', placeDetail.addressLine1);
    setValue('city', placeDetail.city);
    setValue('state', placeDetail.state);
    setValue('latitude', placeDetail.lat);
    setValue('longitude', placeDetail.lon);
    setLocation({latitude: placeDetail.lat, longitude: placeDetail.lon});
  };

  const {createNewAddress} = useUserForm();

  const handleCreation = async (data: createAddress) => {
    setLoading(true);
    try {
      const res = await createNewAddress({...data, customer_id});
      handleClose();
      onSuccess(res.data);
      toast.success('Address created successfully');

      reset();
      setLoading(false);
    } catch (error) {
      toast.error('Something wrong with creation. Try again later !');
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      style={{
        overlay: {
          zIndex: 1000,
        },
        content: {
          overflowY: 'scroll',
        },
      }}
      className={styles.list_wrapper}
    >
      <Typography variant="h2">Add new address</Typography>
      <div className={styles.modalWrapper}>
        <FormGroup>
          <Controller
            name="city"
            control={control}
            rules={{required: 'Please, Select the address'}}
            render={({field, fieldState}) => (
              <GooglePlaceAutoComplete
                label="Your address"
                placeholder="Address"
                onSelectPlace={onSelectPlace}
                error={fieldState.error?.message}
                options={{
                  types: ['address'],
                }}
              />
            )}
          />
          <Controller
            name="unit_address"
            control={control}
            // rules={{required: 'Please, fill unit number'}}
            render={({field, fieldState}) => (
              <TextField
                label="Unit number"
                wrapperClass={styles.googleInput}
                placeholder="Enter unit number"
                hasError={false}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </FormGroup>

        <FormGroup>
          <Controller
            name="type"
            control={control}
            rules={{required: 'Please select type of address'}}
            render={({field, fieldState}) => (
              <SelectField
                {...field}
                label="Address Type"
                placeholder="Select a type"
                options={addressTypes}
                error={fieldState.error?.message}
                value={addressTypes.find((item) => item.value === field.value)}
                onChange={(newValue) =>
                  setValue(
                    'type',
                    (
                      newValue as unknown as {
                        value: 'shipping' | 'billing' | 'both';
                      }
                    ).value
                  )
                }
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="label"
            control={control}
            rules={{required: 'Please select label of address'}}
            render={({field, fieldState}) => (
              <SelectField
                {...field}
                label="Address Label"
                placeholder="Select a label"
                options={labels}
                error={fieldState.error?.message}
                value={labels.find((item) => item.value === field.value)}
                onChange={(newValue) => {
                  if (newValue) {
                    setValue('label', newValue.value as 'home');
                  }
                }}
              />
            )}
          />
        </FormGroup>
        <div
          style={{
            paddingTop: 20,
          }}
        >
          {!!location.latitude && (
            <MapPreview
              zoom={15}
              onDrag={(location) => {
                setValue('latitude', location.lat);
                setValue('longitude', location.lon);
              }}
              center={[location.latitude, location.longitude]}
            />
          )}
        </div>
        <FormGroup className={styles.switchForm} columns={2}>
          <div>
            <p className={styles.label}>Primary address</p>
          </div>
          <div className={styles.switchContainer}>
            <Controller
              control={control}
              name="is_primary"
              render={({field}) => (
                <Switch
                  checked={field.value || false}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
        </FormGroup>
        <div className={styles.actionWrapper}>
          <Button
            className={styles.cancelButton}
            onClick={handleClose}
            component="a"
            color="gray"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            type="submit"
            onClick={handleSubmit(handleCreation)}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UserAddresses;
