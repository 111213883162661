import Button from 'components/Button';
import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListWrapper from 'components/ListWrapper';
import SearchInput from 'components/SearchInput';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';
import Filter from 'components/Filter';

import useEmployees from './useEmployees';

import styles from './Employees.module.scss';
import {DataTableSchemaItem} from 'types';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'name',
    header: 'Employee name',
    sortable: true,
  },
  {
    dataKey: 'phone',
    header: 'Phone number',
    sortable: true,
  },
  {
    dataKey: 'role',
    header: 'Employee title',
    sortable: true,
  },
  {
    dataKey: 'active',
    header: 'Active',
    align: 'right',
  },
  {dataKey: 'view', header: '', colWidth: '150px'},
];

function Employees() {
  const {
    data,
    isLoading,
    filterOptions,
    filterValue,
    sortBy,
    sortOrder,
    onChangeFilter,
    onSort,
    keyWord,
    setKeyWord,
  } = useEmployees();

  return (
    <>
      <PageHeader>
        <Typography variant="h2">Staff</Typography>
        <Button to="/people/employees/new" component="a">
          New Team member
        </Button>
      </PageHeader>

      <ListWrapper>
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput
            value={keyWord}
            onChange={(val) => {
              setKeyWord(val.target.value);
              sessionStorage.setItem(`search_employe`, val.target.value);
            }}
            size="sm"
          />
          <Filter
            size="sm"
            options={filterOptions}
            value={filterValue}
            onChange={onChangeFilter}
          />
        </ListActionsWrapper>
        <DataTable
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
          selectable={false}
          data={data}
          schema={SCHEMA}
          loading={isLoading}
        />
      </ListWrapper>
    </>
  );
}

export default Employees;
