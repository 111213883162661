import React from 'react';
import cn from 'classnames';

import logo from '../../assets/icon.png';

import styles from './Logo.module.scss';

function Logo({className, ...restProps}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn(styles.root, className)} {...restProps}>
      <img
        style={{
          width: 180,
          height: 180,
          filter: 'invert(65%) sepia(190%) saturate(500%) hue-rotate(250deg)',
        }}
        alt="logo"
        src={logo}
      />
    </div>
  );
}

export default Logo;
