import {Controller} from 'react-hook-form';

import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import FormActionGroup from 'components/FormActionGroup';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import SelectField from 'components/Select';
import ListWrapper from 'components/ListWrapper';
import CardContent from 'components/CardContent';

import {validateEmail} from 'utils/validators';

import useEmployeeForm from './useEmployeeForm';

import styles from './EmployeeForm.module.scss';
import roles from 'constants/roles';
import {SetStateAction, useCallback, useState} from 'react';
import useDeleteEmployee from './useDeleteEmployee';
import {useHistory, useLocation} from 'react-router-dom';
import EyeIcon from './icons/eye.svg';
import EyeDisabledIcon from './icons/eye-disable.svg';
import Modal from 'components/Modal';
import VehicleForm from 'pages/VehicleForm';
// import {PlusComponent} from 'pages/Requests/Requests';
import ReactSwitch from 'react-switch';
import DeleteModal from 'components/DeleteModal';
import api from 'api';
import toast from 'react-hot-toast';
import {get} from 'lodash';
import infoIcon from './icons/info.svg';
import Loader from 'components/DataTable/components/Loader';

type Props = {
  isModal?: boolean;
  setUser?: (data: any) => void;
  setModalVisible?: (data: SetStateAction<boolean>) => void;
};

function EmployeeForm({
  isModal,
  setUser,
  setModalVisible: setHideTeamModal,
}: Props) {
  const history = useHistory();
  const {state} = useLocation();

  const teamId = get(state, 'state.team_id');
  const {
    role,
    employeeId,
    submitting,
    errors,
    control,
    register,
    handleSubmit,
    setTeam,
    watch,
    setValue,
    employee,
    loading,
  } = useEmployeeForm(!!isModal, setUser, teamId);

  const [deleteModal, setDeleteModal] = useState(false);
  const {deleteEmployee, isDeleting} = useDeleteEmployee();
  const [modalVisible, setModalVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleDeleteEmployee = useCallback(
    async (id: number) => {
      await deleteEmployee(id);
      history.replace('/people/employees/');
    },
    [deleteEmployee, history]
  );

  const handleSendAppLink = async () => {
    const id = employee?.driver?.id;
    try {
      await api.post(`/drivers/${id}/send-sms/`);
      toast.success('App link sent successfully');
    } catch (error) {
      toast.error(get(error, 'message') || 'Failed to send app link');
    }
  };

  return (
    <div style={{position: 'relative'}}>
      {loading && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            right: 0,
            left: 0,
            height: '100%',
            padding: 0,
            margin: 0,
            zIndex: 1,
          }}
        >
          <Loader />
        </div>
      )}
      <PageHeader>
        <Typography variant="h2">
          {employeeId ? 'Edit Team Member' : 'New Team Member'}
        </Typography>
        {!isModal && (
          <FormActionGroup>
            {!!employeeId && role === 'driver' && (
              <Button onClick={handleSendAppLink} color="primary">
                Send App Link
              </Button>
            )}
            {!!employeeId && role !== 'management' && (
              <Button
                onClick={() => setDeleteModal(true)}
                color="danger"
                loading={isDeleting}
              >
                Delete
              </Button>
            )}

            <Button onClick={handleSubmit} loading={submitting}>
              Save
            </Button>
          </FormActionGroup>
        )}
      </PageHeader>
      <ListWrapper>
        <CardContent px="md" pt="none" pb="md">
          <FormGroup columns={2}>
            <TextField
              label="First name"
              placeholder="First name"
              {...register('first_name', {required: 'Required'})}
              error={errors.first_name?.message}
            />
            <TextField
              label="Last name"
              placeholder="Last name"
              {...register('last_name', {required: 'Required'})}
              error={errors.last_name?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Email address"
              placeholder="Email address"
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Phone number"
              placeholder="(123) 456-7890"
              {...register('phone')}
              type="tel"
              minLength={14}
              onChange={(e) => {
                let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
                let formatted = '';

                if (input.length > 0) formatted += '(' + input.substring(0, 3);
                if (input.length >= 4)
                  formatted += ') ' + input.substring(3, 6);
                if (input.length >= 7)
                  formatted += '-' + input.substring(6, 10);

                e.target.value = formatted;
              }}
              error={errors.phone?.message}
            />
          </FormGroup>

          {role !== 'management' && (
            <div
              style={{
                display: 'flex',
                gap: 3,
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <div className={styles.tooltipContainer}>
                <img className={styles.infoIcon} src={infoIcon} alt="info" />
                <div className={styles.tooltip}>
                  Employees that are active can login, receive jobs and will be
                  billed as paid users. Employees that are NOT active can NOT
                  login or receive jobs and will not be billed as paid users.{' '}
                </div>
              </div>
              <p style={{marginRight: 7}}>Active:</p>
              <label>
                <ReactSwitch
                  onColor="#4000FF"
                  onChange={(val) => setValue('is_active', val)}
                  handleDiameter={22}
                  height={26}
                  width={45}
                  checked={watch('is_active')}
                />
              </label>
            </div>
          )}

          {/* <FormGroup columns={2}>
            <TextField
              label="Address line 1"
              placeholder="Street"
              {...register('address_line_1')}
              error={errors.address_line_1?.message}
            />
            <TextField
              label="Address line 2"
              placeholder="Apartment"
              {...register('address_line_2')}
              error={errors.address_line_2?.message}
            />
          </FormGroup>

          <FormGroup columns={3}>
            <TextField
              label="City"
              placeholder="City name"
              {...register('city')}
              error={errors.city?.message}
            />
            <TextField
              label="Zipcode"
              placeholder="Zipcode"
              {...register('zipcode')}
              error={errors.zipcode?.message}
            />
            <Controller
              name="state"
              control={control}
              render={({field, fieldState}) => (
                <SelectField
                  label="State"
                  placeholder="Select a state"
                  options={states}
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </FormGroup> */}

          <Typography className={styles.header} variant="h3">
            Account
          </Typography>

          <FormGroup columns={3}>
            <TextField
              label="Username"
              placeholder="Username"
              {...register(
                'username',
                !employeeId
                  ? {
                      required: 'Required',
                    }
                  : {}
              )}
              error={errors.username?.message}
            />
            <div style={{position: 'relative'}}>
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                {...register(
                  'password',
                  !employeeId
                    ? {
                        required: 'Required',
                      }
                    : {}
                )}
                error={errors.password?.message}
              />
              <img
                onClick={() => setShowPassword((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
            <div style={{position: 'relative'}}>
              <TextField
                label="Password confirmation"
                type={showPassword1 ? 'text' : 'password'}
                placeholder="Password confirmation"
                {...register(
                  'password_confirmation',
                  !employeeId
                    ? {
                        required: 'Required',
                        validate: (val: string) => {
                          if (watch('password') !== val) {
                            return 'Your passwords do no match';
                          }
                        },
                      }
                    : {}
                )}
                error={errors.password_confirmation?.message}
              />
              <img
                onClick={() => setShowPassword1((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword1 ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
          </FormGroup>
          {teamId === undefined && (
            <FormGroup columns={1}>
              <Controller
                name="role"
                control={control}
                defaultValue={
                  teamId !== undefined
                    ? {
                        value: 'driver',
                        label: 'Driver (driver app)',
                      }
                    : undefined
                }
                rules={{required: 'Required'}}
                render={({field}) => (
                  <div>
                    <div
                      style={{
                        marginBottom: 6,
                        cursor: 'pointer',
                        marginTop: 22,
                      }}
                    >
                      Employee Type
                    </div>
                    <SelectField
                      isDisabled={teamId !== undefined}
                      placeholder="Employee Type"
                      options={roles}
                      {...field}
                    />
                  </div>
                )}
              />
            </FormGroup>
          )}
          {/* {(role === 'Driver (driver app)' ||
            role === 'Technician (driver app)') &&
            !isModal && (
              <Fragment>
                <Typography className={styles.header} variant="h3">
                  {role}
                </Typography>
                <FormGroup columns={1}>
                  <Controller
                    name="team"
                    control={control}
                    render={({field}) => (
                      <div>
                        <div
                          style={{
                            marginBottom: 6,
                            cursor: 'pointer',
                            marginTop: 22,
                          }}
                        >
                          {!isModal ? (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                              }}
                            >
                              <div>Assigned team</div>
                              <PlusComponent
                                onClick={() => {
                                  if (!isModal) setModalVisible(true);
                                }}
                              />
                            </div>
                          ) : (
                            'Assigned team'
                          )}
                        </div>
                        <SelectField
                          placeholder="No assigned team"
                          options={options}
                          {...field}
                        />
                      </div>
                    )}
                  />
                </FormGroup>
              </Fragment>
            )} */}
        </CardContent>
        {!!isModal && (
          <FormActionGroup style={{justifyContent: 'flex-end', padding: 20}}>
            {!!employeeId && (
              <Button
                onClick={() => handleDeleteEmployee(+employeeId)}
                color="danger"
                loading={isDeleting}
              >
                Delete
              </Button>
            )}
            <Button
              onClick={() => {
                if (setHideTeamModal) setHideTeamModal(false);
              }}
              component="button"
              color="gray"
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit} loading={submitting}>
              Save
            </Button>
          </FormActionGroup>
        )}
      </ListWrapper>
      <Modal
        isOpen={modalVisible}
        style={{
          content: {
            maxHeight: '90vh',
            overflow: 'scroll',
            minWidth: 800,
          },
        }}
        onClose={() => setModalVisible(false)}
        onRequestClose={() => setModalVisible(false)}
      >
        <VehicleForm
          setModalVisible={setModalVisible}
          setUser={(data) => {
            setTeam(data);
            setModalVisible(false);
          }}
          isModal={true}
        />
      </Modal>
      <DeleteModal
        openModal={deleteModal}
        setOpenModal={setDeleteModal}
        onDelete={() => handleDeleteEmployee(+employeeId)}
        text="Are you sure, you want to delete this employee ?"
      />
    </div>
  );
}

export default EmployeeForm;
