import {useCallback, useEffect, useMemo, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';
import {format, parseISO} from 'date-fns';
import {capitalize} from 'lodash';

import ViewDetails from 'components/ViewDetails';
import StatusBadge from 'components/StatusBadge';

import {DataItem, ListResponse, Route} from 'types';
import api from 'api';
import {DATETIME_FORMAT} from 'constants/datetime';
import useSort from 'hooks/useSort';
import {mapStatusColor} from 'constants/mappings';
import {useHistory, useRouteMatch} from 'react-router-dom';

export const ALL_STATUSES = [
  'new',
  'processing',
  'processed',
  'awaiting',
  'active',
  'completed',
].join(',');

export const ACTIVE_STATUSES = [
  'new',
  'processing',
  'processed',
  'awaiting',
  'active',
].join(',');

export const COMPLETED_STATUSES = 'completed';

async function getRoutes({queryKey}: QueryFunctionContext<string[]>) {
  const [, page, order, status, keyWord] = queryKey;
  const {data} = await api.get<ListResponse<Route>>('/routes/', {
    params: {
      order: order.includes('number') ? '' : order,
      status: status ? status : undefined,
      search: keyWord ? keyWord : undefined,
      offset: +page * 20,
      limit: 40,
    },
  });

  return data;
}

function useRoutes() {
  const history = useHistory();
  const {params} = useRouteMatch<{page: string | undefined}>();
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_history`) || ''
  );
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem(`history_tab`) || ''
  );
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));

  const {sortBy, sortOrder, order, onSort} = useSort('created_at');
  const [status, setStatus] = useState('');
  const {data: routes, isLoading} = useQuery(
    ['routes', String(currentPage - 1), order, activeTab, keyWord],
    getRoutes
  );

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  const data = useMemo(
    function () {
      return routes
        ? routes.results.map((r) => ({
            ...r,
            number: `#${r.sequential_order_number}`,
            status: (
              <StatusBadge color={mapStatusColor[r.status]}>
                {capitalize(
                  r.status === 'awaiting'
                    ? 'delivering'
                    : r.status === 'processed'
                    ? 'calculated'
                    : r.status
                )}
              </StatusBadge>
            ),
            created_at: r.confirmed_at
              ? format(parseISO(r.confirmed_at), DATETIME_FORMAT)
              : '',
            view: <ViewDetails disabled={true} to={`/history/routes/:id`} />,
          }))
        : [];
    },
    [routes]
  );

  const changeTab = useCallback(
    (tab: string) => {
      setActiveTab(tab);
      setCurrentPage(1);
      history.push(`/history/routes/page/1`);
      sessionStorage.setItem(`history_tab`, tab);
    },
    [history]
  );

  const handleViewDetails = useCallback(
    (item: DataItem) => {
      history.push(`/history/routes/${item.id}`);
    },
    [history]
  );

  return {
    data,
    isLoading,
    activeTab,
    status,
    sortBy,
    sortOrder,
    setActiveTab: changeTab,
    onSort,
    setStatus,
    keyWord,
    setKeyWord,
    currentPage,
    setCurrentPage,
    handleViewDetails,
    totalPages:
      routes && routes?.limit ? Math.ceil(routes.count / routes.limit) : 0,
  };
}

export default useRoutes;
