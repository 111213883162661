import DataTable from 'components/DataTable';
import useHistory from './useHistory';
import {DataTableSchemaItem} from 'types';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'created',
    header: 'Created at',
  },
  {
    dataKey: 'amount',
    header: 'Amount',
  },
  {
    dataKey: 'status',
    header: 'Status',
  },
  {
    dataKey: 'invoice_pdf',
    header: '',
  },
];

function Index() {
  const {data, loading} = useHistory();

  return (
    <div style={styles.container}>
      <h1>Billing History</h1>
      <DataTable
        selectable={false}
        data={data}
        schema={SCHEMA}
        loading={loading}
      />
    </div>
  );
}

const styles = {
  container: {
    width: '90%',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    // backgroundColor: '#f9f9f9',
  },
};

export default Index;
