function IconPackage({color}: {color?: string}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0967 11.25C12.5109 11.2543 12.8432 11.5936 12.8389 12.0077L12.7394 21.6462C12.7351 22.0604 12.3959 22.3927 11.9817 22.3884C11.5675 22.3842 11.2352 22.0449 11.2395 21.6307L11.339 11.9923C11.3433 11.5781 11.6825 11.2458 12.0967 11.25Z"
        fill={color || 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2514 1.80548C11.4765 1.67821 11.7308 1.61133 11.9894 1.61133C12.2481 1.61133 12.5024 1.67821 12.7275 1.80548L20.6025 6.25657C20.8338 6.38728 21.0262 6.57705 21.16 6.80648C21.2939 7.03592 21.3644 7.29678 21.3644 7.56241V16.4372C21.3644 16.7029 21.2939 16.9637 21.16 17.1932C21.0262 17.4226 20.8338 17.6124 20.6025 17.7431L12.7275 22.1942C12.5024 22.3214 12.2481 22.3883 11.9894 22.3883C11.7308 22.3883 11.4765 22.3214 11.2514 22.1942L3.37635 17.7431C3.1451 17.6124 2.95271 17.4226 2.81885 17.1932C2.68498 16.9637 2.61444 16.7028 2.61444 16.4372V7.56241C2.61444 7.29678 2.68498 7.03592 2.81885 6.80648C2.95271 6.57705 3.1451 6.38728 3.37635 6.25657L3.7454 6.90949L3.37635 6.25657L11.2514 1.80548ZM11.9894 3.11133L4.11444 7.56242L4.11444 16.4372L11.9894 20.8883L19.8644 16.4372V7.56242L11.9894 3.11133L12.3585 2.45841L11.9894 3.11133L11.6204 2.45841L11.9894 3.11133Z"
        fill={color || 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.81309 6.81691C3.01514 6.45532 3.47206 6.32598 3.83366 6.52803L12.0847 11.1385L20.1395 6.53271C20.499 6.3271 20.9572 6.45191 21.1628 6.81149C21.3684 7.17107 21.2436 7.62925 20.884 7.83486L12.4613 12.6511C12.2329 12.7817 11.9528 12.783 11.7231 12.6547L3.10198 7.83747C2.74038 7.63543 2.61105 7.17851 2.81309 6.81691Z"
        fill={color || 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24306 4.1968C7.4451 3.8352 7.90202 3.70586 8.26362 3.90791L16.9616 8.7681C17.1988 8.90064 17.3458 9.15111 17.3458 9.42282V13.9228C17.3458 14.337 17.01 14.6728 16.5958 14.6728C16.1816 14.6728 15.8458 14.337 15.8458 13.9228V9.86289L7.53194 5.21736C7.17035 5.01531 7.04101 4.55839 7.24306 4.1968Z"
        fill={color || 'black'}
      />
    </svg>
  );
}

export default IconPackage;
