import CardContent from 'components/CardContent';
import ListWrapper from 'components/ListWrapper';
import ProductImage from 'components/ProductImage';
import React, {useContext, useState} from 'react';
import styles from './style.module.scss';
import {onboardingContext} from 'pages/Onboarding';
import {Controller, get, useForm} from 'react-hook-form';
import {SelectOption} from 'types';
import Input from 'components/Input';
import Select from 'components/Select';
import FormActionGroup from 'components/FormActionGroup';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import api from 'api';
import toast from 'react-hot-toast';
import Typography from 'components/Typography';

type FormValue = {
  name: string;
  offering_type: SelectOption;
  description: string;
  price: string;
};

function Index() {
  const {data, setData} = useContext(onboardingContext);
  const [image, setImage] = useState<any>(null);
  const [description, setDescription] = useState<string>(
    get(data, 'offering.description', '')
  );

  const {control, handleSubmit} = useForm<FormValue>({
    defaultValues: {
      name: get(data, 'offering.name', ''),
      price: get(data, 'offering.price', ''),
      offering_type: get(data, 'offering.offering_type')
        ? {
            value: get(data, 'offering.offering_type', ''),
            label:
              get(data, 'offering.offering_type') === 'service'
                ? 'Service'
                : 'Product',
          }
        : undefined,
      description: get(data, 'offering.description', ''),
    },
  });

  const onSubmit = async (values: FormValue) => {
    if (get(data, 'offering.id', 0)) {
      setData({...data, step: 3});
      return;
    }
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('price', values.price);
    formData.append('offering_type', values.offering_type?.value as string);
    formData.append('description', description);
    formData.append('image.file', image);

    try {
      const {data: offering} = await api.post('products/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setData({...data, offering, step: 3});
    } catch (error) {
      toast.error(get(error, 'response.data.message', 'Something went wrong'));
    }
  };

  return (
    <>
      <Typography variant="h4">
        <b>Create your first product or service (Offerings)</b> - These are
        items that your company offers to customers and can be added to customer
        orders
      </Typography>
      <ListWrapper>
        <CardContent className={styles.cardContent} px="md" pt="none" pb="md">
          <div style={{width: '70%'}}>
            <FormGroup columns={2}>
              <div>
                <div className={styles.label}>Name:</div>
                <Controller
                  name="name"
                  control={control}
                  rules={{required: true}}
                  render={({field, fieldState}) => (
                    <Input
                      {...field}
                      placeholder="Name"
                      hasError={!!fieldState.error}
                      className={styles.root}
                    />
                  )}
                />
              </div>
              <div>
                <div className={styles.label}>Price:</div>
                <Controller
                  name="price"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Required',
                    },
                  }}
                  render={({field, fieldState}) => (
                    <Input
                      {...field}
                      hasError={!!fieldState.error?.message}
                      onChange={(value) => {
                        let val = value.target.value;
                        let dec = '';
                        if (val.split('.').length > 1) {
                          dec = '.' + val.split('.')[1].replace(/\D/g, '');
                          val = val.split('.')[0];
                        }
                        field.onChange(
                          val
                            .replace(/\D/g, '')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + `${dec}`
                        );
                      }}
                      placeholder="Price"
                      className={styles.root}
                    />
                  )}
                />
              </div>
            </FormGroup>

            <div className={styles.label}>Offering Type</div>
            <Controller
              name="offering_type"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({field, fieldState}) => (
                <Select
                  {...field}
                  error={fieldState.error?.message}
                  options={[
                    {
                      value: 'service',
                      label: 'Service',
                    },
                    {
                      value: 'product',
                      label: 'Product',
                    },
                  ]}
                />
              )}
            />

            <div className={styles.label}>Description:</div>
            <textarea
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              placeholder="Description"
              className={styles.textarea}
            />
          </div>
          <div style={{width: '30%'}}>
            <div className={styles.label}>Image:</div>
            <ProductImage
              className={styles.productImage}
              showDelete={true}
              product={data.offering as any}
              size="medium"
              onChange={(value) => {
                setImage(value);
              }}
            />
          </div>
        </CardContent>
      </ListWrapper>
      <FormActionGroup
        style={{justifyContent: 'center', padding: 20, marginTop: 50}}
      >
        <Button
          color="blue"
          onClick={() => setData({...data, step: data.step - 1})}
        >
          Prev
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Next</Button>
      </FormActionGroup>
    </>
  );
}

export default Index;
