import api from 'api';
import {QueryFunctionContext, useQuery} from 'react-query';

type HistoryItem = {
  amount: string;
  created: string;
  invoice_pdf: string;
  id: string;
  status: string;
};

async function getHistory({queryKey}: QueryFunctionContext<any[]>) {
  const [,] = queryKey;

  const {data} = await api.get<HistoryItem[]>(
    '/payment/stripe-invoice-history/',
    {
      params: {},
    }
  );

  return data;
}

function useHistory() {
  const {data, isLoading} = useQuery(['billing-history'], getHistory);

  const downloadInvoice = async (url: string) => {
    window.open(url, '_blank');
  };

  const transformData = (data: HistoryItem[]) => {
    return data.map((item) => ({
      ...item,
      invoice_pdf: (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <p
            onClick={() => downloadInvoice(item.invoice_pdf)}
            style={{
              margin: 0,
              color: 'blue',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            Download
          </p>
        </div>
      ),
    }));
  };

  return {
    data: data ? transformData(data) : [],
    loading: isLoading,
  };
}

export default useHistory;
