function Clear() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 2.0625C9.23233 2.0625 7.50436 2.58668 6.0346 3.56874C4.56483 4.55081 3.41929 5.94665 2.74283 7.57977C2.06637 9.21288 1.88938 11.0099 2.23424 12.7436C2.57909 14.4773 3.43031 16.0698 4.68024 17.3198C5.93017 18.5697 7.52268 19.4209 9.25638 19.7658C10.9901 20.1106 12.7871 19.9336 14.4202 19.2572C16.0534 18.5807 17.4492 17.4352 18.4313 15.9654C19.4133 14.4956 19.9375 12.7677 19.9375 11C19.9348 8.63047 18.9923 6.35877 17.3167 4.68326C15.6412 3.00774 13.3695 2.06524 11 2.0625ZM14.2361 13.2639C14.3 13.3277 14.3508 13.4035 14.3854 13.4869C14.42 13.5704 14.4379 13.6598 14.4379 13.7502C14.438 13.8405 14.4202 13.9299 14.3857 14.0134C14.3511 14.0969 14.3005 14.1727 14.2366 14.2366C14.1727 14.3005 14.0969 14.3511 14.0134 14.3857C13.93 14.4202 13.8405 14.438 13.7502 14.4379C13.6598 14.4379 13.5704 14.42 13.4869 14.3854C13.4035 14.3508 13.3277 14.3 13.2639 14.2361L11 11.9722L8.73609 14.2361C8.60712 14.3648 8.43235 14.437 8.25016 14.4369C8.06797 14.4368 7.89327 14.3644 7.76444 14.2356C7.63561 14.1067 7.56319 13.932 7.56309 13.7498C7.563 13.5677 7.63523 13.3929 7.76392 13.2639L10.0278 11L7.76392 8.73608C7.63523 8.60712 7.563 8.43234 7.56309 8.25015C7.56319 8.06796 7.63561 7.89326 7.76444 7.76443C7.89327 7.63561 8.06797 7.56319 8.25016 7.56309C8.43235 7.56299 8.60712 7.63523 8.73609 7.76392L11 10.0278L13.2639 7.76392C13.3929 7.63523 13.5677 7.56299 13.7499 7.56309C13.932 7.56319 14.1067 7.63561 14.2356 7.76443C14.3644 7.89326 14.4368 8.06796 14.4369 8.25015C14.437 8.43234 14.3648 8.60712 14.2361 8.73608L11.9722 11L14.2361 13.2639Z"
        fill="#FF8855"
      />
    </svg>
  );
}

export default Clear;
