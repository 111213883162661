import Button from 'components/Button';
import CardContent from 'components/CardContent';
import FormActionGroup from 'components/FormActionGroup';
import ListWrapper from 'components/ListWrapper';
import Select from 'components/Select';
import {onboardingContext} from 'pages/Onboarding';
import React, {useContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import {QueryFunctionContext, useQuery} from 'react-query';
import styles from './style.module.scss';
import {get, isArray} from 'lodash';
import {Address, Customer, ListResponse, Product} from 'types';
import {addressToString} from 'helpers/address';
import api from 'api';
import toast from 'react-hot-toast';
import Typography from 'components/Typography';
import useAuth from 'hooks/useAuth';
import {useHistory} from 'react-router-dom';

type FormValue = {
  customer_id: number;
  address_id: number;
  products: number[];
};

async function getUsers({queryKey}: QueryFunctionContext<any[]>) {
  const [,] = queryKey;
  const {data} = await api.get<ListResponse<Customer>>(`/customers/`, {
    params: {
      limit: 20,
    },
  });

  return data;
}
async function getProducts({queryKey}: QueryFunctionContext<any[]>) {
  const [,] = queryKey;
  const {data} = await api.get<ListResponse<Product>>(`/products/`, {
    params: {
      limit: 20,
    },
  });

  return data;
}
async function getAddresses({queryKey}: QueryFunctionContext<any[]>) {
  const [, id] = queryKey;
  if (!id) return [];
  const {data} = await api.get<Customer>(`/customers/${id}`, {
    params: {
      limit: 20,
    },
  });

  return data.addresses;
}

function Index() {
  const history = useHistory();
  const {setUser, currentUser} = useAuth();
  const {data, setData} = useContext(onboardingContext);
  const [userId, setUserId] = useState<number>(
    get(data, 'order.customer.id', 0)
  );
  const [addressId, setAddressId] = useState<number>(
    get(data, 'order.delivery_address.id', 0)
  );
  const [productIds, setProductIds] = useState<number[]>(
    get(data, 'order.prescriptions', []).map((item: any) => item.id)
  );
  const {data: users} = useQuery(['users'], getUsers);
  const {data: products} = useQuery(['products'], getProducts);
  const {data: addresses} = useQuery(['addresses', userId], getAddresses);

  const {
    handleSubmit,
    register,
    setValue,
    formState: {errors},
  } = useForm<FormValue>();

  const onSubmit = async (values: FormValue) => {
    try {
      const {data} = await api.post('/requests/create-order/', {
        customer_id: userId,
        address_id: addressId,
        drugs: productIds,
      });

      await api.get(
        `/requests/${data.sequential_order_number}/?is_sequential_order_number=true`
      );

      toast.success('Congratulations, welcome to the dashboard!');
      await api.post('/users/onboard/', {
        is_onboarded: true,
      });
      if (currentUser) setUser({...currentUser, is_onboarded: true});
      history.replace('/orders');
    } catch (error) {
      toast.error(get(error, 'response.data.message', 'Something went wrong'));
    }
  };

  return (
    <>
      <Typography variant="h4">
        <b>Create your first order (Order)</b> - Let's create a sample order
        which includes a customer and the product or service they are
        requesting.
      </Typography>
      <ListWrapper>
        <CardContent px="md" pt="none" pb="md">
          <div className={styles.label}>Customer</div>
          <Select
            {...register('customer_id', {
              required: 'Customer is required',
            })}
            error={errors.customer_id?.message}
            placeholder="Click to select"
            onChange={(item) => {
              setValue('customer_id', Number(item?.value));
              setUserId(Number(item?.value));
            }}
            value={
              users?.results.find((item) => item.id === userId)
                ? {
                    value:
                      users?.results.find((item) => item.id === userId)?.user
                        ?.id || '',
                    label:
                      users?.results.find((item) => item.id === userId)?.user
                        ?.first_name +
                      ' ' +
                      users?.results.find((item) => item.id === userId)?.user
                        ?.last_name,
                  }
                : undefined
            }
            menuPosition="fixed"
            options={users?.results.map((item) => ({
              value: item.id,
              label: item.user.first_name + ' ' + item.user.last_name,
            }))}
          />
          {userId !== 0 && (
            <>
              <div
                style={{display: 'flex', alignItems: 'center', gap: 10}}
                className={styles.label}
              >
                <div>Address</div>
              </div>

              <Select
                {...register('address_id', {
                  required: 'Address is required',
                })}
                error={errors.address_id?.message}
                placeholder="Click to select"
                menuPosition="fixed"
                onChange={(item) => {
                  setValue('address_id', Number(item?.value));
                  setAddressId(Number(item?.value));
                }}
                value={
                  addresses?.find((item) => item.id === addressId)
                    ? {
                        value:
                          addresses?.find((item) => item.id === addressId)
                            ?.id || '',
                        label: addressToString(
                          addresses?.find(
                            (item) => item.id === addressId
                          ) as Address
                        ),
                      }
                    : undefined
                }
                options={addresses?.map((item) => ({
                  value: item.id,
                  label:
                    item.address_line_1 +
                    ',' +
                    item.city +
                    ',' +
                    item.state +
                    ',' +
                    item.zipcode,
                }))}
              />
            </>
          )}
          <div className={styles.label}>Offering</div>
          <Select
            {...register('products', {
              required: 'Offering is required',
            })}
            error={errors.products?.message}
            placeholder="Click to select"
            menuPosition="fixed"
            onChange={(item) => {
              if (isArray(item)) {
                setValue(
                  'products',
                  item.map((item) => item.value)
                );
                setProductIds(item.map((item) => item.value));
              } else {
                setValue('products', [Number(item?.value)]);
                setProductIds([Number(item?.value)]);
              }
            }}
            value={
              productIds.length > 0
                ? products?.results
                    .filter((item) => productIds.includes(item.id))
                    .map((item) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    })
                : undefined
            }
            isSearchable={true}
            //@ts-ignore
            isMulti={true}
            options={products?.results.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </CardContent>
      </ListWrapper>
      <FormActionGroup
        style={{justifyContent: 'center', padding: 20, marginTop: 50}}
      >
        <Button
          color="blue"
          onClick={() => setData({...data, step: data.step - 1})}
        >
          Prev
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Next</Button>
      </FormActionGroup>
    </>
  );
}

export default Index;
