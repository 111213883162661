import {useCallback, useEffect, useMemo, useState} from 'react';
import authContext from 'contexts/authContext';

import api from 'api';

import {User} from 'types';
import {AuthService} from 'services/auth.service';
import toast from 'react-hot-toast';
import {get} from 'lodash';

interface Props {
  children: React.ReactNode;
}

interface RegisterResponse {
  message: string;
  email: string;
  token: string;
}

// interface AuthResponse {
//   token: string;
// }

function AuthProvider({children}: Props) {
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [token, setToken] = useState<string>();
  const [loading, setLoading] = useState(true);

  const componentDidMount = useCallback(async function (first: boolean) {
    try {
      const authService = new AuthService();
      setLoading(first);
      const {data} = await authService.getProfile();

      setCurrentUser({...data});
    } catch (error) {
      localStorage.removeItem('token');
      setToken(undefined);
      setCurrentUser(undefined);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(
    function () {
      componentDidMount(true);
    },
    [token, componentDidMount]
  );

  const setup = useCallback(async function (token: string) {
    localStorage.setItem('token', token);
    setToken(token);
  }, []);

  const register = useCallback(
    async function (data: any) {
      try {
        const {
          data: {token},
        } = await api.post<RegisterResponse>('/users/register/', {
          email: data.email,
          // phone: data.phone.replace(/\D/g, ''),
          // first_name: data.first_name,
          // last_name: data.last_name,
          password: data.password,
          company: {
            name: data.company_name,
            // address: data.company_address,
            // email: data.company_email,
            // phone: data.company_phone.replace(/\D/g, ''),
            // website: data.website,
          },
          role: 'tech',
        });

        setup(token);
      } catch (error) {
        const err = get(error, 'response.data', '');

        let error_message = 'Something went wrong. Please try again later.';
        if (typeof err === 'object') {
          error_message = JSON.stringify(err);
        }
        toast.error(error_message);
        throw Error('Registration error');
      }
    },
    [setup]
  );

  const login = useCallback(
    async function (email: string, password: string) {
      const authService = new AuthService();
      const {
        data: {token},
      } = await authService.login(email, password);
      await setup(token);
    },
    [setup]
  );

  const logout = useCallback(async function () {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    setToken(undefined);
    setCurrentUser(undefined);
  }, []);

  const setUser = useCallback((user: User) => {
    setCurrentUser(user);
  }, []);

  const auth = useMemo(
    function () {
      return {
        currentUser,
        token,
        loading,
        register,
        login,
        logout,
        setUser,
        refetch: componentDidMount,
      };
    },
    [
      currentUser,
      token,
      loading,
      register,
      login,
      logout,
      setUser,
      componentDidMount,
    ]
  );

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default AuthProvider;
