import {useCallback, useEffect, useMemo, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';
import {get} from 'lodash';

import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListWrapper from 'components/ListWrapper';
import PageHeader from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import Typography from 'components/Typography';
import ViewDetails from 'components/ViewDetails';
import ClickablePagination from 'components/ClickablePagination';
import Modal from 'components/Modal';
import calendar from '../../DeliveryPlanner/icons/calendar.svg';
import api from 'api';
import {getFullName} from 'helpers/user';
import {
  ListResponse,
  Customer,
  DataItem,
  SelectOption,
  DataTableSchemaItem,
} from 'types';

import styles from './Customers.module.scss';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import Button from 'components/Button';
import FormGroup from '../../../components/FormGroup';
import TextField from '../../../components/TextField';
import {validateEmail} from '../../../utils/validators';
import FormActionGroup from '../../../components/FormActionGroup';
import {useForm} from 'react-hook-form';
import {format, parse} from 'date-fns';
import toast from 'react-hot-toast';
import useSort from 'hooks/useSort';
import useDebounce from 'hooks/useDebounce';
import DateTimePicker from 'components/DateTimePicker';
import FilterIcon from 'pages/Requests/FilterIcon';
import ListTabs from 'pages/UserForm/ListTabs';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'name',
    header: 'Name',
    sortable: true,
  },
  {
    dataKey: 'customer_company',
    header: 'Company',
  },
  {
    dataKey: 'phone',
    header: 'Phone',
  },
  {
    dataKey: 'email',
    header: 'Email',
    colWidth: '250px',
  },
  {
    dataKey: 'last_offering_date',
    header: 'Latest Order',
    sortable: true,
    colWidth: '130px',
  },
  {
    dataKey: 'date',
    header: 'Date Joined',
    sortable: true,
    colWidth: '130px',
  },
  {
    dataKey: 'total_offerings',
    header: 'Total Orders',
    align: 'center',
    sortable: true,
  },
  {
    dataKey: 'view',
    header: '',
  },
];

const TABS = [
  {title: 'All', value: 'all'},
  {title: 'To Invite', value: 'to_invite'},
  {title: 'Registered', value: 'registered'},
  {title: 'Active', value: 'active'},
];

export async function getUsers({queryKey}: QueryFunctionContext<any[]>) {
  const [, keyWord, order, page, metaDatas, status] = queryKey;
  const {data} = await api.get<ListResponse<Customer>>(`/customers/`, {
    params: {
      limit: 20,
      order,
      status,
      offset: keyWord ? 0 : +page * 20,
      search: keyWord ? keyWord : undefined,
      last_offering_date_after: metaDatas.last_offering_date.startDate
        ? format(metaDatas.last_offering_date.startDate, 'yyyy-MM-dd')
        : undefined,
      last_offering_date_before: metaDatas.last_offering_date.endDate
        ? format(metaDatas.last_offering_date.endDate, 'yyyy-MM-dd')
        : undefined,
      total_offerings_range_min: metaDatas.total_offerings_range.from
        ? metaDatas.total_offerings_range.from
        : undefined,
      total_offerings_range_max: metaDatas.total_offerings_range.to
        ? metaDatas.total_offerings_range.to
        : undefined,
    },
  });

  return data;
}

function Users() {
  const [status, setStatus] = useState(
    sessionStorage.getItem(`users_tab`) || 'all'
  );
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_users`) || ''
  );
  const [isModalOpen, setModalOpen] = useState(
    get(useLocation().state, 'isAdd', false)
  );
  const {params} = useRouteMatch<{page: string | undefined}>();
  const {sortBy, sortOrder, order, onSort} = useSort('name');

  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const [filterVisible, setFilterVisible] = useState(false);
  const [filter, setFilter] = useState({
    last_offering_date: {
      startDate: sessionStorage.getItem('last_offering_date_start')
        ? new Date(
            JSON.parse(sessionStorage.getItem('last_offering_date_start') || '')
          )
        : null,
      endDate: sessionStorage.getItem('last_offering_date_end')
        ? new Date(
            JSON.parse(sessionStorage.getItem('last_offering_date_end') || '')
          )
        : null,
    },
    total_offerings_range: {
      from: sessionStorage.getItem('total_offerings_range_from')
        ? JSON.parse(sessionStorage.getItem('total_offerings_range_from') || '')
        : null,
      to: sessionStorage.getItem('total_offerings_range_to')
        ? JSON.parse(sessionStorage.getItem('total_offerings_range_to') || '')
        : null,
    },
  });
  const [metaDatas, setMetaDatas] = useState<any>({
    last_offering_date: {
      startDate: sessionStorage.getItem('last_offering_date_start')
        ? new Date(
            JSON.parse(sessionStorage.getItem('last_offering_date_start') || '')
          )
        : null,
      endDate: sessionStorage.getItem('last_offering_date_end')
        ? new Date(
            JSON.parse(sessionStorage.getItem('last_offering_date_end') || '')
          )
        : null,
    },
    total_offerings_range: {
      from: sessionStorage.getItem('total_offerings_range_from')
        ? JSON.parse(sessionStorage.getItem('total_offerings_range_from') || '')
        : null,
      to: sessionStorage.getItem('total_offerings_range_to')
        ? JSON.parse(sessionStorage.getItem('total_offerings_range_to') || '')
        : null,
    },
  });

  const searchDebounced = useDebounce(keyWord, 400);

  const {
    data: users,
    isLoading,
    refetch,
  } = useQuery(
    [
      'users',
      searchDebounced,
      order,
      String(currentPage - 1 || 0),
      metaDatas,
      status,
    ],
    getUsers
  );

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params]);

  const history = useHistory();

  const handleNavigateUser = useCallback(
    (row: DataItem) => {
      history.push(`/people/customers/${row.id}/edit`);
    },
    [history]
  );

  const data = useMemo(
    function () {
      return users
        ? users.results.map((u) => ({
            ...u,
            name: getFullName(u.user),
            email: u.user.email,
            phone: u.user.phone,
            date: get(u, 'created_at', ''),
            view: <ViewDetails to={`/people/customers/${u.id}/edit`} />,
          }))
        : [];
    },
    [users]
  );
  const handleOrdersClose = () => {
    setModalOpen(false);
  };

  const resetFilter = () => {
    setFilter({
      last_offering_date: {
        startDate: null,
        endDate: null,
      },
      total_offerings_range: {
        from: null,
        to: null,
      },
    });
    setMetaDatas({
      last_offering_date: {
        startDate: null,
        endDate: null,
      },
      total_offerings_range: {
        from: null,
        to: null,
      },
    });
    sessionStorage.removeItem('last_offering_date_start');
    sessionStorage.removeItem('last_offering_date_end');
    sessionStorage.removeItem('total_offerings_range_from');
    sessionStorage.removeItem('total_offerings_range_to');
    setFilterVisible(false);
  };

  const applyFilter = () => {
    if (filter.last_offering_date.startDate) {
      sessionStorage.setItem(
        'last_offering_date_start',
        JSON.stringify(filter.last_offering_date.startDate || '')
      );
    } else {
      sessionStorage.removeItem('last_offering_date_start');
    }
    if (filter.last_offering_date.endDate) {
      sessionStorage.setItem(
        'last_offering_date_end',
        JSON.stringify(filter.last_offering_date.endDate || '')
      );
    } else {
      sessionStorage.removeItem('last_offering_date_end');
    }
    if (filter.total_offerings_range.from) {
      sessionStorage.setItem(
        'total_offerings_range_from',
        JSON.stringify(filter.total_offerings_range.from || '')
      );
    } else {
      sessionStorage.removeItem('total_offerings_range_from');
    }
    if (filter.total_offerings_range.to) {
      sessionStorage.setItem(
        'total_offerings_range_to',
        JSON.stringify(filter.total_offerings_range.to || '')
      );
    } else {
      sessionStorage.removeItem('total_offerings_range_to');
    }
    setMetaDatas(filter);
    setFilterVisible(false);
  };

  const filtered = useMemo(() => {
    return Object.values(filter).some((obj) =>
      Object.values(obj).some((value) => value !== null)
    );
  }, [filter]);

  return (
    <>
      <PageHeader className={styles.top_header}>
        <Typography variant="h2">Customers</Typography>
        <div className={styles.count_wrapper}>
          <Typography textAlign="right" variant="h4">
            {'Total Customer Count:'}
          </Typography>
          <Typography
            textAlign="right"
            className={styles.countText}
            variant="h4"
          >
            {users?.count}
          </Typography>
        </div>
      </PageHeader>
      <ListWrapper>
        <ListTabs
          tab="users_tab"
          tabs={TABS}
          activeTabValue={status}
          onChange={setStatus}
        />
        <ListActionsWrapper className={styles.list_header}>
          <div className={styles.row}>
            <SearchInput
              value={keyWord}
              size="sm"
              onChange={(e) => {
                setKeyWord(e.target.value);
                sessionStorage.setItem(`search_users`, e.target.value);
              }}
            />
            <div
              className={styles.filterBtn}
              style={{
                borderColor: filtered ? '#4000FF' : '#E0E0E0',
              }}
              onClick={() => setFilterVisible(true)}
            >
              <FilterIcon isFilled={filtered} />
              <div
                style={{
                  color: '#28303F',
                }}
              >
                {filtered ? 'Filtered' : 'Filter'}
              </div>
            </div>
          </div>
          <Button
            className={styles.button}
            size="sm"
            onClick={() => setModalOpen(true)}
          >
            Add New Customer
          </Button>
        </ListActionsWrapper>
        <DataTable
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
          data={data}
          schema={SCHEMA}
          loading={isLoading}
          onClick={handleNavigateUser}
        />
        <ClickablePagination
          page={currentPage}
          total={Math.round((users?.count || 0) / 20)}
          setCurrentPage={setCurrentPage}
        />
      </ListWrapper>
      <NewCustomer
        onSuccess={() => {
          refetch();
        }}
        isOpen={isModalOpen}
        onClose={handleOrdersClose}
      />
      <Modal
        isOpen={filterVisible}
        className={styles.filterModal}
        onClose={() => setFilterVisible(false)}
        onRequestClose={() => setFilterVisible(false)}
      >
        <Typography variant="h2">Filter</Typography>
        <div className={styles.filterContainer}>
          <Typography variant="h4">Last Offering Date</Typography>
          <div className={styles.filterItem}>
            <DateTimePicker
              icon={calendar}
              showTimeSelect={false}
              dateFormat="MM/dd/yy E"
              value={filter.last_offering_date.startDate}
              placeholder="Last offering date from"
              className={styles.customInput}
              onChange={(data) => {
                setFilter((prev: any) => ({
                  ...prev,
                  last_offering_date: {
                    ...prev.last_offering_date,
                    startDate: data,
                  },
                }));
              }}
            />

            <DateTimePicker
              icon={calendar}
              showTimeSelect={false}
              dateFormat="MM/dd/yy E"
              value={filter.last_offering_date.endDate}
              placeholder="Last offering date to"
              className={styles.customInput}
              onChange={(data) => {
                setFilter((prev: any) => ({
                  ...prev,
                  last_offering_date: {
                    ...prev.last_offering_date,
                    endDate: data,
                  },
                }));
              }}
            />
          </div>
          <Typography variant="h4">Total Offerings</Typography>
          <div className={styles.filterItem}>
            <TextField
              wrapperClass={styles.filterInput}
              placeholder="Total offerings from"
              value={filter.total_offerings_range.from || ''}
              onChange={(e) => {
                setFilter((prev: any) => ({
                  ...prev,
                  total_offerings_range: {
                    ...prev.total_offerings_range,
                    from: e.target.value,
                  },
                }));
              }}
            />
            <TextField
              wrapperClass={styles.filterInput}
              placeholder="Total offerings to"
              value={filter.total_offerings_range.to || ''}
              onChange={(e) => {
                setFilter((prev: any) => ({
                  ...prev,
                  total_offerings_range: {
                    ...prev.total_offerings_range,
                    to: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>
        <div className={styles.filterBtnContainer}>
          <Button onClick={resetFilter} color="gray">
            Reset
          </Button>
          <Button onClick={applyFilter}>Apply</Button>
        </div>
      </Modal>
    </>
  );
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (data: Customer) => void;
};

interface FormValue {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  birthday: string;
  company: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zipcode: string;
  state: SelectOption;
}

export function formatDateString(inputDate: string) {
  const numbersOnly = inputDate.replace(/[^0-9]/g, '');
  const month = numbersOnly.slice(0, 2);
  const day = numbersOnly.slice(2, 4);
  const year = numbersOnly.slice(4, 8);

  let formattedDate = '';
  if (month) {
    if (parseInt(month) > 12) {
      formattedDate += `12`;
    } else {
      formattedDate += month;
    }
    if (day) {
      if (parseInt(day) > 31) {
        formattedDate += `/31`;
      } else {
        formattedDate += `/${day}`;
      }
      if (year) {
        if (parseInt(year) > 2025) {
          formattedDate += `/2025`;
        } else {
          formattedDate += `/${year}`;
        }
      }
    }
  }

  if (formattedDate.length > 10) {
    formattedDate = formattedDate.slice(0, 10);
  }

  return formattedDate;
}

export const NewCustomer = ({isOpen, onClose, onSuccess}: Props) => {
  const {
    handleSubmit,
    register,
    formState: {errors},
    reset,
  } = useForm<FormValue>();

  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function handleDateChange(event: {target: {value: any}}) {
    const inputDate = event.target.value;
    const formattedDate = formatDateString(inputDate);
    setDate(formattedDate);
  }

  const submithandler = async (data: FormValue) => {
    setIsLoading(true);
    const user = {
      ...data,
      customer_company: data.company || ' ',
      phone: data.phone.replace(/\D/g, ''),
      birthday: format(
        parse(data.birthday, 'MM/dd/yyyy', new Date()),
        'yyyy-MM-dd'
      ),
    };

    try {
      const data = await api.post('/customers/', user);
      onSuccess(data.data);
      setIsLoading(false);
      onClose();
      reset();
      setDate('');
    } catch (error) {
      toast.error(
        get(error, 'response.data.error_message') ||
          JSON.stringify(get(error, 'response.data', {}) || '') ||
          'Something went wrong'
      );
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    reset({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      birthday: '',
      company: '',
    });
    setDate('');
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onRequestClose={handleClose}
      className={styles.list_wrapper}
    >
      <div className={styles.formWrapper}>
        <FormGroup columns={2}>
          <TextField
            label="First name"
            placeholder="First name"
            {...register('first_name', {required: 'Required'})}
            error={errors.first_name?.message}
          />
          <TextField
            label="Last name"
            placeholder="Last name"
            {...register('last_name', {required: 'Required'})}
            error={errors.last_name?.message}
          />
        </FormGroup>

        <FormGroup columns={1}>
          <TextField
            label="Email address"
            placeholder="Email address"
            {...register('email', {
              required: 'Required',
              validate: validateEmail,
            })}
            error={errors.email?.message}
          />
        </FormGroup>

        <FormGroup columns={1}>
          <TextField
            label="Phone number"
            placeholder="(123) 456-7890"
            type="tel"
            {...register('phone', {
              required: 'Required',
              minLength: {
                value: 14,
                message: 'Enter a valid phone number',
              },
            })}
            onChange={(e) => {
              let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
              let formatted = '';

              if (input.length > 0) formatted += '(' + input.substring(0, 3);
              if (input.length >= 4) formatted += ') ' + input.substring(3, 6);
              if (input.length >= 7) formatted += '-' + input.substring(6, 10);

              e.target.value = formatted;
              // e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }}
            maxLength={14}
            error={errors.phone?.message}
          />
        </FormGroup>

        <FormGroup columns={1}>
          <TextField
            label="Date of birth"
            placeholder="mm/dd/yyyy"
            {...register('birthday', {
              required: 'Required',
            })}
            value={date}
            onChange={handleDateChange}
            maxLength={10}
            error={errors.birthday?.message}
          />
        </FormGroup>
        <FormGroup columns={1}>
          <TextField
            label="Company"
            placeholder="Company"
            {...register('company')}
            error={errors.company?.message}
          />
        </FormGroup>

        <FormActionGroup className={styles.buttonWrap}>
          <Button onClick={handleClose} component="a" color="gray">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(submithandler)}
            type="submit"
            loading={isLoading}
          >
            Save
          </Button>
        </FormActionGroup>
      </div>
    </Modal>
  );
};

export default Users;
