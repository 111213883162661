import React, {useEffect, useCallback, useState, useRef} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import Card from 'components/Card';
import Button from 'components/Button';
import TextField from 'components/TextField';
import CardContent from 'components/CardContent';
import Logo from 'components/Logo';
import Typography from 'components/Typography';
import Spinner from 'components/Spinner';
import EyeIcon from '../EmployeeForm/icons/eye.svg';
import EyeDisabledIcon from '../EmployeeForm/icons/eye-disable.svg';
import api from 'api';

import styles from './ResetPassword.module.scss';

type TokenStatus = 'validating' | 'valid' | 'invalid' | 'expired';

const mapTokenStatusMessage: Record<TokenStatus, React.ReactNode> = {
  validating: <Spinner />,
  valid: '',
  invalid: 'Token is invalid',
  expired: 'Token expred',
};

interface FormValue {
  password: string;
  passwordConfirmation: string;
}

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [tokenStatus, setTokenStatus] = useState<TokenStatus>('validating');
  // Ref is used to cancel calling `setState` on unmounted component
  // Issue can be solved using other data fetching lib e.g. `react-query`
  const cleanup = useRef(false);
  const {push} = useHistory();
  const {search} = useLocation();

  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');

  const {
    register,
    handleSubmit,
    setError,
    formState: {errors},
    getValues,
  } = useForm<FormValue>();

  const componentDidMount = useCallback(
    async function () {
      try {
        const {
          data: {message},
        } = await api.get<{message: string}>(
          `/users/check-reset-token/${token}/`
        );

        if (cleanup.current) {
          cleanup.current = false;
          return;
        }

        if (message === 'token_correct') {
          setTokenStatus('valid');
        } else {
          setTokenStatus('expired');
        }
      } catch {
        if (cleanup.current) {
          cleanup.current = false;
          return;
        }

        setTokenStatus('invalid');
      }
    },
    [token, setTokenStatus]
  );

  useEffect(
    function () {
      componentDidMount();

      return function () {
        cleanup.current = true;
      };
    },
    [componentDidMount]
  );

  async function submit(data: FormValue) {
    if (data.password !== data.passwordConfirmation) {
      setError('passwordConfirmation', {
        type: 'manual',
        message: "Doesn't match",
      });

      return;
    }

    try {
      setLoading(true);

      await api.patch(`/users/reset-password/${token}/`, {
        password: data.password,
        confirm_password: data.passwordConfirmation,
      });
      push('/login');
    } catch {
      setTokenStatus('invalid');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card className={styles.root}>
      <CardContent>
        <Logo />
        <Typography variant="h2" textAlign="center">
          Reset password
        </Typography>
        {tokenStatus === 'valid' ? (
          <form onSubmit={handleSubmit(submit)}>
            <div style={{position: 'relative'}}>
              <TextField
                label="New password"
                type={showPassword ? 'text' : 'password'}
                placeholder="New password"
                {...register('password', {
                  required: 'Required',
                  minLength: {value: 6, message: 'Too short'},
                })}
                error={errors.password?.message}
              />
              <img
                onClick={() => setShowPassword((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
            <div style={{position: 'relative'}}>
              <TextField
                label="Confirm password"
                type={showPasswordConfirmation ? 'text' : 'password'}
                placeholder="Confirm password"
                {...register('passwordConfirmation', {
                  required: 'Required',
                  validate: (value) => {
                    if (value !== getValues('password')) {
                      return "Password doesn't match";
                    }
                    return true;
                  },
                })}
                error={errors.passwordConfirmation?.message}
              />
              <img
                onClick={() => setShowPasswordConfirmation((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPasswordConfirmation ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>

            <div className={styles.button_container}>
              <Button type="submit" loading={loading}>
                Save
              </Button>
            </div>
          </form>
        ) : (
          <div className={styles.message}>
            {typeof mapTokenStatusMessage[tokenStatus] === 'string' ? (
              <Typography variant="h3" textAlign="center">
                {mapTokenStatusMessage[tokenStatus]}
              </Typography>
            ) : (
              mapTokenStatusMessage[tokenStatus]
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default ResetPassword;
